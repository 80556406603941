import React from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { setMaterial } from '../../store/current-calculation-data/current-calculation-data.action-creators';
export default function MaterialDropDown(props) {
    var _a;
    const { isLinkedMaterial } = props;
    const dispatch = useDispatch();
    const layer = useSelector(activeConstructionLayerSelector);
    const currentSelectedMaterial = useSelector(currentSelectedMaterialSelector(isLinkedMaterial));
    const parentSelectedMaterial = useSelector(currentSelectedMaterialSelector(false));
    const materials = isLinkedMaterial ? parentSelectedMaterial.linkedMaterials : layer.materials;
    const handleChange = (event) => {
        dispatch(setMaterial(layer, materials.filter(m => m.materialId === event.target.value)[0]));
    };
    return (React.createElement("div", { className: 'marginTop20' },
        React.createElement(Typography, { gutterBottom: true, "data-qa-id": "materialSelectLabel" },
            React.createElement(Trans, { i18nKey: "calculation-ui.material-label" }, "Material")),
        React.createElement(Select, { fullWidth: true, "data-qa-id": "materialSelect", variant: "outlined", value: (_a = currentSelectedMaterial === null || currentSelectedMaterial === void 0 ? void 0 : currentSelectedMaterial.materialId) !== null && _a !== void 0 ? _a : '', onChange: handleChange }, materials.map(i => (React.createElement(MenuItem, { key: i.materialId, value: i.materialId, "data-qa-id": `${i.name}-dropdownvalue` }, i.name))))));
}
