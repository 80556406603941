import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation, Trans } from 'react-i18next';
import { Card, CardActionArea, CardContent, CircularProgress, Typography, CardMedia, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { constructionTypesSelector, constructionCategoriesSelector } from '../../store/construction-data/construction-data.selectors';
import './ConstructionTypeSelection.module.scss';
import { getAppConfig } from '../../config/config';
import { buildRoute } from '../../helpers/routeHelper';
import SignInDialog from './SignInDialog';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selectors';
import { setActiveConstructionType } from '../../store/component-state/component-state.actions';
import { countryIdSelector, countrySelector } from '../../store/component-state/component-state.selectors';
import { isLoadingConstructionTypesSelector } from '../../store/construction-data/construction-data.selectors';
import { isConfirmedGuestSelector } from '../../store/account/account.selectors';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    media: {
        height: 270,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
}));
export default function ConstructionTypeSelection() {
    var _a;
    const dispatch = useDispatch();
    const history = useHistory();
    const store = useStore().getState();
    const apiUrl = (_a = getAppConfig()) === null || _a === void 0 ? void 0 : _a.apiUrl;
    const constructionTypes = useSelector(constructionTypesSelector);
    const categories = useSelector(constructionCategoriesSelector);
    const country = useSelector(countrySelector);
    const countryId = useSelector(countryIdSelector).toLowerCase();
    const isLoadingConstructionTypes = useSelector(isLoadingConstructionTypesSelector);
    const countryIdUpper = countryId.toUpperCase();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const isConfirmedGuest = useSelector(isConfirmedGuestSelector);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedType, setSelectedType] = React.useState('');
    const { t } = useTranslation();
    const handleConstructionTypeSelection = (selectedConstructionType) => {
        if (!isAuthenticated && !isConfirmedGuest) {
            setOpen(true);
            setSelectedType(selectedConstructionType);
        }
        else {
            history.push(buildRoute(store, `/calculation/${selectedConstructionType}`));
        }
    };
    const handleSignInClose = (cont) => {
        setOpen(false);
        if (cont) {
            dispatch(setActiveConstructionType(selectedType));
            history.push(buildRoute(store, `/calculation/${selectedType}`));
        }
    };
    return (React.createElement("div", null,
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement("div", { className: 'title', "data-qa-id": "titleLabel" }, t(`by-country.${countryId}.construction-type-selection-ui.title`, {
                    resultType: t(`calculation-result-type.singular.${country === null || country === void 0 ? void 0 : country.calculationResultType}`),
                })),
                React.createElement("div", { className: 'subTitle', "data-qa-id": "subTitleLabel" }, t(`by-country.${countryId}.construction-type-selection-ui.sub-title`, {
                    resultTypePlural: t(`calculation-result-type.plural.${country === null || country === void 0 ? void 0 : country.calculationResultType}`),
                    calculationStandardName: country === null || country === void 0 ? void 0 : country.calculationStandardName,
                })))),
        isLoadingConstructionTypes &&
            React.createElement(CircularProgress, { size: 50 }),
        categories.map((c, index) => (React.createElement("div", { key: index },
            React.createElement("div", { className: 'groupTitle' },
                React.createElement(Trans, { i18nKey: `construction-category.${c.toLowerCase()}` }, c)),
            React.createElement(Divider, null),
            React.createElement("br", null),
            React.createElement(Grid, { container: true, spacing: 2 }, constructionTypes
                .filter(ct => ct.category === c)
                .map(ct => (React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 4, key: ct.id, "data-qa-id": `${ct.id}-card` },
                React.createElement(Card, null,
                    React.createElement(CardActionArea, { onClick: () => { handleConstructionTypeSelection(ct.id); } },
                        React.createElement(CardMedia, { className: classes.media, image: `${apiUrl}/content/build-up-images/${countryIdUpper}/${countryIdUpper}-${ct.id}.jpg`, title: ct.nameLocalized }),
                        React.createElement(CardContent, null,
                            React.createElement(Typography, { gutterBottom: true, variant: "h5", component: "h2", "data-qa-id": `${ct.id}-cardtext` }, ct.nameLocalized))))))))))),
        React.createElement(SignInDialog, { open: open, handleClose: handleSignInClose })));
}
