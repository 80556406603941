import { SET_ACTIVE_CONSTRUCTION_LAYER, SET_ACTIVE_CONSTRUCTION_TYPE, SET_COUNTRIES, SET_SELECTED_COUNTRY, SET_SELECTED_CULTURE, SET_GTM_CONTAINER, SET_PROJECT_CONFIG, SET_COUNTIES, SET_FIELD_DATA } from './component-state.action-types';
export const setActiveConstructionType = (constructionType) => ({
    type: SET_ACTIVE_CONSTRUCTION_TYPE,
    payload: constructionType,
});
export const setActiveConstructionLayer = (constructionLayerId) => ({
    type: SET_ACTIVE_CONSTRUCTION_LAYER,
    payload: constructionLayerId,
});
export const setCountries = (countries) => ({
    type: SET_COUNTRIES,
    payload: countries,
});
export const setSelectedCountry = (country) => ({
    type: SET_SELECTED_COUNTRY,
    payload: country,
});
export const setSelectedCulture = (selectedCulture) => ({
    type: SET_SELECTED_CULTURE,
    payload: selectedCulture,
});
export const setGTMContainer = () => ({
    type: SET_GTM_CONTAINER
});
export const setProjectConfig = (config) => ({
    type: SET_PROJECT_CONFIG,
    payload: config
});
export const setCounties = (counties) => ({
    type: SET_COUNTIES,
    payload: counties
});
export const setFieldData = (fieldData) => ({
    type: SET_FIELD_DATA,
    payload: fieldData
});
