var _a, _b;
import * as constructionDataActionTypes from '../construction-data/construction-data.action-types';
import * as componentStateActionTypes from './component-state.action-types';
import * as currentCalculationStateActionTypes from '../current-calculation-data/current-calculation-data.action-types';
import * as calculationStateActionTypes from '../calculations/calculations.action-types';
import { getPreviouslySelectedCulture } from '../../helpers/memory-helper';
export const initialState = {
    activeConstructionTypeId: '',
    activeConstructionLayerId: 0,
    countries: null,
    selectedCountry: null,
    isLoading: false,
    isLoadingConstructionTypes: false,
    isCalculationResultLoading: false,
    error: null,
    calculationResultError: null,
    selectedCulture: (_b = (_a = getPreviouslySelectedCulture()) !== null && _a !== void 0 ? _a : navigator.language) !== null && _b !== void 0 ? _b : 'en-GB',
    downloadingCalculationId: null,
    isGtmContainerSet: false,
    projectConfig: null,
    counties: null,
    fieldData: null
};
export const componentStateReducer = (state = initialState, action) => {
    switch (action.type) {
        // Page Loading State
        case constructionDataActionTypes.FETCH_CONSTRUCTION_TYPES_ATTEMPT:
            return Object.assign(Object.assign({}, state), { isLoadingConstructionTypes: true, error: null });
        case constructionDataActionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT:
        case calculationStateActionTypes.FETCH_CALCULATIONS_ATTEMPT:
            return Object.assign(Object.assign({}, state), { isLoading: true, error: null });
        case constructionDataActionTypes.FETCH_CONSTRUCTION_TYPES_SUCCESS:
        case constructionDataActionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS:
        case calculationStateActionTypes.FETCH_CALCULATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, isLoadingConstructionTypes: false });
        case constructionDataActionTypes.FETCH_CONSTRUCTION_TYPES_FAILURE:
        case constructionDataActionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE:
        case calculationStateActionTypes.FETCH_CALCULATIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, isLoadingConstructionTypes: false, error: action.error });
        // Component State
        case componentStateActionTypes.SET_GTM_CONTAINER:
            return Object.assign(Object.assign({}, state), { isGtmContainerSet: true });
        case componentStateActionTypes.SET_ACTIVE_CONSTRUCTION_TYPE:
            return Object.assign(Object.assign({}, state), { activeConstructionTypeId: action.payload });
        case componentStateActionTypes.SET_ACTIVE_CONSTRUCTION_LAYER:
            return Object.assign(Object.assign({}, state), { activeConstructionLayerId: action.payload });
        case componentStateActionTypes.SET_COUNTRIES:
            return Object.assign(Object.assign({}, state), { countries: action.payload });
        case componentStateActionTypes.SET_SELECTED_COUNTRY:
            return Object.assign(Object.assign({}, state), { selectedCountry: action.payload });
        case componentStateActionTypes.SET_PROJECT_CONFIG:
            return Object.assign(Object.assign({}, state), { projectConfig: action.payload });
        case componentStateActionTypes.SET_SELECTED_CULTURE:
            return Object.assign(Object.assign({}, state), { selectedCulture: action.payload });
        case componentStateActionTypes.SET_COUNTIES:
            return Object.assign(Object.assign({}, state), { counties: action.payload });
        case componentStateActionTypes.SET_FIELD_DATA:
            return Object.assign(Object.assign({}, state), { fieldData: action.payload });
        // Component State
        case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_ATTEMPT:
            return Object.assign(Object.assign({}, state), { isCalculationResultLoading: true, calculationResultError: null });
        case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_SUCCESS:
        case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_CLIENT_ERROR:
            return Object.assign(Object.assign({}, state), { isCalculationResultLoading: false });
        case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_FAILURE:
            return Object.assign(Object.assign({}, state), { isCalculationResultLoading: false, calculationResultError: action.error });
        // Calculcation Downloading State
        case calculationStateActionTypes.DOWNLOAD_CALCULATION_ATTEMPT:
        case calculationStateActionTypes.DOWNLOAD_PDF_CALCULATION_ATTEMPT:
            return Object.assign(Object.assign({}, state), { downloadingCalculationId: action.payload, error: null });
        case calculationStateActionTypes.DOWNLOAD_CALCULATION_SUCCESS:
        case calculationStateActionTypes.DOWNLOAD_PDF_CALCULATION_SUCCESS:
            return Object.assign(Object.assign({}, state), { downloadingCalculationId: null });
        case calculationStateActionTypes.DOWNLOAD_CALCULATION_FAILURE:
        case calculationStateActionTypes.DOWNLOAD_PDF_CALCULATION_FAILURE:
            return Object.assign(Object.assign({}, state), { downloadingCalculationId: null });
        default:
            return state;
    }
};
