import React from 'react';
import ReactDOM from 'react-dom';
import { detect } from 'detect-browser';
import { App } from './components/App/App';
import { setAppConfig } from './config/config';
import { isUnsupportedBrowser } from './isUnsupportedBrowser';
if (isUnsupportedBrowser(detect())) {
    const unsupportedBrowserBannerEl = document.getElementById('browser-unsupported');
    if (unsupportedBrowserBannerEl != null) {
        unsupportedBrowserBannerEl.style.display = 'block';
    }
}
(async function () {
    try {
        const clientConfig = await (await fetch('/config.json')).json();
        const { apiUrl } = clientConfig;
        const configData = await fetch(`${apiUrl}/config.json`);
        const appConfig = Object.assign(Object.assign({}, (await configData.json())), clientConfig);
        console.log('appConfig', appConfig);
        setAppConfig(appConfig);
        ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
    }
    catch (e) {
        console.error('App config could not be loaded');
        console.error(e);
    }
})();
