import { FETCH_NEWS_ITEM_SUCCESS, FETCH_NEWS_ITEM_FAILURE, FETCH_NEWS_ITEM_SKIPPED, FETCH_NEWS_ITEM_ATTEMPT, CLEAR_NEWS_ITEM, } from './news-item.action-types';
export const fetchNewsItemAttempt = () => ({
    type: FETCH_NEWS_ITEM_ATTEMPT,
});
export const fetchNewsItemSuccess = (newsItem) => ({
    type: FETCH_NEWS_ITEM_SUCCESS,
    payload: newsItem
});
export const fetchNewsItemFailure = (error) => ({
    type: FETCH_NEWS_ITEM_FAILURE,
    error: error,
});
export const fetchNewsItemSkipped = () => ({
    type: FETCH_NEWS_ITEM_SKIPPED,
});
export const clearNewsItem = () => ({
    type: CLEAR_NEWS_ITEM,
});
