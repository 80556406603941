import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { fetchCountries, fetchCounties, fetchFieldData } from '../../store/component-state/component-state.action-creators';
import { countryIdSelector, isLoadingCountriesSelector, isLoadingConstructionTypesSelector, selectedCultureSelector } from '../../store/component-state/component-state.selectors';
import kingspanLogo from '../../assets/images/kingspan-logo.svg';
import { LayoutMinimal } from '../Layout/LayoutMinimal';
import { fetchConstructionTypes, fetchConstructionTypeNames } from '../../store/construction-data/construction-data.action-creators';
const splash = () => (React.createElement(LayoutMinimal, null,
    React.createElement(Grid, { container: true, direction: 'column', alignItems: 'center' },
        React.createElement("p", null,
            React.createElement("img", { src: kingspanLogo, alt: "Kingspan Logo" })),
        React.createElement(CircularProgress, { size: 50 }))));
export default function CountriesProvider({ children }) {
    const isLoadingCountries = useSelector(isLoadingCountriesSelector);
    const countryId = useSelector(countryIdSelector);
    const selectedCulture = useSelector(selectedCultureSelector);
    const isLoadingConstructionTypes = useSelector(isLoadingConstructionTypesSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);
    useEffect(() => {
        dispatch(fetchCounties());
    }, [dispatch]);
    useEffect(() => {
        dispatch(fetchFieldData());
    }, [dispatch]);
    // Make sure that the construction types for the current country are always available
    useEffect(() => {
        if (!isEmpty(countryId)) {
            console.log(`Loading Construction Types for Country: ${countryId}`);
            dispatch(fetchConstructionTypes(countryId));
        }
    }, [dispatch, countryId]);
    // Make sure that the localised names for the construction types are always in-sync
    useEffect(() => {
        if (!isEmpty(countryId)) {
            console.log(`Refreshing localised Construction Type Names for Country: ${countryId}, Culture: ${selectedCulture}`);
            dispatch(fetchConstructionTypeNames(countryId));
        }
    }, [dispatch, countryId, selectedCulture]);
    return (React.createElement(React.Fragment, null, isLoadingCountries || isLoadingConstructionTypes ? splash() : children));
}
