import axios, { AxiosError } from 'axios';
import { LayerType, Complexity } from '../../types/domain/construction-data.types';
import { newCalculationStarted, fetchCalculationResultsAttempt, fetchCalculationResultsSuccess, fetchCalculationResultsClientError, fetchCalculationResultsFailure, setMaterialInternal, setThickness, setAnchorDiameter, setAnchorAmountPerSquareMetre, createLinkedLayer, removeLinkedLayer, setCalculatedLayerValue, setAnchorPenetration, editCalculationStarted, copyCalculationStarted, setAirCavityGradingInternal, } from './current-calculation-data.actions';
import { selectedCultureSelector } from '../../store/component-state/component-state.selectors';
import { getCalculationLayerKey } from './current-calculation-data.reducer';
import { AuthenticationState } from 'react-aad-msal';
import { activeConstructionTypeSelector } from '../component-state/component-state.selectors';
import { additionalParametersSelector, projectDetailsSelector } from './current-calculation-data.selectors';
import { mapApiResponseToCalculcation } from '../../helpers/calculation-response-helper';
import { debounce } from 'lodash';
const getDefaultMaterial = (layer) => { var _a; return (_a = layer.materials.filter(m => m.isDefaultLayerMaterial)[0]) !== null && _a !== void 0 ? _a : layer.materials[0]; };
const getDefaultLinkedMaterial = (parentMaterial) => { var _a; return (_a = (parentMaterial && parentMaterial.linkedMaterials.filter(m => m.isDefaultLayerMaterial)[0])) !== null && _a !== void 0 ? _a : (parentMaterial && parentMaterial.linkedMaterials[0]); };
const getDefaultAirCavityGrading = (layer) => { var _a; return (_a = layer.airCavityGradings.filter(ac => ac.isDefaultLayerAirCavityGrading)[0]) !== null && _a !== void 0 ? _a : layer.airCavityGradings[0]; };
const applyAutomaticBridgedInsulationAndAirCavityScenario = (constructionLayers, calculationLayers) => {
    var _a, _b, _c, _d, _e;
    // In the scenario where there is a bridging layer, and a bridged insulation layer, and an air cavity layer,
    // then the air cavity layer needs its value setting automatically.
    const bridgedInsulationLayerId = (_a = constructionLayers.filter(cl => cl.layerType === LayerType.Insulation && cl.isWithinBridgingFrame)[0]) === null || _a === void 0 ? void 0 : _a.constructionLayerId;
    const bridgingLayerId = (_b = constructionLayers.filter(cl => cl.layerType === LayerType.Bridging)[0]) === null || _b === void 0 ? void 0 : _b.constructionLayerId;
    const bridgedAirCavityLayerId = (_c = constructionLayers.filter(cl => cl.layerType === LayerType.AirCavity && cl.isWithinBridgingFrame)[0]) === null || _c === void 0 ? void 0 : _c.constructionLayerId;
    if (bridgedInsulationLayerId && bridgingLayerId && bridgedAirCavityLayerId) {
        const insulationLayer = calculationLayers[getCalculationLayerKey(bridgedInsulationLayerId)];
        const airLayer = calculationLayers[getCalculationLayerKey(bridgedAirCavityLayerId)];
        const availableGradings = (_d = constructionLayers.find(cl => cl.constructionLayerId === airLayer.constructionLayerId)) === null || _d === void 0 ? void 0 : _d.airCavityGradings;
        airLayer.thicknessMillimetres = calculationLayers[getCalculationLayerKey(bridgingLayerId)].thicknessMillimetres - insulationLayer.thicknessMillimetres;
        if (airLayer.thicknessMillimetres === 0 && availableGradings) {
            const noAirLayerGrading = availableGradings.find(a => a.airCavityGradingId === 'no-air-layer');
            airLayer.airCavityGrading = noAirLayerGrading;
        }
        else if (((_e = insulationLayer.material) === null || _e === void 0 ? void 0 : _e.defaultAirCavityGradingId) && availableGradings) {
            airLayer.airCavityGrading = availableGradings.find(g => { var _a; return g.airCavityGradingId === ((_a = insulationLayer.material) === null || _a === void 0 ? void 0 : _a.defaultAirCavityGradingId); });
        }
    }
};
const mapToCalculationLayers = (mappingFunction, constructionLayers) => {
    const calculationLayers = constructionLayers.map(mappingFunction).reduce((obj, item) => {
        return Object.assign(Object.assign({}, obj), { [getCalculationLayerKey(item.constructionLayerId)]: item });
    }, {});
    applyAutomaticBridgedInsulationAndAirCavityScenario(constructionLayers, calculationLayers);
    // Add in any linked layers for the default materials
    constructionLayers.forEach(cl => {
        const defaultMaterial = getDefaultMaterial(cl);
        const defaultLinkedMaterial = getDefaultLinkedMaterial(defaultMaterial);
        if (defaultLinkedMaterial) {
            calculationLayers[getCalculationLayerKey(cl.constructionLayerId, true)] = {
                constructionLayerId: cl.constructionLayerId,
                thicknessMillimetres: defaultLinkedMaterial.defaultThicknessMillimetres,
                material: defaultLinkedMaterial,
            };
        }
    });
    return calculationLayers;
};
export const startNewCalculation = (constructionTypeId, constructionLayers) => async (dispatch, getState) => {
    const calculationLayers = mapToCalculationLayers(cl => {
        var _a;
        const defaultMaterial = getDefaultMaterial(cl);
        const defaultAirCavityGrading = getDefaultAirCavityGrading(cl);
        switch (cl.layerType) {
            case LayerType.WoodPercentage:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    thicknessMillimetres: Math.round(((_a = getState().constructionData.types.filter(ct => ct.id === constructionTypeId)[0].defaultWoodPercentage) !== null && _a !== void 0 ? _a : 0.2) * 100),
                };
            case LayerType.Anchors:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    material: defaultMaterial,
                    diameterOfAnchorsMillimetres: defaultMaterial === null || defaultMaterial === void 0 ? void 0 : defaultMaterial.defaultDiameterOfAnchorsMillimetres,
                    numberOfAnchorsPerMetreSquare: defaultMaterial === null || defaultMaterial === void 0 ? void 0 : defaultMaterial.defaultNumberOfAnchorsPerMetreSquare,
                    anchorPenetrationDepthMillimetres: defaultMaterial === null || defaultMaterial === void 0 ? void 0 : defaultMaterial.defaultAnchorPenetrationMillimetres,
                };
            case LayerType.AnchorsPreCalculated:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    material: defaultMaterial,
                    netAreaOfAnchorsPerMetreSquareMillimetresSqrd: 50,
                };
            case LayerType.AirCavity:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    thicknessMillimetres: defaultAirCavityGrading === null || defaultAirCavityGrading === void 0 ? void 0 : defaultAirCavityGrading.defaultThicknessMillimetres,
                    airCavityGrading: defaultAirCavityGrading,
                };
            default:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    thicknessMillimetres: defaultMaterial === null || defaultMaterial === void 0 ? void 0 : defaultMaterial.defaultThicknessMillimetres,
                    material: defaultMaterial,
                };
        }
    }, constructionLayers);
    dispatch(newCalculationStarted(calculationLayers, constructionTypeId));
};
const buildCalculationLayersFromSaved = (calculation, constructionLayers) => {
    const calculationLayers = mapToCalculationLayers(cl => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const savedReferenceLayer = Object.values(calculation.calculationLayers).find(calculationLayer => { var _a; return calculationLayer.constructionLayerId === cl.constructionLayerId && ((_a = calculationLayer.material) === null || _a === void 0 ? void 0 : _a.isLinkedMaterial) !== true; });
        const material = (_a = cl.materials.filter(m => { var _a; return m.materialId === ((_a = savedReferenceLayer === null || savedReferenceLayer === void 0 ? void 0 : savedReferenceLayer.material) === null || _a === void 0 ? void 0 : _a.materialId); })[0]) !== null && _a !== void 0 ? _a : getDefaultMaterial(cl);
        const airCavityGrading = (_b = cl.airCavityGradings.filter(ac => { var _a; return ac.airCavityGradingId === ((_a = savedReferenceLayer === null || savedReferenceLayer === void 0 ? void 0 : savedReferenceLayer.airCavityGrading) === null || _a === void 0 ? void 0 : _a.airCavityGradingId); })[0]) !== null && _b !== void 0 ? _b : getDefaultAirCavityGrading(cl);
        switch (cl.layerType) {
            case LayerType.WoodPercentage:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    thicknessMillimetres: ((_c = calculation.woodPercentage) !== null && _c !== void 0 ? _c : 0.2) * 100,
                };
            case LayerType.Anchors:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    material: material,
                    diameterOfAnchorsMillimetres: (_d = savedReferenceLayer === null || savedReferenceLayer === void 0 ? void 0 : savedReferenceLayer.diameterOfAnchorsMillimetres) !== null && _d !== void 0 ? _d : material === null || material === void 0 ? void 0 : material.defaultDiameterOfAnchorsMillimetres,
                    numberOfAnchorsPerMetreSquare: (_e = savedReferenceLayer === null || savedReferenceLayer === void 0 ? void 0 : savedReferenceLayer.numberOfAnchorsPerMetreSquare) !== null && _e !== void 0 ? _e : material === null || material === void 0 ? void 0 : material.defaultNumberOfAnchorsPerMetreSquare,
                    anchorPenetrationDepthMillimetres: (_f = savedReferenceLayer === null || savedReferenceLayer === void 0 ? void 0 : savedReferenceLayer.anchorPenetrationDepthMillimetres) !== null && _f !== void 0 ? _f : material === null || material === void 0 ? void 0 : material.defaultAnchorPenetrationMillimetres,
                };
            case LayerType.AnchorsPreCalculated:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    material: material,
                    netAreaOfAnchorsPerMetreSquareMillimetresSqrd: (_g = savedReferenceLayer === null || savedReferenceLayer === void 0 ? void 0 : savedReferenceLayer.netAreaOfAnchorsPerMetreSquareMillimetresSqrd) !== null && _g !== void 0 ? _g : 50,
                };
            case LayerType.AirCavity:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    thicknessMillimetres: airCavityGrading === null || airCavityGrading === void 0 ? void 0 : airCavityGrading.defaultThicknessMillimetres,
                    airCavityGrading: airCavityGrading,
                };
            default:
                return {
                    constructionLayerId: cl.constructionLayerId,
                    thicknessMillimetres: (_h = savedReferenceLayer === null || savedReferenceLayer === void 0 ? void 0 : savedReferenceLayer.thicknessMillimetres) !== null && _h !== void 0 ? _h : material === null || material === void 0 ? void 0 : material.defaultThicknessMillimetres,
                    material: material,
                };
        }
    }, constructionLayers);
    const projectDetails = {
        name: calculation.projectDetails.name,
        startDate: calculation.projectDetails.startDate,
        siteArea: calculation.projectDetails.siteArea,
        county: calculation.projectDetails.county,
        size: calculation.projectDetails.size,
        type: calculation.projectDetails.type,
        ribaStatus: calculation.projectDetails.ribaStatus,
        buildingUse: calculation.projectDetails.buildingUse,
        postcode: calculation.projectDetails.postcode,
    };
    // Include the linked layers in to the calculation (fixes bug that stops linked layers from being reloaded)
    Object.values(calculation.calculationLayers)
        .filter(calculationLayer => { var _a; return ((_a = calculationLayer.material) === null || _a === void 0 ? void 0 : _a.isLinkedMaterial) === true; })
        .forEach(linkedLayer => {
        calculationLayers[getCalculationLayerKey(linkedLayer.constructionLayerId, true)] = Object.assign({}, linkedLayer);
    });
    return {
        projectDetails,
        calculationLayers,
    };
};
export const startEditCalculation = (calculation, constructionLayers) => async (dispatch) => {
    const { calculationLayers, projectDetails } = buildCalculationLayersFromSaved(calculation, constructionLayers);
    dispatch(editCalculationStarted(calculation.calculationId, calculation.calculationAccessCode, calculationLayers, calculation.constructionTypeId, projectDetails, calculation.calculationResult, calculation.additionalParameters));
};
export const startCopyCalculation = (calculation, constructionLayers) => async (dispatch) => {
    const { calculationLayers, projectDetails } = buildCalculationLayersFromSaved(calculation, constructionLayers);
    dispatch(copyCalculationStarted(calculationLayers, calculation.constructionTypeId, projectDetails, calculation.additionalParameters));
};
export const setAirCavityGrading = (layer, airCavityGrading) => async (dispatch, getState) => {
    var _a;
    dispatch(setAirCavityGradingInternal(airCavityGrading, layer.constructionLayerId));
    // This is the calulated layer for a complex construction type and doesn't need its thickness setting
    if (!layer.isWithinBridgingFrame) {
        setMaterialParameter(setThickness, layer, (_a = airCavityGrading.defaultThicknessMillimetres) !== null && _a !== void 0 ? _a : 0, false)(dispatch, getState);
    }
    await debouncedDispatchCalculationAttempt(dispatch, getState);
};
const getNextAirCavityLayer = (layer, store) => {
    const constructionLayers = store.constructionData.layers[layer.constructionTypeId];
    const airLayers = constructionLayers.filter(l => l.layerType === LayerType.AirCavity);
    if (airLayers.length === 1) {
        return airLayers[0];
    }
    else if (airLayers.length > 1) {
        const insulationLayerIndex = constructionLayers.indexOf(layer);
        return constructionLayers.filter((l, i) => insulationLayerIndex < i && l.layerType === LayerType.AirCavity)[0];
    }
    return undefined;
};
export const setMaterial = (layer, material) => async (dispatch, getState) => {
    const defaultLinkedMaterial = getDefaultLinkedMaterial(material);
    if (defaultLinkedMaterial) {
        dispatch(createLinkedLayer({
            constructionLayerId: layer.constructionLayerId,
            thicknessMillimetres: defaultLinkedMaterial.defaultThicknessMillimetres,
            material: defaultLinkedMaterial,
        }));
    }
    else if (!material.isLinkedMaterial) {
        dispatch(removeLinkedLayer(layer.constructionLayerId));
    }
    dispatch(setMaterialInternal(material, layer.constructionLayerId, material.isLinkedMaterial));
    switch (layer.layerType) {
        case LayerType.Anchors:
            const calculationLayer = getState().currentCalculationData.calculationLayers[layer.constructionLayerId.toString()];
            // We do an optional dispatch intentionally,
            // so that we keep the currently selected values for the anchor layer's parameters wherever possible.
            // The requirement is that "Anchors & Fastening should not change amount, diameter or penetration when selecting different anchor materials"
            const optionalDispatch = (currentValue, options, action) => {
                if ((options || []).indexOf(currentValue) === -1) {
                    dispatch(action);
                }
            };
            optionalDispatch(calculationLayer === null || calculationLayer === void 0 ? void 0 : calculationLayer.numberOfAnchorsPerMetreSquare, material.optionsForNumberOfAnchorsPerMetreSquare, setAnchorAmountPerSquareMetre(material.defaultNumberOfAnchorsPerMetreSquare, layer.constructionLayerId, false));
            optionalDispatch(calculationLayer === null || calculationLayer === void 0 ? void 0 : calculationLayer.diameterOfAnchorsMillimetres, material.optionsForDiameterOfAnchorsMillimetres, setAnchorDiameter(material.defaultDiameterOfAnchorsMillimetres, layer.constructionLayerId, false));
            optionalDispatch(calculationLayer === null || calculationLayer === void 0 ? void 0 : calculationLayer.anchorPenetrationDepthMillimetres, material.optionsForAnchorPenetrationMillimetres, setAnchorPenetration(material.defaultAnchorPenetrationMillimetres, layer.constructionLayerId, false));
            break;
        default:
            if (layer.layerType === LayerType.AirCavity && layer.isWithinBridgingFrame) {
                // This is the calulated layer for a complex construction type and doesn't need its thickness setting
                break;
            }
            setMaterialParameter(setThickness, layer, material.defaultThicknessMillimetres, material.isLinkedMaterial)(dispatch, getState);
            if (layer.layerType === LayerType.Insulation && material.defaultAirCavityGradingId && !material.isLinkedMaterial) {
                const nextAirLayer = getNextAirCavityLayer(layer, getState());
                const defaultGrading = nextAirLayer === null || nextAirLayer === void 0 ? void 0 : nextAirLayer.airCavityGradings.find(ac => ac.airCavityGradingId === material.defaultAirCavityGradingId);
                if (nextAirLayer && defaultGrading) {
                    setAirCavityGrading(nextAirLayer, defaultGrading)(dispatch, getState);
                }
            }
            break;
    }
    await debouncedDispatchCalculationAttempt(dispatch, getState);
};
export const setMaterialParameter = (action, layer, value, isLinkedLayer) => async (dispatch, getState) => {
    const store = getState();
    const constructionType = activeConstructionTypeSelector(store);
    switch (constructionType === null || constructionType === void 0 ? void 0 : constructionType.complexity) {
        case Complexity.Simple:
            dispatch(action(value, layer.constructionLayerId, isLinkedLayer));
            break;
        case Complexity.Complex:
            dispatch(action(value, layer.constructionLayerId, isLinkedLayer));
            const layers = store.constructionData.layers[store.componentState.activeConstructionTypeId];
            if ((layer.layerType === LayerType.Bridging || layer.layerType === LayerType.Insulation) && layers.some(l => l.layerType === LayerType.AirCavity && l.isWithinBridgingFrame)) {
                dispatch(setCalculatedLayerValue(layers.filter(l => l.layerType === LayerType.Bridging)[0], layers.filter(l => l.layerType === LayerType.Insulation && l.isWithinBridgingFrame)[0], layers.filter(l => l.layerType === LayerType.AirCavity && l.isWithinBridgingFrame)[0]));
            }
            break;
    }
    await debouncedDispatchCalculationAttempt(dispatch, getState);
};
export const dispatchCalculationAttempt = () => dispatchCalculationAttemptInternal;
const debouncedDispatchCalculationAttempt = debounce(async (dispatch, getState) => {
    await dispatchCalculationAttemptInternal(dispatch, getState);
}, 250);
const dispatchCalculationAttemptInternal = async (dispatch, getState) => {
    var _a;
    const calculation = buildCalculationData(getState);
    if (!calculation || !calculation.layers || calculation.layers.length === 0) {
        // Do not dispatch the calculation if we're in a state where we have no layers yet, and therefore we're unable to build a calculation model.
        return;
    }
    dispatch(fetchCalculationResultsAttempt());
    try {
        const { authenticationState } = getState();
        const { data } = await axios.post('/api/calculation', calculation, {
            withCredentials: authenticationState.state === AuthenticationState.Authenticated,
        });
        dispatch(fetchCalculationResultsSuccess(mapApiResponseToCalculcation(data)));
    }
    catch (error) {
        if (error instanceof AxiosError) {
            // Only dispatch the failure if it is not a client error.
            // Note that we respond with 409 when simultaneous requests for the same calculation cause a concurrency error, when only one of the requests wins and the rest are essentially rejected.
            const isClientError = ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) && !isNaN(error.response.status) && error.response.status >= 400 && error.response.status < 500;
            if (isClientError) {
                dispatch(fetchCalculationResultsClientError());
            }
            else {
                dispatch(fetchCalculationResultsFailure(error === null || error === void 0 ? void 0 : error.message));
            }
        }
    }
};
const buildCalculationData = (getState) => {
    var _a, _b, _c;
    const store = getState();
    const selectedCulture = selectedCultureSelector(store);
    const constructionType = activeConstructionTypeSelector(store);
    const constructionTypeLayers = store.constructionData.layers[(_a = constructionType === null || constructionType === void 0 ? void 0 : constructionType.id) !== null && _a !== void 0 ? _a : -1];
    if (!constructionTypeLayers || constructionTypeLayers.length === 0) {
        return null;
    }
    const pseudoLayers = constructionTypeLayers.filter(cl => cl.isPseudoLayer);
    let layers = store.currentCalculationData.calculationLayers;
    let woodPercentage = 0;
    if ((constructionType === null || constructionType === void 0 ? void 0 : constructionType.complexity) === Complexity.Complex && pseudoLayers.length > 0) {
        const woodPercentageLayerId = pseudoLayers.filter(cl => cl.layerType === LayerType.WoodPercentage)[0].constructionLayerId;
        //extract pseudo layer
        layers = Object.entries(store.currentCalculationData.calculationLayers).reduce((obj, kvp) => {
            const [key, value] = kvp;
            if (pseudoLayers.some(cl => cl.constructionLayerId === value.constructionLayerId)) {
                if (value.constructionLayerId === woodPercentageLayerId) {
                    woodPercentage = value.thicknessMillimetres;
                }
                return obj;
            }
            return Object.assign(Object.assign({}, obj), { [key]: value });
        }, {});
    }
    const projectDetails = projectDetailsSelector(store);
    const additionalParameters = additionalParametersSelector(store);
    const calculation = {
        calculationId: store.currentCalculationData.calculationId,
        calculationAccessCode: store.currentCalculationData.calculationAccessCode,
        constructionTypeId: store.componentState.activeConstructionTypeId,
        countryId: (_c = (_b = store.componentState.selectedCountry) === null || _b === void 0 ? void 0 : _b.countryId) !== null && _c !== void 0 ? _c : '',
        woodPercentage: woodPercentage / 100,
        layers: Object.entries(layers).map(kvp => {
            var _a, _b;
            const [, value] = kvp;
            return {
                constructionLayerId: value.constructionLayerId,
                materialId: (_a = value.material) === null || _a === void 0 ? void 0 : _a.materialId,
                airCavityGradingId: (_b = value.airCavityGrading) === null || _b === void 0 ? void 0 : _b.airCavityGradingId,
                thicknessMillimetres: value.thicknessMillimetres,
                numberOfAnchorsPerMetreSquare: value.numberOfAnchorsPerMetreSquare,
                diameterOfAnchorsMillimetres: value.diameterOfAnchorsMillimetres,
                anchorPenetrationDepthMillimetres: value.anchorPenetrationDepthMillimetres,
                netAreaOfAnchorsPerMetreSquareMillimetresSqrd: value.netAreaOfAnchorsPerMetreSquareMillimetresSqrd,
            };
        }),
        clientTimestamp: new Date().getTime(),
        cultureOfUser: selectedCulture,
        projectDetails: {
            name: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.name,
            siteArea: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.siteArea,
            startDate: (projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.startDate) ? formatDate(projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.startDate) : '',
            county: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.county,
            size: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.size,
            type: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.type,
            ribaStatus: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.ribaStatus,
            buildingUse: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.buildingUse,
            postcode: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.postcode,
        },
        additionalParameters: {
            areaMetresSquared: additionalParameters === null || additionalParameters === void 0 ? void 0 : additionalParameters.areaMetresSquared,
            perimeterMetres: additionalParameters === null || additionalParameters === void 0 ? void 0 : additionalParameters.perimeterMetres,
        },
    };
    return calculation;
};
const formatDate = (date) => {
    if (date === undefined) {
        return '';
    }
    return `${date.getFullYear()}-${appendLeadingZero(date.getMonth() + 1)}-${appendLeadingZero(date.getDate())}T00:00:00.000Z`;
};
const appendLeadingZero = (n) => (n <= 9 ? '0' + n : n.toString());
