export const getPreviouslySelectedCountryId = () => {
    return window && window.localStorage
        ? window.localStorage.getItem('previouslySelectedCountryId') || ''
        : '';
};
export const setPreviouslySelectedCountryId = (countryId) => {
    if (window && window.localStorage) {
        window.localStorage.setItem('previouslySelectedCountryId', countryId);
    }
};
export const getPreviouslySelectedCulture = () => {
    const culture = window && window.localStorage
        ? window.localStorage.getItem('previouslySelectedCulture') || ''
        : '';
    return culture !== '' ? culture : null;
};
export const setPreviouslySelectedCulture = (culture) => {
    if (window && window.localStorage) {
        window.localStorage.setItem('previouslySelectedCulture', culture);
    }
};
