import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Trans } from 'react-i18next';
export default function NoSlider(props) {
    const { labelKey, selectedThickness, } = props;
    return (React.createElement(Box, { marginTop: 4 },
        React.createElement(Grid, { container: true, spacing: 4 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Trans, { i18nKey: `calculation-ui.${labelKey}` }, "Thickness"),
                React.createElement("div", null, selectedThickness)))));
}
