import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Avatar } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
export default function CultureSelectMobile(props) {
    const { t } = useTranslation();
    const { culture, supportedCultures, handleCultureSelection, anchorElement, handleMenuButtonClick, handleClose, } = props;
    if (isEmpty(supportedCultures)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { button: true, "aria-controls": "culture-menu", "aria-haspopup": "true", onClick: handleMenuButtonClick },
            React.createElement(ListItemIcon, null,
                React.createElement(Avatar, null,
                    React.createElement(PublicIcon, null))),
            React.createElement(ListItemText, { primary: t(`navigation-ui.${culture}`) })),
        React.createElement(Menu, { id: "culture-menu", anchorEl: anchorElement, keepMounted: true, open: !isEmpty(anchorElement), onClose: handleClose }, supportedCultures.map(supportedCulture => React.createElement(MenuItem, { key: supportedCulture, onClick: () => handleCultureSelection(supportedCulture) }, t(`navigation-ui.${supportedCulture}`))))));
}
