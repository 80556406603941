import { FETCH_USER_ATTEMPT, FETCH_USER_SUCCESS, FETCH_USER_FAILURE, UPLOAD_COMPANY_LOGO_ATTEMPT, UPLOAD_COMPANY_LOGO_SUCCESS, UPLOAD_COMPANY_LOGO_BAD_REQUEST, UPLOAD_COMPANY_LOGO_FAILURE, GDPR_CONSENT_RESPONSE_SELECTED, RECORD_GDPR_CONSENT_SUCCESS, RECORD_GDPR_CONSENT_FAILURE, RECORD_GDPR_CONSENT_ATTEMPT, SET_IS_CONFIRMED_GUEST } from './account.action-types';
export const fetchUserAttempt = () => ({
    type: FETCH_USER_ATTEMPT,
});
export const fetchUserSuccess = (data, isNewUser = false) => ({
    type: FETCH_USER_SUCCESS,
    payload: { user: data, isNewUser },
});
export const fetchUserFailure = (error) => ({
    type: FETCH_USER_FAILURE,
    error,
});
export const recordGDPRConsentSuccess = () => ({
    type: RECORD_GDPR_CONSENT_SUCCESS
});
export const recordGDPRConsentFailure = () => ({
    type: RECORD_GDPR_CONSENT_FAILURE
});
export const recordGDPRConsentAttempt = () => ({
    type: RECORD_GDPR_CONSENT_ATTEMPT
});
export const GDPRConsentResponse = (consent) => ({
    type: GDPR_CONSENT_RESPONSE_SELECTED,
    payload: consent,
});
export const uploadCompanyLogoAttempt = () => ({
    type: UPLOAD_COMPANY_LOGO_ATTEMPT
});
export const uploadCompanyLogoSuccess = (data) => ({
    type: UPLOAD_COMPANY_LOGO_SUCCESS,
    payload: data,
});
export const uploadCompanyLogoBadRequest = (error) => ({
    type: UPLOAD_COMPANY_LOGO_BAD_REQUEST,
    error,
});
export const uploadCompanyLogoFailure = (error) => ({
    type: UPLOAD_COMPANY_LOGO_FAILURE,
    error,
});
export const setIsConfirmedGuest = (isConfirmedGuest) => ({
    type: SET_IS_CONFIRMED_GUEST,
    payload: isConfirmedGuest,
});
