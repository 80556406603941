import { useEffect } from 'react';
import React from 'react';
import { useRouteMatch, Route, useHistory } from 'react-router-dom';
import { setGTMContainer, setSelectedCountry } from '../../store/component-state/component-state.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAppConfig } from '../../config/config';
import { setPreviouslySelectedCountryId } from '../../helpers/memory-helper';
import TagManager from 'react-gtm-module';
import { countriesSelector, getIsGtmContainerSet } from '../../store/component-state/component-state.selectors';
import { fetchProjectConfig } from '../../store/component-state/component-state.action-creators';
export default function CountryRoute(props) {
    var _a;
    const dispatch = useDispatch();
    const match = useRouteMatch('/:countryId');
    const history = useHistory();
    const countryId = (_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.countryId;
    const countries = useSelector(countriesSelector);
    const isGtmContainerSet = useSelector(getIsGtmContainerSet);
    function getCookies() {
        const cookies = {};
        const cookiesArray = document.cookie.split(';');
        cookiesArray.forEach(cookie => {
            const [key, value] = cookie.split('=').map(c => c.trim());
            if (key && value) {
                cookies[decodeURIComponent(key)] = decodeURIComponent(value);
            }
        });
        return cookies;
    }
    const getIsAnalyticalCookiesAccepted = () => {
        const cookieControl = getCookies()['CookieControl'];
        if (!cookieControl)
            return false;
        try {
            const { optionalCookies } = JSON.parse(cookieControl);
            return (optionalCookies === null || optionalCookies === void 0 ? void 0 : optionalCookies.AnalyticalCookies) === 'accepted';
        }
        catch (_a) {
            return false;
        }
    };
    const isAnalyticalCookiesAccepted = getIsAnalyticalCookiesAccepted();
    useEffect(() => {
        const country = countries.find(c => c.countryId.toLowerCase() === countryId.toLowerCase());
        if (country) {
            setPreviouslySelectedCountryId(countryId);
            if (!isGtmContainerSet && isAnalyticalCookiesAccepted) {
                initialiseGTMContainer(countryId, dispatch);
            }
            dispatch(setSelectedCountry(country));
            dispatch(fetchProjectConfig(country.countryId));
        }
        else {
            console.warn(`No country matching ${countryId}, redirecing to homepage`);
            history.push('/');
        }
    }, [dispatch, countryId, history, countries, isGtmContainerSet, isAnalyticalCookiesAccepted]);
    return React.createElement(Route, Object.assign({}, props));
}
function initialiseGTMContainer(countryId, dispatch) {
    const { gtmTrackingIds } = getAppConfig();
    const gtmTrackingId = gtmTrackingIds[countryId.toUpperCase()];
    if (gtmTrackingId) {
        const tagManagerArgs = {
            gtmId: gtmTrackingId,
        };
        TagManager.initialize(tagManagerArgs);
        console.info(`GTM container set to ${gtmTrackingId} for ${countryId}`);
        dispatch(setGTMContainer());
    }
}
