import axios, { AxiosError } from 'axios';
import { fetchUserAttempt, fetchUserSuccess, fetchUserFailure, uploadCompanyLogoSuccess, uploadCompanyLogoBadRequest, uploadCompanyLogoFailure, uploadCompanyLogoAttempt, recordGDPRConsentSuccess, recordGDPRConsentFailure, recordGDPRConsentAttempt, } from './account.actions';
import { ResponseCode } from '../../types/application/response-code.types';
export const fetchUser = (accountInfo) => async (dispatch) => {
    dispatch(fetchUserAttempt());
    try {
        const { data, status } = await axios.post('/api/user', {}, {
            withCredentials: true,
        });
        const isNewUser = status === ResponseCode.CREATED;
        dispatch(fetchUserSuccess(data, isNewUser));
    }
    catch (error) {
        dispatch(fetchUserFailure(error));
    }
};
export const recordGDPRConsent = (consentGiven) => async (dispatch) => {
    dispatch(recordGDPRConsentAttempt());
    try {
        const { data } = await axios.post('api/user/record-gdpr-consent', consentGiven, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        dispatch(recordGDPRConsentSuccess());
        dispatch(fetchUserSuccess(data));
    }
    catch (error) {
        dispatch(recordGDPRConsentFailure());
    }
};
export const uploadCompanyLogo = (file, fileName) => async (dispatch) => {
    var _a, _b;
    dispatch(uploadCompanyLogoAttempt());
    try {
        const formData = new FormData();
        const blob = new Blob([file], { type: 'image/jpeg' });
        formData.append('companyLogoImage', blob, fileName); // TODO: test this change a bit more
        const { data } = await axios.post('api/user/upload-company-logo', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch(uploadCompanyLogoSuccess(Object.assign({}, data)));
        dispatch(fetchUserSuccess(data));
    }
    catch (error) {
        if (error instanceof AxiosError) {
            // const { status, data } = error.response;
            const status = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
            const data = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data;
            if (status === ResponseCode.BAD_REQUEST) {
                dispatch(uploadCompanyLogoBadRequest({
                    errorCode: data.errorCode,
                    args: data.args,
                    message: data.message,
                }));
                return;
            }
            dispatch(uploadCompanyLogoFailure(error.response));
        }
    }
};
