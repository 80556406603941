export const constructionDataSelector = (store) => store.constructionData;
export const constructionTypesSelector = (store) => constructionDataSelector(store).types;
export const isLoadingConstructionTypesSelector = (store) => store.componentState.isLoading;
export const constructionCategoriesSelector = (store) => {
    var _a, _b;
    return [...((_b = (_a = store.constructionData) === null || _a === void 0 ? void 0 : _a.types) !== null && _b !== void 0 ? _b : [])]
        .sort((a, b) => b.category.localeCompare(a.category))
        .map(ct => ct.category)
        .filter((value, index, self) => self.indexOf(value) === index);
};
export const constructionLayerSelector = (constructionTypeId) => (store) => { var _a; return (_a = store.constructionData.layers[constructionTypeId]) !== null && _a !== void 0 ? _a : []; };
export const constructionTypeNameSelector = (constructionTypeId) => (store) => {
    var _a, _b;
    return (_b = (_a = constructionDataSelector(store).types
        .find(ct => ct.id === constructionTypeId)) === null || _a === void 0 ? void 0 : _a.nameLocalized) !== null && _b !== void 0 ? _b : constructionTypeId;
};
