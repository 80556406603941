import axios from 'axios';
import { fetchConstructionTypesAttempt, fetchConstructionTypesFailure, fetchConstructionTypesSuccess, fetchConstructionTypeNamesSuccess, fetchConstructionLayersForTypeAttempt, fetchConstructionLayersForTypeFailure, fetchConstructionLayersForTypeSuccess, } from './construction-data.actions';
import { startNewCalculation, startEditCalculation, startCopyCalculation } from '../current-calculation-data/current-calculation-data.action-creators';
import { Complexity, LayerType } from '../../types/domain/construction-data.types';
import { setActiveConstructionLayer } from '../component-state/component-state.actions';
export const fetchConstructionTypes = (countryId) => 
// TODO: Typing needs to be defined
async (dispatch) => {
    dispatch(fetchConstructionTypesAttempt());
    try {
        const { data } = await axios.get(`/api/constructionTypes/${countryId}`);
        dispatch(fetchConstructionTypesSuccess(data));
    }
    catch (error) {
        dispatch(fetchConstructionTypesFailure(error === null || error === void 0 ? void 0 : error.message));
    }
};
export const fetchConstructionTypeNames = (countryId) => async (dispatch) => {
    try {
        const { data } = await axios.get(`/api/constructionTypes/${countryId}`);
        dispatch(fetchConstructionTypeNamesSuccess(data));
    }
    catch (error) {
        console.warn('fetchConstructionTypeNames error', error);
    }
};
export const fetchConstructionLayersForType = (constructionTypeId, countryId, refetch) => 
// TODO: Typing needs to be defined
async (dispatch, getState) => {
    dispatch(fetchConstructionLayersForTypeAttempt());
    try {
        const { data, headers } = await axios.get(`/api/constructionLayers/${constructionTypeId}/${countryId}`);
        const constructionTypeNameLocalized = headers['construction-type-name-localized'];
        const ct = getState().constructionData.types.filter(ct => ct.id === constructionTypeId)[0];
        const dataWithPseudoLayers = constructPseudoLayers(ct, data);
        dispatch(fetchConstructionLayersForTypeSuccess(constructionTypeId, dataWithPseudoLayers, constructionTypeNameLocalized !== null && constructionTypeNameLocalized !== void 0 ? constructionTypeNameLocalized : ''));
        if (refetch !== true) {
            dispatch(setActiveConstructionLayer(data[0].constructionLayerId));
            await startNewCalculation(constructionTypeId, dataWithPseudoLayers)(dispatch, getState);
        }
    }
    catch (error) {
        console.warn('fetchConstructionLayersForType error', error);
        dispatch(fetchConstructionLayersForTypeFailure(error === null || error === void 0 ? void 0 : error.message));
    }
};
export const fetchConstructionLayersForCalculation = (calculation, copy) => async (dispatch, getState) => {
    dispatch(fetchConstructionLayersForTypeAttempt());
    try {
        const { data, headers } = await axios.get(`/api/constructionLayers/${calculation.constructionTypeId}/${calculation.countryId}`);
        const constructionTypeNameLocalized = headers['construction-type-name-localized'];
        const ct = getState().constructionData.types.filter(ct => ct.id === calculation.constructionTypeId)[0];
        const dataWithPseudoLayers = constructPseudoLayers(ct, data);
        dispatch(fetchConstructionLayersForTypeSuccess(calculation.constructionTypeId, dataWithPseudoLayers, constructionTypeNameLocalized !== null && constructionTypeNameLocalized !== void 0 ? constructionTypeNameLocalized : ''));
        dispatch(setActiveConstructionLayer(data[0].constructionLayerId));
        if (copy) {
            await startCopyCalculation(calculation, dataWithPseudoLayers)(dispatch);
        }
        else {
            await startEditCalculation(calculation, dataWithPseudoLayers)(dispatch);
        }
    }
    catch (error) {
        console.warn('fetchConstructionLayersForCalculation error', error);
        dispatch(fetchConstructionLayersForTypeFailure(error));
    }
};
const constructPseudoLayers = (constructionType, layers) => {
    if (!constructionType) {
        throw new Error('Invalid state: `constructionType` param is null. The `constructPseudoLayers` function requires the `constructionType` param');
    }
    if (constructionType.complexity === Complexity.Simple) {
        return layers;
    }
    return layers.reduce((obj, item) => {
        if (item.layerType === LayerType.Bridging) {
            const pseudoLayer = {
                constructionLayerId: -9999,
                constructionTypeId: constructionType.id,
                name: 'wood-percentage',
                nameLocalized: 'Wood Percentage',
                materials: [],
                airCavityGradings: [],
                layerType: LayerType.WoodPercentage,
                isWithinBridgingFrame: false,
                isPseudoLayer: true,
            };
            return [...obj, item, pseudoLayer];
        }
        return [...obj, item];
    }, []);
};
