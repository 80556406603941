import * as actionTypes from './account.action-types';
import { ResponseCode } from '../../types/application/response-code.types';
export const initialState = {
    uploadCompanyLogoState: {
        isLoading: false
    }
};
export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_SUCCESS:
            return Object.assign(Object.assign({}, state), { loggedInUser: action.payload.user, isNewUser: action.payload.isNewUser });
        case actionTypes.GDPR_CONSENT_RESPONSE_SELECTED:
            return Object.assign(Object.assign({}, state), { gdprConsent: action.payload });
        case actionTypes.UPLOAD_COMPANY_LOGO_ATTEMPT:
            return Object.assign(Object.assign({}, state), { uploadCompanyLogoState: {
                    isLoading: true,
                    error: null,
                } });
        case actionTypes.UPLOAD_COMPANY_LOGO_SUCCESS:
            return Object.assign(Object.assign({}, state), { uploadCompanyLogoState: {
                    isLoading: false,
                    response: {
                        status: ResponseCode.OK,
                        ok: action.payload,
                    },
                } });
        case actionTypes.UPLOAD_COMPANY_LOGO_BAD_REQUEST:
            return Object.assign(Object.assign({}, state), { uploadCompanyLogoState: {
                    isLoading: false,
                    error: action.error,
                } });
        case actionTypes.UPLOAD_COMPANY_LOGO_FAILURE:
            return Object.assign(Object.assign({}, state), { uploadCompanyLogoState: {
                    isLoading: false,
                    error: action.error,
                } });
        case actionTypes.SET_IS_CONFIRMED_GUEST:
            return Object.assign(Object.assign({}, state), { isConfirmedGuest: action.payload });
        default:
            return state;
    }
};
