import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, makeStyles, createStyles, Paper, CircularProgress, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import FormatNumber from '../FormatNumber/FormatNumber';
import { CalculationResultType } from '../../types/domain/calculation-data.types';
const useStyles = makeStyles((theme) => createStyles({
    table: {
        fontSize: '0.9em',
        '& td': {
            padding: '16px 0px 16px 4px',
        },
        '& th': {
            padding: '16px 0px 16px 4px',
        },
        '& .actionButtons': {
            padding: '16px 0px',
        },
    },
    button: {
        margin: `${theme.spacing(1)}px 2px`,
    },
    progressContainer: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    buttonWrapper: {
        position: 'relative',
        float: 'left',
    },
    buttonProgress: {
        color: theme.palette.info.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
export default function MyCalculationsDesktop(props) {
    const { calculations, country, countryId, handleDownloadClick, handleDirectDownloadClick, handleDeleteClick, isDeleting, isDownloading, isCalculationDownloading } = props;
    const history = useHistory();
    const classes = useStyles();
    const isTabletView = useMediaQuery((theme) => theme.breakpoints.only('sm'));
    return (React.createElement(TableContainer, { className: classes.table, component: Paper },
        React.createElement(Table, { "aria-label": "simple table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null,
                        React.createElement(Trans, { i18nKey: "my-calculations-ui.table.project-name" }, "Project Name")),
                    React.createElement(TableCell, null,
                        React.createElement(Trans, { i18nKey: "my-calculations-ui.table.construction-type" }, "Type of Construction")),
                    React.createElement(TableCell, null, (country === null || country === void 0 ? void 0 : country.calculationResultType) === CalculationResultType.RValue
                        ? React.createElement(Trans, { i18nKey: "my-calculations-ui.table.rvalue" }, "R Value ()")
                        : React.createElement(Trans, { i18nKey: "my-calculations-ui.table.uvalue" }, "U Value ()")),
                    !isTabletView && (React.createElement(TableCell, null,
                        React.createElement(Trans, { i18nKey: "my-calculations-ui.table.startdate" }, "Start Date"))),
                    !isTabletView && (React.createElement(TableCell, null,
                        React.createElement(Trans, { i18nKey: "my-calculations-ui.table.last-change" }, "Date of last Change"))),
                    React.createElement(TableCell, null))),
            React.createElement(TableBody, null, calculations.map(calc => (React.createElement(TableRow, { key: calc.calculationId },
                React.createElement(TableCell, { component: "th", scope: "row" }, calc.projectDetails.name),
                React.createElement(TableCell, null, calc.constructionTypeNameLocalized),
                React.createElement(TableCell, null,
                    React.createElement(FormatNumber, { toFormat: calc.calculationResult.resultValue }),
                    " ",
                    calc.calculationResult.resultUnits),
                !isTabletView && React.createElement(TableCell, null, calc.projectDetails.startDate ? format(calc.projectDetails.startDate, 'dd-MM-yyyy') : '-'),
                !isTabletView && React.createElement(TableCell, null, calc.updatedOn ? format(calc.updatedOn, 'dd-MM-yyyy HH:mm:ss') : '-'),
                React.createElement(TableCell, { className: 'actionButtons' },
                    React.createElement("div", { className: classes.buttonWrapper },
                        React.createElement(Button, { color: "primary", className: classes.button, startIcon: React.createElement(FileCopyIcon, null), onClick: () => history.push(buildRouteWithCountry(countryId, `/calculation/${calc.constructionTypeId}/${calc.calculationId}?copy`)) },
                            React.createElement(Trans, { i18nKey: "my-calculations-ui.table.buttons.copy" }, "Copy"))),
                    React.createElement("div", { className: classes.buttonWrapper },
                        React.createElement(Button, { color: "primary", className: classes.button, startIcon: React.createElement(EditIcon, null), onClick: () => history.push(buildRouteWithCountry(countryId, `/calculation/${calc.constructionTypeId}/${calc.calculationId}`)) },
                            React.createElement(Trans, { i18nKey: "my-calculations-ui.table.buttons.edit" }, "Edit"))),
                    React.createElement("div", { className: classes.buttonWrapper },
                        React.createElement(Button, { color: "primary", className: classes.button, startIcon: React.createElement(EmailIcon, null), onClick: () => handleDownloadClick(calc.calculationId, calc.calculationAccessCode), disabled: isDownloading() },
                            React.createElement(Trans, { i18nKey: "my-calculations-ui.table.buttons.download" }, "Download")),
                        isCalculationDownloading(calc.calculationId) && React.createElement(CircularProgress, { size: 24, className: `${classes.buttonProgress}` })),
                    React.createElement("div", { className: classes.buttonWrapper },
                        React.createElement(Button, { color: "primary", className: classes.button, startIcon: React.createElement(GetAppIcon, null), onClick: () => handleDirectDownloadClick(calc.calculationId, calc.calculationAccessCode), disabled: isDownloading() },
                            React.createElement(Trans, { i18nKey: "my-calculations-ui.table.buttons.direct-download" }, "View")),
                        isCalculationDownloading(calc.calculationId) && React.createElement(CircularProgress, { size: 24, className: `${classes.buttonProgress}` })),
                    React.createElement("div", { className: classes.buttonWrapper },
                        React.createElement(Button, { color: "primary", className: classes.button, startIcon: React.createElement(DeleteIcon, null), onClick: () => handleDeleteClick(calc.calculationId), disabled: isDeleting(calc.calculationId) },
                            React.createElement(Trans, { i18nKey: "my-calculations-ui.table.buttons.delete" }, "Delete")),
                        isDeleting(calc.calculationId) && React.createElement(CircularProgress, { size: 24, className: `${classes.buttonProgress}` }))))))))));
}
