import React from 'react';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedThicknessSelector, currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector } from 'react-redux';
import MaterialParametersAux from './MaterialParametersAux';
export default function MaterialParameters(props) {
    const { isLinkedMaterial } = props;
    const layer = useSelector(activeConstructionLayerSelector);
    const currentSelectedThickness = useSelector(currentSelectedThicknessSelector(isLinkedMaterial));
    const currentSelectedMatrial = useSelector(currentSelectedMaterialSelector(isLinkedMaterial));
    return (React.createElement(MaterialParametersAux, { layer: layer, currentSelectedThickness: currentSelectedThickness, currentSelectedMaterial: currentSelectedMatrial, isLinkedMaterial: isLinkedMaterial }));
}
