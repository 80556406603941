import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCalculation, deleteCalculation, downloadCalculation, directDownloadCalculation } from '../../store/calculations/calculations.action-creators';
import { Box, Typography, Divider, Button, makeStyles, createStyles, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import { Link } from 'react-router-dom';
import { calculationArraySelector, deletingCalculationsSelector } from '../../store/calculations/calculations.selectors';
import { isAuthenticationInitialized } from '../../store/authentication/authentication.selectors';
import { isLoadingSelector, countryIdSelector, downloadingCalculationIdSelector, countrySelector, selectedCultureSelector } from '../../store/component-state/component-state.selectors';
import MyCalculationsDesktop from './MyCalculations.desktop';
import MyCalculationsMobile from './MyCalculations.mobile';
import { displayPdfDownloadToast } from '../../helpers/toast-helpers';
const useStyles = makeStyles((theme) => createStyles({
    progressContainer: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));
export default function MyCalculations() {
    const dispatch = useDispatch();
    const country = useSelector(countrySelector);
    const countryId = useSelector(countryIdSelector);
    const selectedCulture = useSelector(selectedCultureSelector);
    const calculations = useSelector(calculationArraySelector);
    const isAuthInitialized = useSelector(isAuthenticationInitialized);
    const deletingCalculationsStore = useSelector(deletingCalculationsSelector);
    const isLoading = useSelector(isLoadingSelector);
    const downloadingCalculationId = useSelector(downloadingCalculationIdSelector);
    const classes = useStyles();
    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const { t } = useTranslation();
    React.useEffect(() => {
        if (isAuthInitialized && !isEmpty(countryId)) {
            dispatch(fetchCalculation(countryId));
        }
    }, [dispatch, isAuthInitialized, countryId, selectedCulture]);
    const [deletingCalculationIds, setDeletingCalclationIds] = useState([]);
    const [downloadingState, setDownloadingState] = useState(false);
    React.useEffect(() => {
        if (!downloadingState && downloadingCalculationId) {
            setDownloadingState(true);
        }
        else if (downloadingState && !downloadingCalculationId) {
            setDownloadingState(false);
        }
    }, [downloadingState, downloadingCalculationId, setDownloadingState]);
    const handleDeleteClick = (calculationId) => {
        setDeletingCalclationIds([...deletingCalculationIds, calculationId]);
        dispatch(deleteCalculation(calculationId));
    };
    const handleDownloadClick = async (calculationId, calculationAccessCode) => {
        const result = await dispatch(downloadCalculation(calculationId, calculationAccessCode));
        displayPdfDownloadToast(result, t);
    };
    const handleDirectDownloadClick = async (calculationId, calculationAccessCode) => {
        await dispatch(directDownloadCalculation(calculationId, calculationAccessCode));
    };
    React.useEffect(() => {
        setDeletingCalclationIds(deletingCalculationsStore);
    }, [deletingCalculationsStore]);
    const isDeleting = (calculationId) => {
        return deletingCalculationIds.some(c => c === calculationId);
    };
    const isDownloading = () => {
        return downloadingState;
    };
    const isCalculationDownloading = (calculationId) => {
        return downloadingCalculationId === calculationId;
    };
    const renderMyCalculationsView = () => {
        return isMobileView ? (React.createElement(MyCalculationsMobile, { calculations: calculations, country: country, countryId: countryId, handleDownloadClick: handleDownloadClick, handleDirectDownloadClick: handleDirectDownloadClick, handleDeleteClick: handleDeleteClick, isDeleting: isDeleting, isDownloading: isDownloading, isCalculationDownloading: isCalculationDownloading })) : (React.createElement(MyCalculationsDesktop, { calculations: calculations, country: country, countryId: countryId, handleDownloadClick: handleDownloadClick, handleDirectDownloadClick: handleDirectDownloadClick, handleDeleteClick: handleDeleteClick, isDeleting: isDeleting, isDownloading: isDownloading, isCalculationDownloading: isCalculationDownloading }));
    };
    return (React.createElement(React.Fragment, null,
        !isMobileView && (React.createElement(React.Fragment, null,
            React.createElement(Box, { marginBottom: 4 },
                React.createElement(Typography, { variant: "h1", component: "h2" },
                    React.createElement(Trans, { i18nKey: "my-calculations-ui.title" }, "My Calculations"))),
            React.createElement(Divider, null),
            React.createElement(Box, { my: 4 },
                React.createElement(Button, { variant: "outlined", color: "secondary", component: Link, to: buildRouteWithCountry(countryId, '') },
                    React.createElement(Trans, { i18nKey: "my-calculations-ui.new-calculation" }, "New Calculation"))),
            React.createElement(Divider, null))),
        isAuthInitialized && !isLoading ? (renderMyCalculationsView()) : (React.createElement("div", { className: classes.progressContainer },
            React.createElement(CircularProgress, null)))));
}
