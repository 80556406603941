import { LayerType } from '../../types/domain/construction-data.types';
import { getCalculationLayerKey } from './current-calculation-data.reducer';
export const currentCalculationResultSelector = (store) => store.currentCalculationData.calculationResult;
export const currentCalculationDataSelector = (store) => store.currentCalculationData;
export const currentSelectedMaterialSelector = (isLinkedMaterial) => (store) => { var _a; return (_a = store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId, isLinkedMaterial)]) === null || _a === void 0 ? void 0 : _a.material; };
export const currentSelectedAirCavityGradingSelector = (store) => { var _a; return (_a = store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId, false)]) === null || _a === void 0 ? void 0 : _a.airCavityGrading; };
export const currentSelectedThicknessSelector = (isLinkedMaterial) => (store) => { var _a; return (_a = store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId, isLinkedMaterial)]) === null || _a === void 0 ? void 0 : _a.thicknessMillimetres; };
export const previousInsulationCalculationLayerSelector = (store) => {
    var _a, _b, _c, _d, _e;
    const constructionLayers = store.constructionData.layers[store.componentState.activeConstructionTypeId];
    const insluationLayerIndexes = constructionLayers
        .map((cl, index) => ({ layer: cl, index }))
        .filter(t => t.layer.layerType === LayerType.Insulation);
    let layerId = 0;
    if (insluationLayerIndexes.length === 1) {
        layerId = (_b = (_a = insluationLayerIndexes[0].layer) === null || _a === void 0 ? void 0 : _a.constructionLayerId) !== null && _b !== void 0 ? _b : 0;
    }
    else {
        layerId = (_e = (_d = (_c = insluationLayerIndexes
            .find(t => t.index < constructionLayers.indexOf(constructionLayers
            .filter(cl => cl.constructionLayerId === store.componentState.activeConstructionLayerId)[0]))) === null || _c === void 0 ? void 0 : _c.layer) === null || _d === void 0 ? void 0 : _d.constructionLayerId) !== null && _e !== void 0 ? _e : 0;
    }
    return store.currentCalculationData.calculationLayers[getCalculationLayerKey(layerId)];
};
export const currentAnchorPropertiesSelector = (store) => {
    const layer = store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId)];
    return {
        numberOfAnchorsPerMetreSquare: layer === null || layer === void 0 ? void 0 : layer.numberOfAnchorsPerMetreSquare,
        diameterOfAnchorsMillimetres: layer === null || layer === void 0 ? void 0 : layer.diameterOfAnchorsMillimetres,
        anchorPenetrationDepthMillimetres: layer === null || layer === void 0 ? void 0 : layer.anchorPenetrationDepthMillimetres,
        netAreaOfAnchorsPerMetreSquareMillimetresSqrd: layer === null || layer === void 0 ? void 0 : layer.netAreaOfAnchorsPerMetreSquareMillimetresSqrd,
    };
};
export const projectDetailsSelector = (store) => store.currentCalculationData.projectDetails;
export const additionalParametersSelector = (store) => store.currentCalculationData.additionalParameters;
