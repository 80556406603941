import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { setActiveConstructionLayer, setCountries, setProjectConfig, setCounties, setFieldData } from './component-state.actions';
export const resetActiveConstructionLayerForCurrentCalculation = () => (async (dispatch, getState) => {
    dispatch(setActiveConstructionLayer(Object.values(getState().currentCalculationData.calculationLayers)[0].constructionLayerId));
});
export const fetchCountries = () => async (dispatch) => {
    dispatch(setCountries(null));
    try {
        const showAllCountries = `${window.location.search}`.indexOf('preview=64b60e66-9735-4911-ad8f-b064aaa57526') > -1;
        const { data } = await axios.get(`/api/countries?all=${showAllCountries}`);
        dispatch(setCountries(data !== null && data !== void 0 ? data : []));
    }
    catch (error) {
        toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
            autoClose: false,
            closeOnClick: false,
        });
        dispatch(setCountries([]));
    }
};
export const fetchCounties = () => async (dispatch) => {
    dispatch(setCounties(null));
    try {
        const { data } = await axios.get('/api/lookup/counties');
        dispatch(setCounties(data));
    }
    catch (error) {
        toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
            autoClose: false,
            closeOnClick: false,
        });
    }
};
export const fetchFieldData = () => async (dispatch) => {
    dispatch(setFieldData(null));
    try {
        const { data } = await axios.get('/api/lookup/fielddata');
        dispatch(setFieldData(data));
    }
    catch (error) {
        toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
            autoClose: false,
            closeOnClick: false,
        });
    }
};
export const fetchProjectConfig = (countryId) => async (dispatch) => {
    dispatch(setProjectConfig(null));
    try {
        const { data } = await axios.get(`/api/lookup/projectdetailsconfig/${countryId}`);
        dispatch(setProjectConfig(data));
    }
    catch (error) {
        toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
            autoClose: false,
            closeOnClick: false,
        });
    }
};
