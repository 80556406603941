import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { LayerType } from '../../types/domain/construction-data.types';
import ThicknessSlider from './ThicknessSlider';
import NoSlider from './NoSlider';
import { setThickness } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
export default function MaterialParametersAux(props) {
    var _a, _b, _c, _d;
    const { isLinkedMaterial, layer, currentSelectedThickness, currentSelectedMaterial } = props;
    const dispatch = useDispatch();
    const isFixedLayer = layer.isWithinBridgingFrame && layer.layerType === LayerType.AirCavity;
    const handleChange = (value) => {
        dispatch(setMaterialParameter(setThickness, layer, value, isLinkedMaterial));
    };
    if (currentSelectedMaterial && currentSelectedMaterial.optionsForThicknessMillimetres.length > 1) {
        return (React.createElement(ThicknessSlider, { labelKey: 'thickness-label', handleChange: handleChange, selectedThickness: currentSelectedThickness, disabled: !currentSelectedMaterial || isFixedLayer, marks: (_a = currentSelectedMaterial === null || currentSelectedMaterial === void 0 ? void 0 : currentSelectedMaterial.optionsForThicknessMillimetres) !== null && _a !== void 0 ? _a : [], min: (_b = currentSelectedMaterial === null || currentSelectedMaterial === void 0 ? void 0 : currentSelectedMaterial.optionsForThicknessMillimetres[0]) !== null && _b !== void 0 ? _b : 0, max: (_c = currentSelectedMaterial === null || currentSelectedMaterial === void 0 ? void 0 : currentSelectedMaterial.optionsForThicknessMillimetres[currentSelectedMaterial.optionsForThicknessMillimetres.length - 1]) !== null && _c !== void 0 ? _c : 100, defaultValue: (_d = currentSelectedMaterial === null || currentSelectedMaterial === void 0 ? void 0 : currentSelectedMaterial.defaultThicknessMillimetres) !== null && _d !== void 0 ? _d : 0, units: 'mm', isValueFixed: isFixedLayer }));
    }
    else if (currentSelectedMaterial && currentSelectedMaterial.optionsForThicknessMillimetres.length === 1 && currentSelectedMaterial.optionsForThicknessMillimetres[0] !== 0) {
        return (React.createElement(NoSlider, { labelKey: 'thickness-label', selectedThickness: currentSelectedThickness, units: 'mm' }));
    }
    else {
        return (React.createElement(Box, { marginTop: 4 }));
    }
}
