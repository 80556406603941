import { FETCH_CONSTRUCTION_TYPES_ATTEMPT, FETCH_CONSTRUCTION_TYPES_SUCCESS, FETCH_CONSTRUCTION_TYPES_FAILURE, FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS, FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT, FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS, FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE, } from './construction-data.action-types';
export const fetchConstructionTypesAttempt = () => ({
    type: FETCH_CONSTRUCTION_TYPES_ATTEMPT,
});
export const fetchConstructionTypesSuccess = (data) => ({
    type: FETCH_CONSTRUCTION_TYPES_SUCCESS,
    payload: data,
});
export const fetchConstructionTypeNamesSuccess = (data) => ({
    type: FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS,
    payload: data,
});
export const fetchConstructionTypesFailure = (error) => ({
    type: FETCH_CONSTRUCTION_TYPES_FAILURE,
    error,
});
export const fetchConstructionLayersForTypeAttempt = () => ({
    type: FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT,
});
export const fetchConstructionLayersForTypeSuccess = (constructionType, data, constructionTypeNameLocalized) => ({
    type: FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS,
    payload: {
        constructionType: constructionType,
        constructionTypeNameLocalized,
        layers: data,
    },
});
export const fetchConstructionLayersForTypeFailure = (error) => ({
    type: FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE,
    error,
});
