import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { loggedInUserSelector, uploadCompanyLogoSelector } from '../../store/account/account.selectors';
import PersonalInformation from './PersonalInformation';
import BusinessInformation from './BusinessInformation';
import authProviderFactory from '../../authentication/authProvider';
export default function AccountDetails() {
    useTranslation();
    const loggedInUser = useSelector(loggedInUserSelector);
    const uploadCompanyLogoState = useSelector(uploadCompanyLogoSelector);
    const handleSignOutClick = () => {
        authProviderFactory().logout();
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Box, { marginBottom: 4 },
            React.createElement(Typography, { variant: "h1", component: "h2" },
                React.createElement(Trans, { i18nKey: 'account-ui.title' }, "Account settings"))),
        loggedInUser === undefined
            ? React.createElement(Box, null,
                React.createElement(Trans, { i18nKey: 'account-ui.user-not-logged-in' }, "No logged in user to display."))
            : React.createElement(Grid, { container: true },
                React.createElement(BusinessInformation, { user: loggedInUser, uploadCompanyLogoState: uploadCompanyLogoState }),
                React.createElement(PersonalInformation, { user: loggedInUser, handleSignOutClick: handleSignOutClick })));
}
