import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import CalculationLayer from '../CalculationLayer/CalculationLayer';
import RcValue from '../RcValue/RcValue';
import './Calculation.module.scss';
import { isLoadingSelector } from '../../store/component-state/component-state.selectors';
import CalculationWizardControls from './CalculationWizardControls';
import { projectDetailsSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { constructionTypeNameSelector } from '../../store/construction-data/construction-data.selectors';
import { dispatchCalculationAttempt } from '../../store/current-calculation-data/current-calculation-data.action-creators';
export default function Calculation(props) {
    const { activeStepIndex, constructionTypeId, steps, handleNextStep, handlePreviousStep } = props;
    const dispatch = useDispatch();
    const projectDetails = useSelector(projectDetailsSelector);
    const isLoading = useSelector(isLoadingSelector);
    const constructionTypeName = useSelector(constructionTypeNameSelector(constructionTypeId));
    useEffect(() => {
        if (!isLoading) {
            dispatch(dispatchCalculationAttempt());
        }
    }, [dispatch, isLoading]);
    const hasSteps = steps && steps.length > 0;
    return (React.createElement(Box, null,
        React.createElement(Box, { marginBottom: 4 },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: "auto", sm: "auto", md: 1 }),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 10 },
                    React.createElement(Box, { className: 'calculationTitle', "data-qa-id": "calculationNameLabel" }, constructionTypeName),
                    React.createElement(Box, { className: 'calculationSubTitle', "data-qa-id": "projectNameLabel" }, projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.name)),
                React.createElement(Grid, { item: true, xs: "auto", sm: "auto", md: 1 }))),
        hasSteps ? React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: "auto", sm: "auto", md: 1 }),
            React.createElement(Grid, { item: true, xs: 12, sm: 8, md: 7 },
                React.createElement(Box, { marginBottom: 4 },
                    React.createElement(CalculationLayer, null))),
            React.createElement(Grid, { item: true, xs: 12, sm: 4, md: 4 },
                React.createElement(Box, { marginBottom: 4 },
                    React.createElement(RcValue, null)))) : React.createElement(Box, { m: 2 },
            React.createElement(CircularProgress, null)),
        hasSteps && React.createElement(CalculationWizardControls, { activeStepIndex: activeStepIndex, steps: steps, handleBackClick: handlePreviousStep, handleNextClick: handleNextStep })));
}
