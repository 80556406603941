import { AuthenticationActionCreators, AuthenticationActions } from 'react-aad-msal';
import { debounce } from 'lodash';
import { fetchUser } from '../account/account.action-creators';
export const authenticationActionCreatorOverrides = (store) => {
    console.log('authenticationActionCreatorOverrides Loaded');
    const debouncedFetchUser = debounce((data) => {
        console.log('debouncedFetchUser');
        fetchUser(data)(store.dispatch);
    }, 250);
    AuthenticationActionCreators.loginSuccessful = (data) => {
        console.log('onLoginSuccessful');
        debouncedFetchUser(data);
        return {
            payload: data,
            type: AuthenticationActions.LoginSuccess,
        };
    };
};
