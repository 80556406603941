import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
const buildUpHeight = 120;
const useStyle = makeStyles((theme) => (createStyles({
    materialName: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        textAlign: 'center',
        paddingBottom: '0.5em',
    },
    buildUpContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '100px',
    },
    buildUpMaterial: {
        display: 'inline-block',
        height: `${buildUpHeight}px`,
        backgroundColor: '#FFF',
        borderRadius: '2px',
        borderColor: '#414141'
    },
    buildUpMaterialInsulation: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    buildUpMaterialInnerWall: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    constructionThicknessBracket: {
        height: '10px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '5px',
    },
    thicknessLabel: {
        textAlign: 'center',
        fontSize: '95%',
    },
    kingSpan: {
        backgroundColor: '#F6AF9A',
    }
})));
export default function ThicknessBuildUp(props) {
    const classes = useStyle();
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.materialName }, props.materialName),
        React.createElement("div", { className: classes.buildUpContainer },
            React.createElement(Box, null,
                React.createElement("div", null,
                    React.createElement(Box, { className: classes.buildUpMaterial, width: 35, border: 2, marginRight: "5px" }),
                    React.createElement(Box, { className: `${classes.buildUpMaterial} ${classes.buildUpMaterialInsulation} ${props.isKingspanProduct ? classes.kingSpan : ''}`, width: 30 * props.layerThicknessPercentageNormalised, border: 2, borderRight: 0 }),
                    React.createElement(Box, { className: `${classes.buildUpMaterial} ${classes.buildUpMaterialInnerWall}`, width: 12, border: 2, borderLeft: 1 })),
                React.createElement(Box, { className: classes.constructionThicknessBracket, borderLeft: 1, borderRight: 1, borderBottom: 1 }))),
        React.createElement("div", { className: classes.thicknessLabel },
            props.totalConstructionThicknessMillimetres,
            " mm")));
}
