import React from 'react';
import { LayerType } from '../../types/domain/construction-data.types';
import { useTranslation } from 'react-i18next';
import { insertSuggestedHyphenationIfRequired } from './hyphenSuggestion';
const getText = (props, t) => {
    var _a, _b;
    const text = ((_a = props.layer) === null || _a === void 0 ? void 0 : _a.layerType) === LayerType.WoodPercentage
        ? t('calculation-ui.wood-percentage')
        : (_b = props.layer) === null || _b === void 0 ? void 0 : _b.nameLocalized;
    return props.insertSuggestedHyphenationIfRequired === true
        ? insertSuggestedHyphenationIfRequired(text !== null && text !== void 0 ? text : '')
        : text;
};
export default function LayerName(props) {
    const { t } = useTranslation();
    return React.createElement("span", null, getText(props, t));
}
