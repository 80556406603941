import { FETCH_CALCULATIONS_ATTEMPT, FETCH_CALCULATIONS_SUCCESS, FETCH_CALCULATIONS_FAILURE, DELETE_CALCULATION_ATTEMPT, DELETE_CALCULATION_SUCCESS, DELETE_CALCULATION_FAILURE, DOWNLOAD_CALCULATION_ATTEMPT, DOWNLOAD_CALCULATION_SUCCESS, DOWNLOAD_CALCULATION_FAILURE, DOWNLOAD_PDF_CALCULATION_ATTEMPT, DOWNLOAD_PDF_CALCULATION_SUCCESS, DOWNLOAD_PDF_CALCULATION_FAILURE, } from './calculations.action-types';
export const fetchCalculationAttempt = () => ({
    type: FETCH_CALCULATIONS_ATTEMPT,
});
export const fetchCalculationSuccess = (data) => ({
    type: FETCH_CALCULATIONS_SUCCESS,
    payload: data,
});
export const fetchCalculationFailure = (error) => ({
    type: FETCH_CALCULATIONS_FAILURE,
    error,
});
export const deleteCalculationAttempt = (calculationId) => ({
    type: DELETE_CALCULATION_ATTEMPT,
    payload: calculationId,
});
export const deleteCalculationSuccess = (calculationId) => ({
    type: DELETE_CALCULATION_SUCCESS,
    payload: calculationId,
});
export const deleteCalculationFailure = (error, calculationId) => ({
    type: DELETE_CALCULATION_FAILURE,
    error,
    payload: calculationId,
});
export const downloadCalculationAttempt = (calculationId) => ({
    type: DOWNLOAD_CALCULATION_ATTEMPT,
    payload: calculationId,
});
export const downloadCalculationSuccess = (calculationId) => ({
    type: DOWNLOAD_CALCULATION_SUCCESS,
    payload: calculationId,
});
export const downloadCalculationFailure = (error, calculationId) => ({
    type: DOWNLOAD_CALCULATION_FAILURE,
    error,
    payload: calculationId,
});
export const downloadPdfCalculationAttempt = (calculationId) => ({
    type: DOWNLOAD_PDF_CALCULATION_ATTEMPT,
    payload: calculationId,
});
export const downloadPdfCalculationSuccess = (calculationId) => ({
    type: DOWNLOAD_PDF_CALCULATION_SUCCESS,
    payload: calculationId,
});
export const downloadPdfCalculationFailure = (error, calculationId) => ({
    type: DOWNLOAD_PDF_CALCULATION_FAILURE,
    error,
    payload: calculationId,
});
