import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedThicknessSelector, currentSelectedAirCavityGradingSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { setThickness } from '../../store/current-calculation-data/current-calculation-data.actions';
import ThicknessSlider from './ThicknessSlider';
import NoSlider from './NoSlider';
export default function AirCavityThickness() {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const layer = useSelector(activeConstructionLayerSelector);
    const currentSelectedAcg = useSelector(currentSelectedAirCavityGradingSelector);
    const currentSelectedThickness = useSelector(currentSelectedThicknessSelector(false));
    const isFixedLayer = layer.isWithinBridgingFrame;
    const handleThicknessChange = (value) => {
        dispatch(setMaterialParameter(setThickness, layer, value, false));
    };
    if (!isFixedLayer) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ThicknessSlider, { labelKey: 'thickness-label', handleChange: handleThicknessChange, selectedThickness: currentSelectedThickness, disabled: !currentSelectedAcg || isFixedLayer, marks: (_a = currentSelectedAcg === null || currentSelectedAcg === void 0 ? void 0 : currentSelectedAcg.optionsForThicknessMillimetres) !== null && _a !== void 0 ? _a : [], min: (_b = currentSelectedAcg === null || currentSelectedAcg === void 0 ? void 0 : currentSelectedAcg.optionsForThicknessMillimetres[0]) !== null && _b !== void 0 ? _b : 0, max: (_c = currentSelectedAcg === null || currentSelectedAcg === void 0 ? void 0 : currentSelectedAcg.optionsForThicknessMillimetres[currentSelectedAcg.optionsForThicknessMillimetres.length - 1]) !== null && _c !== void 0 ? _c : 100, defaultValue: (_d = currentSelectedAcg === null || currentSelectedAcg === void 0 ? void 0 : currentSelectedAcg.defaultThicknessMillimetres) !== null && _d !== void 0 ? _d : 0, units: 'mm', isValueFixed: isFixedLayer })));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement(NoSlider, { labelKey: 'thickness-label', selectedThickness: currentSelectedThickness, units: 'mm' })));
    }
}
