import React from 'react';
import { Button, Menu, MenuItem, Grid } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
export default function CultureSelectDesktop(props) {
    const { t } = useTranslation();
    const { culture, supportedCultures, handleCultureSelection, anchorElement, handleMenuButtonClick, handleClose, } = props;
    if (isEmpty(supportedCultures)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "aria-controls": "culture-menu", "aria-haspopup": "true", onClick: handleMenuButtonClick },
            React.createElement(Grid, { container: true, spacing: 2, "data-qa-id": "cultureSelect" },
                React.createElement(Grid, { item: true }, t(`navigation-ui.${culture}`)),
                React.createElement(Grid, { item: true },
                    React.createElement(PublicIcon, null)))),
        React.createElement(Menu, { id: "culture-menu", anchorEl: anchorElement, keepMounted: true, open: !isEmpty(anchorElement), onClose: handleClose }, supportedCultures.map(supportedCulture => React.createElement(MenuItem, { key: supportedCulture, onClick: () => handleCultureSelection(supportedCulture), "data-qa-id": `cultureSelectOption-${supportedCulture}` }, t(`navigation-ui.${supportedCulture}`))))));
}
