import React from 'react';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentAnchorPropertiesSelector, currentSelectedMaterialSelector, previousInsulationCalculationLayerSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector, useDispatch } from 'react-redux';
import ThicknessSlider from './ThicknessSlider';
import { setAnchorAmountPerSquareMetre, setAnchorDiameter, setAnchorPenetration } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { useTranslation } from 'react-i18next';
export default function AnchorParameters() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const dispatch = useDispatch();
    const layer = useSelector(activeConstructionLayerSelector);
    const { numberOfAnchorsPerMetreSquare, diameterOfAnchorsMillimetres, anchorPenetrationDepthMillimetres } = useSelector(currentAnchorPropertiesSelector);
    const currentSelectedMatrial = useSelector(currentSelectedMaterialSelector(false));
    const previousInsulationLayer = useSelector(previousInsulationCalculationLayerSelector);
    const [optionsForAnchorPenetrationMillimetres, setOptionsForAnchorPenetrationMillimetres] = React.useState([]);
    const handleAmountChange = (value) => {
        dispatch(setMaterialParameter(setAnchorAmountPerSquareMetre, layer, value, false));
    };
    const handleDiameterChange = (value) => {
        dispatch(setMaterialParameter(setAnchorDiameter, layer, value, false));
    };
    const handlePenetrationChange = (value) => {
        dispatch(setMaterialParameter(setAnchorPenetration, layer, value, false));
    };
    const { t } = useTranslation();
    React.useEffect(() => {
        if (currentSelectedMatrial && currentSelectedMatrial.optionsForAnchorPenetrationMillimetres && previousInsulationLayer) {
            setOptionsForAnchorPenetrationMillimetres(currentSelectedMatrial.optionsForAnchorPenetrationMillimetres.filter(o => o <= previousInsulationLayer.thicknessMillimetres));
        }
    }, [setOptionsForAnchorPenetrationMillimetres, currentSelectedMatrial, previousInsulationLayer]);
    const defaultMarks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (React.createElement("div", null,
        React.createElement(ThicknessSlider, { labelKey: 'amount-label', handleChange: handleAmountChange, selectedThickness: numberOfAnchorsPerMetreSquare, disabled: !currentSelectedMatrial, marks: (_a = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.optionsForNumberOfAnchorsPerMetreSquare) !== null && _a !== void 0 ? _a : defaultMarks, min: (_b = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.optionsForNumberOfAnchorsPerMetreSquare[0]) !== null && _b !== void 0 ? _b : 0, max: (_c = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.optionsForNumberOfAnchorsPerMetreSquare[currentSelectedMatrial.optionsForNumberOfAnchorsPerMetreSquare.length - 1]) !== null && _c !== void 0 ? _c : 10, defaultValue: (_d = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.defaultNumberOfAnchorsPerMetreSquare) !== null && _d !== void 0 ? _d : 0, units: `${t('calculation-ui.anchor-per')} m²`, isValueFixed: false }),
        React.createElement(ThicknessSlider, { labelKey: 'diameter-label', handleChange: handleDiameterChange, selectedThickness: diameterOfAnchorsMillimetres, disabled: !currentSelectedMatrial, marks: (_e = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.optionsForDiameterOfAnchorsMillimetres) !== null && _e !== void 0 ? _e : defaultMarks, min: (_f = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.optionsForDiameterOfAnchorsMillimetres[0]) !== null && _f !== void 0 ? _f : 0, max: (_g = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.optionsForDiameterOfAnchorsMillimetres[currentSelectedMatrial.optionsForDiameterOfAnchorsMillimetres.length - 1]) !== null && _g !== void 0 ? _g : 10, defaultValue: (_h = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.defaultDiameterOfAnchorsMillimetres) !== null && _h !== void 0 ? _h : 0, units: 'mm', isValueFixed: false }),
        optionsForAnchorPenetrationMillimetres.length ?
            React.createElement(ThicknessSlider, { labelKey: 'penetration-label', handleChange: handlePenetrationChange, selectedThickness: anchorPenetrationDepthMillimetres, disabled: !currentSelectedMatrial, marks: optionsForAnchorPenetrationMillimetres !== null && optionsForAnchorPenetrationMillimetres !== void 0 ? optionsForAnchorPenetrationMillimetres : defaultMarks, min: optionsForAnchorPenetrationMillimetres[0], max: (_j = optionsForAnchorPenetrationMillimetres[optionsForAnchorPenetrationMillimetres.length - 1]) !== null && _j !== void 0 ? _j : 100, defaultValue: (_k = currentSelectedMatrial === null || currentSelectedMatrial === void 0 ? void 0 : currentSelectedMatrial.defaultAnchorPenetrationMillimetres) !== null && _k !== void 0 ? _k : 0, units: 'mm', isValueFixed: false })
            : null));
}
