import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { UserAgentApplication } from 'msal';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { getAppConfig } from '../config/config';
let currentLocale = undefined;
const baseAuthConfig = () => {
    var _a, _b;
    return {
        auth: {
            clientId: (_b = (_a = getAppConfig()) === null || _a === void 0 ? void 0 : _a.azureB2CConfig.clientId) !== null && _b !== void 0 ? _b : '',
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: false,
            // After being redirected to the 'redirectUri' page, should user
            // be redirected back to the Url where their login originated from?
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true
        }
    };
};
export function getAuthority(policyName) {
    var _a, _b;
    return `${(_a = getAppConfig()) === null || _a === void 0 ? void 0 : _a.azureB2CConfig.authority}${(_b = getAppConfig()) === null || _b === void 0 ? void 0 : _b.azureB2CConfig.policies[policyName]}`;
}
export function buildAuthenticationParameters(policyName) {
    return {
        authority: getAuthority(policyName),
        extraQueryParameters: {
            'ui_locales': currentLocale || 'qps-ploc'
        }
    };
}
export const signUpSignInAuthConfig = () => (Object.assign(Object.assign({}, baseAuthConfig()), { auth: Object.assign(Object.assign({}, baseAuthConfig().auth), { authority: getAuthority('signUpSignIn') }) }));
export const resetPasswordAuthConfig = () => (Object.assign(Object.assign({}, baseAuthConfig()), { auth: Object.assign(Object.assign({}, baseAuthConfig().auth), { authority: getAuthority('resetPassword'), redirectUri: window.location.origin + '/password-reset' }) }));
export const updateDetailsAuthConfig = () => (Object.assign(Object.assign({}, baseAuthConfig()), { auth: Object.assign(Object.assign({}, baseAuthConfig().auth), { authority: getAuthority('updateDetails'), redirectUri: window.location.origin + '/account-updated' }) }));
export const changeAuthProviderLocale = (locale) => {
    const authProvider = authProviderFactory();
    const authParams = authProvider.getAuthenticationParameters();
    authProvider.setAuthenticationParameters(Object.assign(Object.assign({}, authParams), { extraQueryParameters: Object.assign(Object.assign({}, authParams.extraQueryParameters), { 'ui_locales': locale }) }));
    console.info(`AuthProvider locale changed to: ${locale}`);
    currentLocale = locale;
};
export const launchRedirectToPasswordReset = () => {
    const myMSALObj = new UserAgentApplication(resetPasswordAuthConfig());
    setTimeout(() => myMSALObj.loginRedirect(buildAuthenticationParameters('resetPassword')), 1000);
    toast.info(i18n.t('navigation-ui.redirecting-ellipsis'), { autoClose: 900 });
};
export const checkAndRedirectIfIsForgotPasswordError = (error) => {
    if (error.errorMessage.indexOf('AADB2C90118') > -1) {
        try {
            launchRedirectToPasswordReset();
        }
        catch (err) {
            console.log(err);
        }
    }
};
// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
let authProviderInternal;
export default function authProviderFactory() {
    var _a, _b;
    if (!authProviderInternal) {
        authProviderInternal = new MsalAuthProvider(signUpSignInAuthConfig(), {
            scopes: ['openid']
        }, {
            loginType: ((_b = (_a = getAppConfig()) === null || _a === void 0 ? void 0 : _a.azureB2CConfig) === null || _b === void 0 ? void 0 : _b.useRedirectLoginType) ? LoginType.Redirect : LoginType.Popup,
            // When a token is refreshed it will be done by loading a page in an iframe.
            // Rather than reloading the same page, we can point to an empty html file which will prevent
            // site resources from being loaded twice.
            tokenRefreshUri: window.location.origin + '/auth.html'
        });
        authProviderInternal.registerErrorHandler((error) => {
            if (error) {
                console.log('authProvider errorHandler error:', error);
                // Check for forgot password error
                checkAndRedirectIfIsForgotPasswordError(error);
            }
        });
        authProviderInternal.handleRedirectCallback((error, response) => {
            if (error) {
                console.log('authProvider handleRedirectCallback error:', error);
                // Check for forgot password error
                checkAndRedirectIfIsForgotPasswordError(error);
            }
        });
    }
    return authProviderInternal;
}
;
