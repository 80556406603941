import React from 'react';
import { Stepper, StepButton, Step, StepLabel, useMediaQuery, Box, makeStyles, createStyles, Select, MenuItem } from '@material-ui/core';
import { toNumber } from 'lodash';
import SelectedIcon from '@material-ui/icons/LocationOn';
import styles from './CalculationStepper.module.scss';
const useStyles = makeStyles(() => createStyles({
    step: {
        hyphens: 'auto',
    },
}));
export default function CalculationStepper(props) {
    const { activeStepIndex, steps } = props;
    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const isTabletView = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const classes = useStyles();
    return isMobileView || (isTabletView && steps.length > 5) ? (React.createElement(Box, null,
        React.createElement(Select, { fullWidth: true, variant: "outlined", value: activeStepIndex, onChange: (event) => props.handleBreadcrumbStepChange(toNumber(event.target.value)) }, steps.map((step, index) => (React.createElement(MenuItem, { key: step.id, value: index },
            index + 1,
            ":\u00A0",
            step.renderLabel())))))) : (React.createElement(Stepper, { nonLinear: true, alternativeLabel: true, activeStep: activeStepIndex }, steps.map((step, index) => (React.createElement(Step, { key: step.id, className: classes.step },
        React.createElement(StepButton, { color: "inherit", onClick: () => props.handleBreadcrumbStepChange(index) },
            React.createElement(StepLabel, { StepIconComponent: StepIcon }, step.renderLabel(true))))))));
}
function StepIcon(props) {
    const { active } = props;
    return React.createElement("div", { className: styles.stepIcon }, active
        ? React.createElement(SelectedIcon, { className: styles.active })
        : React.createElement("div", { className: styles.circle }));
}
