import React from 'react';
import styles from '../ChooseCountry/ChooseCountry.module.scss';
import { Trans } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { getLinks } from '../../common/links';
export default function footerLinks() {
    const links = getLinks();
    const linkDatas = [
        { link: links.cookiePolicy, i18nKey: 'choose-country.cookie-policy', defaultName: 'Cookie Policy & Control' },
        { link: links.privacyPolicy, i18nKey: 'choose-country.privacy-policy', defaultName: 'Website Privacy Policy' },
        { link: links.marketingConsent, i18nKey: 'choose-country.marketing-consent', defaultName: 'Direct Marketing Consent Notice' },
        { link: links.termsAndConditions, i18nKey: 'choose-country.terms-and-conditions', defaultName: 'Terms & Conditions of Website Use' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { width: '100%', paddingBottom: '5px' } },
            React.createElement("div", { style: { float: 'right' } }, "\u00A9 Kingspan Insulation")),
        React.createElement("div", { className: styles.footerLinks },
            React.createElement(Grid, { container: true, spacing: 2 },
                linkDatas.map((linkData, index) => (React.createElement(Grid, { item: true, xs: 12, sm: 6, key: index },
                    React.createElement("a", { href: linkData.link, target: "_blank", rel: "noopener noreferrer  " },
                        React.createElement(Trans, { i18nKey: linkData.i18nKey }, linkData.defaultName))))),
                React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                    React.createElement("a", { href: "#bottom", onClick: () => CookieControl.open() }, "Cookie Settings"))))));
}
