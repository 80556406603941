export const getLinks = () => {
    const previouslySelectedCountryId = localStorage.getItem('previouslySelectedCountryId');
    const i18nextLng = localStorage.getItem('i18nextLng');
    const defaultLinks = {
        cookiePolicy: 'https://www.kingspan.com/gb/en/business-groups/kingspan-insulation/u-value-calculator-cookie-policy-and-control',
        privacyPolicy: 'https://www.kingspan.com/gb/en/business-groups/kingspan-insulation/u-value-calculator-website-privacy-notice',
        marketingConsent: 'https://www.kingspan.com/gb/en/business-groups/kingspan-insulation/u-value-calculator-direct-marketing-consent',
        termsAndConditions: 'https://www.kingspan.com/gb/en/business-groups/kingspan-insulation/u-value-calculator-terms-and-conditions-of-website-use',
    };
    switch (previouslySelectedCountryId) {
        case 'gb':
            return defaultLinks;
        case 'ie':
            return {
                cookiePolicy: 'https://www.kingspan.com/ie/en/business-groups-in-ireland/kingspan-insulation-boards/u-value-calculator-cookie-policy-and-control',
                privacyPolicy: 'https://www.kingspan.com/ie/en/business-groups-in-ireland/kingspan-insulation-boards/u-value-calculator-website-privacy-notice',
                marketingConsent: 'https://www.kingspan.com/ie/en/business-groups-in-ireland/kingspan-insulation-boards/u-value-calculator-direct-marketing-consent',
                termsAndConditions: 'https://www.kingspan.com/ie/en/business-groups-in-ireland/kingspan-insulation-boards/u-value-calculator-terms-and-conditions-of-website-use',
            };
        case 'be': {
            if (i18nextLng === 'nl-BE') {
                return {
                    cookiePolicy: 'https://kingspan.com/be/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-cookiebeleid-en-beheer',
                    privacyPolicy: 'https://kingspan.com/be/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-privacyverklaring-website',
                    marketingConsent: 'https://kingspan.com/be/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-direct-marketing-toestemming',
                    termsAndConditions: 'https://kingspan.com/be/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-algemene-voorwaarden-voor-gebruik-van-de-website',
                };
            }
            return {
                cookiePolicy: 'https://www.kingspan.com/be/fr/divisions/kingspaninsulation/u-value-calculator-politique-controle-sur-les-cookies',
                privacyPolicy: 'https://www.kingspan.com/be/fr/divisions/kingspaninsulation/u-value-calculator-politique-de-confidentialite-du-site-internet',
                marketingConsent: 'https://www.kingspan.com/be/fr/divisions/kingspaninsulation/u-value-calculator-engagements-de-kingspan-direct-marketing',
                termsAndConditions: 'https://www.kingspan.com/be/fr/divisions/kingspaninsulation/u-value-calculator-modalites-et-conditions-generales-dutilisation-du-site-internet',
            };
        }
        case 'nl':
            return {
                cookiePolicy: 'https://www.kingspan.com/nl/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-cookiebeleid-en-beheer',
                privacyPolicy: 'https://kingspan.com/nl/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-privacyverklaring-website',
                marketingConsent: 'https://www.kingspan.com/nl/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-direct-marketing-toestemming',
                termsAndConditions: 'https://kingspan.com/nl/nl/kingspan-divisies/kingspaninsulation/u-value-calculator-algemene-voorwaarden-voor-gebruik-van-de-website',
            };
        default:
            return defaultLinks;
    }
};
