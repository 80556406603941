import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, CircularProgress, createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, useMediaQuery, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import * as Locales from 'date-fns/locale';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getAppConfig } from '../../config/config';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import { activeConstructionTypeIdSelector, activeConstructionTypeSelector, countiesSelector, countryIdSelector, projectConfigSelector, selectedCultureSelector, fieldDataSelector } from '../../store/component-state/component-state.selectors';
import { constructionTypeNameSelector } from '../../store/construction-data/construction-data.selectors';
import { setAdditionalParameters, setProjectDetails } from '../../store/current-calculation-data/current-calculation-data.actions';
import { additionalParametersSelector, projectDetailsSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { ConstructionTypeAdditionalParameters } from '../../types/domain/construction-data.types';
import NewsItemBanner from '../NewsItem/NewsItemBanner';
import { PerimeterAreaConstants } from './PerimeterAndArea/perimeter-area.types';
import PerimeterAndArea from './PerimeterAndArea/PerimeterAndArea';
const useStyles = makeStyles((theme) => createStyles({
    [theme.breakpoints.down('xs')]: {
        formControl: {
            width: '100%',
        }
    },
    [theme.breakpoints.up('sm')]: {
        formControl: {
            width: 320,
        }
    },
    [theme.breakpoints.up('md')]: {
        formControl: {
            width: 380,
        }
    },
}));
export default function CalculationProjectDetails(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { handleContinue } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const apiUrl = (_a = getAppConfig()) === null || _a === void 0 ? void 0 : _a.apiUrl;
    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const constructionTypeId = useSelector(activeConstructionTypeIdSelector);
    const activeConstructionType = useSelector(activeConstructionTypeSelector);
    const constructionTypeName = useSelector(constructionTypeNameSelector(constructionTypeId));
    const countryId = useSelector(countryIdSelector);
    const language = useSelector(selectedCultureSelector);
    const projectDetails = useSelector(projectDetailsSelector);
    const projectConfig = useSelector(projectConfigSelector);
    const counties = useSelector(countiesSelector);
    const fieldData = useSelector(fieldDataSelector);
    const [projectName, setProjectName] = useState({ value: '', error: '' });
    const [startDate, setStartDate] = useState({ value: null, error: '' });
    const [area, setArea] = useState(PerimeterAreaConstants.defaultArea);
    const [perimeter, setPerimeter] = useState(PerimeterAreaConstants.defaultPerimeter);
    const additionalParameters = useSelector(additionalParametersSelector);
    const countiesFiltered = counties.filter((county) => {
        return county.countryId === (projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.country);
    });
    const [countyName, setCountyName] = useState({ value: '', error: '' });
    const sizes = fieldData.filter((fieldData) => {
        return fieldData.fieldName === 'ProjectSize';
    });
    const types = fieldData.filter((fieldData) => {
        return fieldData.fieldName === 'ProjectType';
    });
    const statuses = fieldData.filter((fieldData) => {
        return fieldData.fieldName === 'RIBAStatus';
    });
    const uses = fieldData.filter((fieldData) => {
        return fieldData.fieldName === 'BuildingUse';
    });
    const siteAreas = fieldData.filter((fieldData) => {
        var _a;
        if ((_a = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _a === void 0 ? void 0 : _a.includes('projectsitearea')) {
            return fieldData.fieldName === 'ProjectSiteArea';
        }
        else {
            return fieldData.fieldName === 'SiteArea';
        }
    });
    const [projectSize, setProjectSize] = useState({ value: '', error: '' });
    const [projectType, setProjectType] = useState({ value: '', error: '' });
    const [projectRibaStatus, setRIBAStatus] = useState({ value: '', error: '' });
    const [projectBuidingUse, setBuildingUse] = useState({ value: '', error: '' });
    const [projectPostcode, setPostcode] = useState({ value: '', error: '' });
    const [siteArea, setSiteArea] = useState({ value: '', error: '' });
    // Initialise values if they already exist in the store
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (projectDetails !== undefined) {
            setProjectName({ value: projectDetails.name, error: '' });
            setStartDate({ value: projectDetails.startDate, error: '' });
            if (((_a = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _a === void 0 ? void 0 : _a.includes('projectsitearea')) || ((_b = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _b === void 0 ? void 0 : _b.includes('sitearea'))) {
                setSiteArea({ value: projectDetails.siteArea, error: '' });
            }
            else {
                setSiteArea({ value: projectDetails.siteArea, error: '' });
            }
            if ((_c = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _c === void 0 ? void 0 : _c.includes('projectcounty')) {
                setCountyName({ value: projectDetails.county, error: '' });
            }
            else {
                setCountyName({ value: '', error: '' });
            }
            if ((_d = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _d === void 0 ? void 0 : _d.includes('projectsize')) {
                setProjectSize({ value: projectDetails.size, error: '' });
            }
            else {
                setProjectSize({ value: '', error: '' });
            }
            if ((_e = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _e === void 0 ? void 0 : _e.includes('projecttype')) {
                setProjectType({ value: projectDetails.type, error: '' });
            }
            else {
                setProjectType({ value: '', error: '' });
            }
            if ((_f = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _f === void 0 ? void 0 : _f.includes('ribastatus')) {
                setRIBAStatus({ value: projectDetails.ribaStatus, error: '' });
            }
            else {
                setRIBAStatus({ value: '', error: '' });
            }
            if ((_g = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _g === void 0 ? void 0 : _g.includes('buildinguse')) {
                setBuildingUse({ value: projectDetails.buildingUse, error: '' });
            }
            else {
                setBuildingUse({ value: '', error: '' });
            }
            if ((_h = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _h === void 0 ? void 0 : _h.includes('postcode')) {
                setPostcode({ value: projectDetails.postcode, error: '' });
            }
            else {
                setPostcode({ value: '', error: '' });
            }
        }
    }, [projectDetails, projectConfig]);
    useEffect(() => {
        var _a, _b;
        if (additionalParameters !== undefined) {
            setArea((_a = additionalParameters.areaMetresSquared) !== null && _a !== void 0 ? _a : PerimeterAreaConstants.defaultArea);
            setPerimeter((_b = additionalParameters.perimeterMetres) !== null && _b !== void 0 ? _b : PerimeterAreaConstants.defaultPerimeter);
        }
    }, [additionalParameters]);
    const onProjectNameChange = (event) => {
        setProjectName({
            value: event.target.value,
            error: '',
        });
    };
    const onSiteAreaChange = (event) => {
        setSiteArea({
            value: event.target.value,
            error: '',
        });
    };
    const onStartDateChange = (date) => {
        setStartDate({
            value: date,
            error: '',
        });
    };
    const onCountyChange = (event) => {
        setCountyName({
            value: event.target.value,
            error: '',
        });
    };
    const onProjectSizeChange = (event) => {
        setProjectSize({
            value: event.target.value,
            error: '',
        });
    };
    const onProjectTypeChange = (event) => {
        setProjectType({
            value: event.target.value,
            error: '',
        });
    };
    const onRIBAStatusChange = (event) => {
        setRIBAStatus({
            value: event.target.value,
            error: '',
        });
    };
    const onBuildingUseChange = (event) => {
        setBuildingUse({
            value: event.target.value,
            error: '',
        });
    };
    const onPostcodeChange = (event) => {
        setPostcode({
            value: event.target.value,
            error: '',
        });
    };
    const validate = () => {
        var _a, _b, _c, _d, _e, _f;
        let isValid = true;
        if (projectName.value === '') {
            isValid = false;
            setProjectName(Object.assign(Object.assign({}, projectName), { error: t('project-details-ui.project-name.error-empty') }));
        }
        if (projectName.value.length > 150) {
            isValid = false;
            setProjectName(Object.assign(Object.assign({}, projectName), { error: t('project-details-ui.project-name.error-too-long') }));
        }
        if (startDate.value === null) {
            isValid = false;
            setStartDate(Object.assign(Object.assign({}, startDate), { error: t('project-details-ui.start-date.error-empty') }));
        }
        if (projectPostcode.value.length > 150) {
            isValid = false;
            setPostcode(Object.assign(Object.assign({}, projectPostcode), { error: t('project-details-ui.postcode.error-too-long') }));
        }
        if ((_a = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _a === void 0 ? void 0 : _a.includes('projectcounty')) {
            if (countyName.value === '') {
                isValid = false;
                setCountyName(Object.assign(Object.assign({}, countyName), { error: t('project-details-ui.county.error-empty') }));
            }
        }
        if ((_b = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _b === void 0 ? void 0 : _b.includes('projectsitearea')) {
            if (siteArea.value === '') {
                isValid = false;
                setSiteArea(Object.assign(Object.assign({}, siteArea), { error: t('project-details-ui.site-area.error-empty') }));
            }
        }
        if ((_c = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _c === void 0 ? void 0 : _c.includes('projectsize')) {
            if (projectSize.value === '') {
                isValid = false;
                setProjectSize(Object.assign(Object.assign({}, projectSize), { error: t('project-details-ui.size.error-empty') }));
            }
        }
        if ((_d = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _d === void 0 ? void 0 : _d.includes('projecttype')) {
            if (projectType.value === '') {
                isValid = false;
                setProjectType(Object.assign(Object.assign({}, projectType), { error: t('project-details-ui.type.error-empty') }));
            }
        }
        if ((_e = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _e === void 0 ? void 0 : _e.includes('ribastatus')) {
            if (projectRibaStatus.value === '') {
                isValid = false;
                setRIBAStatus(Object.assign(Object.assign({}, projectRibaStatus), { error: t('project-details-ui.ribastatus.error-empty') }));
            }
        }
        if ((_f = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _f === void 0 ? void 0 : _f.includes('buildinguse')) {
            if (projectBuidingUse.value === '') {
                isValid = false;
                setBuildingUse(Object.assign(Object.assign({}, projectBuidingUse), { error: t('project-details-ui.buildinguse.error-empty') }));
            }
        }
        return isValid;
    };
    const onContinueClick = () => {
        const isValid = validate();
        if (isValid) {
            dispatch(setProjectDetails({
                name: projectName.value,
                siteArea: siteArea.value,
                startDate: startDate.value,
                county: countyName.value,
                size: projectSize.value,
                type: projectType.value,
                ribaStatus: projectRibaStatus.value,
                buildingUse: projectBuidingUse.value,
                postcode: projectPostcode.value
            }));
            dispatch(setAdditionalParameters({
                areaMetresSquared: area,
                perimeterMetres: perimeter
            }));
            handleContinue();
        }
    };
    const getLocale = (localeCode) => {
        var _a, _b;
        const locales = Locales;
        return (_b = (_a = locales[localeCode.replace('-', '')]) !== null && _a !== void 0 ? _a : locales[localeCode.substring(0, 2)]) !== null && _b !== void 0 ? _b : locales['enGB'];
    };
    return (React.createElement(Box, null,
        React.createElement(NewsItemBanner, { countryId: countryId, constructionType: constructionTypeId, language: language }),
        React.createElement(Box, { mx: 2 },
            React.createElement("h1", null, constructionTypeName),
            React.createElement("h2", null,
                React.createElement(Trans, { i18nKey: "project-details-ui.sub-title" }, "Project details"))),
        React.createElement(Grid, { container: true, direction: isMobileView ? 'column-reverse' : 'row' },
            React.createElement(Grid, { item: true, container: true, xs: 12, sm: 6 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 2, my: 4 },
                        React.createElement(TextField, { id: "project-name", label: t('project-details-ui.project-name.label'), variant: "outlined", className: classes.formControl, value: projectName.value, onChange: onProjectNameChange, error: !isEmpty(projectName.error), helperText: projectName.error })),
                    React.createElement(Box, { mx: 2 },
                        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils, locale: getLocale(language) },
                            React.createElement(KeyboardDatePicker, { disableToolbar: true, variant: "inline", format: "dd/MM/yyyy", inputVariant: "outlined", margin: "normal", id: "start-date-picker", label: t('project-details-ui.start-date.label'), className: classes.formControl, value: startDate.value, onChange: onStartDateChange, error: !isEmpty(startDate.error), helperText: startDate.error })))),
                ((_b = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _b === void 0 ? void 0 : _b.includes('postcode')) &&
                    React.createElement(Box, { mx: 2, my: 4 },
                        React.createElement(TextField, { id: "postcode", label: t('project-details-ui.postcode.label'), variant: "outlined", className: classes.formControl, value: projectPostcode.value, onChange: onPostcodeChange, error: !isEmpty(projectPostcode.error), helperText: projectPostcode.error })),
                (((_c = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _c === void 0 ? void 0 : _c.includes('projectsitearea')) || ((_d = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _d === void 0 ? void 0 : _d.includes('sitearea'))) &&
                    React.createElement(Box, { mx: 2, my: 2 },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "site-area-select-label", error: !isEmpty(siteArea.error) }, t('project-details-ui.site-area.label')),
                            React.createElement(Select, { labelId: "site-area-select-label", id: "site-area-select-helper", label: t('project-details-ui.site-area.label'), value: siteArea.value, onChange: onSiteAreaChange, error: !isEmpty(siteArea.error) }, siteAreas.map(siteArea => (React.createElement(MenuItem, { key: siteArea.id, value: siteArea.code },
                                React.createElement(Trans, { i18nKey: `project-details-ui.site-area.${siteArea.code}` },
                                    " ",
                                    siteArea.code,
                                    " "))))),
                            React.createElement(FormHelperText, { error: !isEmpty(siteArea.error) }, siteArea.error))),
                ((_e = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _e === void 0 ? void 0 : _e.includes('projectcounty')) &&
                    React.createElement(Box, { mx: 2, my: 2 },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "county-select-label", error: !isEmpty(countyName.error) }, t('project-details-ui.county.label')),
                            React.createElement(Select, { labelId: "county-select-label", id: "county-select-helper", label: t('project-details-ui.county.label'), value: countyName.value, onChange: onCountyChange, error: !isEmpty(countyName.error) }, countiesFiltered.map(county => (React.createElement(MenuItem, { key: county.id, value: county.code },
                                React.createElement(Trans, { i18nKey: `project-details-ui.county.${county}` },
                                    " ",
                                    county.code,
                                    " "))))),
                            React.createElement(FormHelperText, { error: !isEmpty(countyName.error) }, countyName.error))),
                ((_f = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _f === void 0 ? void 0 : _f.includes('projectsize')) &&
                    React.createElement(Box, { mx: 2, my: 2 },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "size-select-label", error: !isEmpty(projectSize.error) }, t('project-details-ui.size.label')),
                            React.createElement(Select, { labelId: "size-select-label", id: "size-select-helper", label: t('project-details-ui.size.label'), value: projectSize.value, onChange: onProjectSizeChange, error: !isEmpty(projectSize.error) }, sizes.map(size => (React.createElement(MenuItem, { key: size.id, value: size.code },
                                React.createElement(Trans, { i18nKey: `project-details-ui.size.${size.code}` },
                                    " ",
                                    size.code,
                                    " "))))),
                            React.createElement(FormHelperText, { error: !isEmpty(projectSize.error) }, projectSize.error))),
                ((_g = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _g === void 0 ? void 0 : _g.includes('projecttype')) &&
                    React.createElement(Box, { mx: 2, my: 2 },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "type-select-label", error: !isEmpty(projectType.error) }, t('project-details-ui.type.label')),
                            React.createElement(Select, { labelId: "type-select-label", id: "type-select-helper", label: t('project-details-ui.type.label'), value: projectType.value, onChange: onProjectTypeChange, error: !isEmpty(projectType.error) }, types.map(type => (React.createElement(MenuItem, { key: type.id, value: type.code },
                                React.createElement(Trans, { i18nKey: `project-details-ui.type.${type.code}` },
                                    " ",
                                    type.code,
                                    " "))))),
                            React.createElement(FormHelperText, { error: !isEmpty(projectType.error) }, projectType.error))),
                ((_h = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _h === void 0 ? void 0 : _h.includes('ribastatus')) &&
                    React.createElement(Box, { mx: 2, my: 2 },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "ribastatus-select-label", error: !isEmpty(projectRibaStatus.error) }, t('project-details-ui.ribastatus.label')),
                            React.createElement(Select, { labelId: "ribastatus-select-label", id: "ribastatus-select-helper", label: t('project-details-ui.ribastatus.label'), value: projectRibaStatus.value, onChange: onRIBAStatusChange, error: !isEmpty(projectRibaStatus.error) }, statuses.map(status => (React.createElement(MenuItem, { key: status.id, value: status.code },
                                React.createElement(Trans, { i18nKey: `project-details-ui.ribastatus.${status.code}` },
                                    " ",
                                    status.code,
                                    " "))))),
                            React.createElement(FormHelperText, { error: !isEmpty(projectRibaStatus.error) }, projectRibaStatus.error))),
                ((_j = projectConfig === null || projectConfig === void 0 ? void 0 : projectConfig.fieldNames) === null || _j === void 0 ? void 0 : _j.includes('buildinguse')) &&
                    React.createElement(Box, { mx: 2, my: 2 },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "buildinguse-select-label", error: !isEmpty(projectBuidingUse.error) }, t('project-details-ui.buildinguse.label')),
                            React.createElement(Select, { labelId: "buildinguse-select-label", id: "buildinguse-select-helper", label: t('project-details-ui.buildinguse.label'), value: projectBuidingUse.value, onChange: onBuildingUseChange, error: !isEmpty(projectBuidingUse.error) }, uses.map(use => (React.createElement(MenuItem, { key: use.id, value: use.code },
                                React.createElement(Trans, { i18nKey: `project-details-ui.buildinguse.${use.code}` },
                                    " ",
                                    use.code,
                                    " "))))),
                            React.createElement(FormHelperText, { error: !isEmpty(projectBuidingUse.error) }, projectBuidingUse.error))),
                (activeConstructionType === null || activeConstructionType === void 0 ? void 0 : activeConstructionType.additionalParameters) &&
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { mx: 2, my: 2 }, activeConstructionType.additionalParameters.some(a => a === ConstructionTypeAdditionalParameters.PerimeterAndArea) &&
                            React.createElement(PerimeterAndArea, { perimeter: perimeter, setPerimeter: setPerimeter, area: area, setArea: setArea }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 2, marginTop: 2 },
                        React.createElement(Button, { variant: "contained", color: "primary", onClick: onContinueClick, className: classes.formControl, "data-qa-id": "continueBtn" },
                            React.createElement(Trans, { i18nKey: "project-details-ui.continue-button" }, "Continue")))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 2, marginTop: 2, marginBottom: 4 },
                        React.createElement(RouterLink, { to: buildRouteWithCountry(countryId, '/') },
                            React.createElement(Button, { color: "primary", onClick: onContinueClick, className: classes.formControl },
                                React.createElement(Trans, { i18nKey: "project-details-ui.back-button" }, "Back")))))),
            React.createElement(Grid, { item: true, xs: 12, sm: 6 }, isEmpty(constructionTypeId)
                ? React.createElement(CircularProgress, null)
                : React.createElement("img", { "data-qa-id": "construction-type-image", style: { maxWidth: '100%' }, src: `${apiUrl}/content/build-up-images/${countryId.toUpperCase()}/${countryId.toUpperCase()}-${constructionTypeId}.jpg`, alt: constructionTypeName })))));
}
