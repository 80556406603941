export const FETCH_CALCULATIONS_ATTEMPT = '[Calculations] Fetch Calculcation Attempt';
export const FETCH_CALCULATIONS_SUCCESS = '[Calculations] Fetch Calculcation Success';
export const FETCH_CALCULATIONS_FAILURE = '[Calculations] Fetch Calculcation Failure';
export const DELETE_CALCULATION_ATTEMPT = '[Calculations] Delete Calculcation Attempt';
export const DELETE_CALCULATION_SUCCESS = '[Calculations] Delete Calculcation Success';
export const DELETE_CALCULATION_FAILURE = '[Calculations] Delete Calculcation Failure';
export const DOWNLOAD_CALCULATION_ATTEMPT = '[Calculations] Download Calculcation Attempt';
export const DOWNLOAD_CALCULATION_SUCCESS = '[Calculations] Download Calculcation Success';
export const DOWNLOAD_CALCULATION_FAILURE = '[Calculations] Download Calculcation Failure';
export const DOWNLOAD_PDF_CALCULATION_ATTEMPT = '[Calculations] Download PDF Calculcation Attempt';
export const DOWNLOAD_PDF_CALCULATION_SUCCESS = '[Calculations] Download PDF Calculcation Success';
export const DOWNLOAD_PDF_CALCULATION_FAILURE = '[Calculations] Download PDF Calculcation Failure';
