import React from 'react';
import { Grid, Button, makeStyles, createStyles, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
const useStyles = makeStyles((theme) => createStyles({
    fullWidthButton: {
        width: '100%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));
export default function CalculationWizardControls(props) {
    var _a, _b;
    const { activeStepIndex, steps, handleBackClick, handleNextClick } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const isLastStep = () => activeStepIndex === steps.length - 1;
    return (React.createElement(Grid, { container: true, direction: isMobileView ? 'column-reverse' : undefined },
        React.createElement(Grid, { item: true, xs: "auto", sm: "auto", md: 1 }),
        React.createElement(Grid, { item: true, xs: 12, sm: "auto" },
            React.createElement(Button, { onClick: handleBackClick, className: isMobileView ? classes.fullWidthButton : '', "data-qa-id": "buttonOnTheLeft", startIcon: React.createElement(KeyboardArrowLeftIcon, null) }, activeStepIndex > 0 ? (_a = steps[activeStepIndex - 1]) === null || _a === void 0 ? void 0 : _a.renderLabel() : t('calculation-ui.back-button'))),
        React.createElement(Grid, { item: true, xs: true }),
        React.createElement(Grid, { item: true, xs: 12, sm: "auto" },
            React.createElement(Button, { variant: "contained", color: "primary", className: isMobileView ? classes.fullWidthButton : '', onClick: handleNextClick, "data-qa-id": "buttonOnTheRight", endIcon: !isLastStep() && React.createElement(KeyboardArrowRightIcon, null) }, (_b = steps[activeStepIndex + 1]) === null || _b === void 0 ? void 0 : _b.renderLabel())),
        React.createElement(Grid, { item: true, xs: "auto", sm: "auto", md: 1 })));
}
