import axios from 'axios';
import * as he from 'he';
import { AuthenticationState } from 'react-aad-msal';
import { downloadPdf } from '../../common/pdf';
import { mapApiResponseArrayToCalculcationObjectCollection } from '../../helpers/calculation-response-helper';
import { deleteCalculationAttempt, deleteCalculationFailure, deleteCalculationSuccess, downloadCalculationAttempt, downloadCalculationFailure, downloadCalculationSuccess, downloadPdfCalculationAttempt, downloadPdfCalculationFailure, downloadPdfCalculationSuccess, fetchCalculationAttempt, fetchCalculationFailure, fetchCalculationSuccess, } from './calculations.actions';
export const fetchCalculation = (countryId) => async (dispatch, getState) => {
    dispatch(fetchCalculationAttempt());
    try {
        const { data } = await axios.get(`/api/calculation/${countryId}`, {
            withCredentials: true,
        });
        dispatch(fetchCalculationSuccess(mapApiResponseArrayToCalculcationObjectCollection(data)));
    }
    catch (error) {
        dispatch(fetchCalculationFailure(error));
    }
};
export const deleteCalculation = (calculationId) => async (dispatch, getState) => {
    dispatch(deleteCalculationAttempt(calculationId));
    try {
        await axios.delete(`/api/calculation/${calculationId}`, {
            withCredentials: true,
        });
        dispatch(deleteCalculationSuccess(calculationId));
    }
    catch (error) {
        dispatch(deleteCalculationFailure(error, calculationId));
    }
};
export const downloadCalculation = (calculationId, calculationAccessCode, emailAddress, gdprConsentGiven) => async (dispatch, getState) => {
    dispatch(downloadCalculationAttempt(calculationId));
    try {
        const { authenticationState } = getState();
        await axios.post('/api/pdf', {
            calculationId,
            calculationAccessCode,
            emailAddress,
            gdprConsentGiven,
        }, {
            withCredentials: authenticationState.state === AuthenticationState.Authenticated,
        });
        dispatch(downloadCalculationSuccess(calculationId));
        return true;
    }
    catch (error) {
        dispatch(downloadCalculationFailure(error, calculationId));
    }
    return false;
};
export const directDownloadCalculation = (calculationId, calculationAccessCode) => async (dispatch, getState) => {
    dispatch(downloadPdfCalculationAttempt(calculationId));
    try {
        const { authenticationState } = getState();
        const params = { calculationId: calculationId, calculationAccessCode: calculationAccessCode };
        const result = await axios.get('/api/pdf', {
            withCredentials: authenticationState.state === AuthenticationState.Authenticated,
            params,
            headers: {
                'Content-Type': 'application/pdf',
            },
            responseType: 'blob',
        });
        let filename;
        if (result.headers['filename']) {
            filename = he.decode(result.headers['filename']);
        }
        else {
            filename = `${calculationId}.pdf`;
        }
        downloadPdf(result.data, filename);
        dispatch(downloadPdfCalculationSuccess(calculationId));
        return true;
    }
    catch (error) {
        dispatch(downloadPdfCalculationFailure(error, calculationId));
    }
    return false;
};
