import { isEmpty } from 'lodash';
export const buildRoute = (store, relativeRoute) => {
    var _a, _b;
    const countryId = (_b = (_a = store.componentState.selectedCountry) === null || _a === void 0 ? void 0 : _a.countryId) !== null && _b !== void 0 ? _b : '';
    return buildRouteWithCountry(countryId, relativeRoute);
};
export const buildRouteWithCountry = (countryId, route) => {
    if (isEmpty(countryId)) {
        return '/';
    }
    return `/${countryId === null || countryId === void 0 ? void 0 : countryId.toLowerCase()}${route.startsWith('/') ? '' : '/'}${route}`;
};
