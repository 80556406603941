import React, { useState, useEffect } from 'react';
import { CalculationResultType } from '../../types/domain/calculation-data.types';
import { List, ListSubheader, ListItem, ListItemText, Collapse, makeStyles, createStyles, Box, ListItemSecondaryAction, IconButton, Button } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { groupBy, isEmpty } from 'lodash';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import FormatNumber from '../FormatNumber/FormatNumber';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));
export default function MyCalculationsMobile(props) {
    const { calculations, countryId, handleDownloadClick, handleDirectDownloadClick, handleDeleteClick, isDeleting } = props;
    const classes = useStyles();
    const history = useHistory();
    const [displayableCalculations, setDisplayableCalculations] = useState({});
    const [opens, setOpens] = useState({});
    useEffect(() => {
        const result = groupBy(calculations, calculation => calculation.projectDetails.name);
        setDisplayableCalculations(result);
    }, [calculations]);
    useEffect(() => {
        const result = Object.keys(displayableCalculations).reduce((acc, projectName) => (Object.assign(Object.assign({}, acc), { [projectName]: {
                open: false,
            } })), {});
        setOpens(result);
    }, [displayableCalculations]);
    const handleItemClick = (projectName) => {
        setOpens(Object.assign(Object.assign({}, opens), { [projectName]: {
                // @ts-ignore
                open: !opens[projectName].open,
            } }));
    };
    const getProjectStartDate = (projectName) => {
        // @ts-ignore
        if (isEmpty(displayableCalculations[projectName])) {
            return '';
        }
        // @ts-ignore
        return format(displayableCalculations[projectName][0].projectDetails.startDate, 'dd-MM-yyyy');
    };
    return (React.createElement("div", null,
        React.createElement(List, { component: "nav", "aria-labelledby": "nested-list-subheader", subheader: React.createElement(ListSubheader, { component: "div", id: "nested-list-subheader", "data-qa-id": "my-calculation-tab" }, "My calculations"), className: classes.root },
            React.createElement(ListItem, null,
                React.createElement(Button, { variant: "outlined", color: "secondary", component: Link, to: buildRouteWithCountry(countryId, '') },
                    React.createElement(Trans, { i18nKey: "my-calculations-ui.new-calculation" }, "New Calculation"))),
            Object.keys(displayableCalculations).map((projectName, index) => {
                var _a, _b;
                return (React.createElement(Box, { key: index, marginBottom: 2 },
                    React.createElement(ListItem, { button: true, divider: true, onClick: () => handleItemClick(projectName) },
                        React.createElement(ListItemText, { primary: projectName, secondary: getProjectStartDate(projectName) }),
                        ((_a = opens[projectName]) === null || _a === void 0 ? void 0 : _a.open) ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)),
                    React.createElement(Collapse, { in: 
                        // @ts-ignore
                        (_b = opens[projectName]) === null || _b === void 0 ? void 0 : _b.open, timeout: "auto", unmountOnExit: true },
                        React.createElement(List, { component: "div", disablePadding: true }, // @ts-ignore
                        displayableCalculations[projectName].map((calculation, index) => (React.createElement(Box, { key: index },
                            React.createElement(ListItem, { button: true, className: classes.nested },
                                React.createElement(ListItemText, { primary: calculation.constructionTypeNameLocalized, secondary: React.createElement("span", null,
                                        calculation.calculationResult.resultType === CalculationResultType.RValue
                                            ? React.createElement(Trans, { i18nKey: "my-calculations-ui.table.rvalue" }, "R Value ()")
                                            : React.createElement(Trans, { i18nKey: "my-calculations-ui.table.uvalue" }, "U Value ()"),
                                        ":",
                                        ' ',
                                        React.createElement(FormatNumber, { toFormat: calculation.calculationResult.resultValue }),
                                        " ",
                                        calculation.calculationResult.resultUnits) })),
                            React.createElement(ListItem, null,
                                React.createElement(ListItemText, null),
                                React.createElement(ListItemSecondaryAction, null,
                                    React.createElement(IconButton, { edge: "end", "aria-label": "copy", onClick: () => history.push(buildRouteWithCountry(countryId, `/calculation/${calculation.constructionTypeId}/${calculation.calculationId}?copy`)) },
                                        React.createElement(FileCopyIcon, null)),
                                    React.createElement(IconButton, { edge: "end", "aria-label": "edit", onClick: () => history.push(buildRouteWithCountry(countryId, `/calculation/${calculation.constructionTypeId}/${calculation.calculationId}`)) },
                                        React.createElement(EditIcon, null)),
                                    React.createElement(IconButton, { edge: "end", "aria-label": "download", onClick: () => handleDownloadClick(calculation.calculationId, calculation.calculationAccessCode) },
                                        React.createElement(EmailIcon, null)),
                                    React.createElement(IconButton, { edge: "end", "aria-label": "download", onClick: () => handleDirectDownloadClick(calculation.calculationId, calculation.calculationAccessCode) },
                                        React.createElement(GetAppIcon, null)),
                                    React.createElement(IconButton, { edge: "end", "aria-label": "delete", disabled: isDeleting(calculation.calculationId), onClick: () => handleDeleteClick(calculation.calculationId) },
                                        React.createElement(DeleteIcon, null)))))))))));
            }))));
}
