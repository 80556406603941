import React from 'react';
import AzureAD from 'react-aad-msal';
import authProviderFactory from '../../authentication/authProvider';
export default function PasswordReset() {
    return (React.createElement(AzureAD, { provider: authProviderFactory() }, ({ logout, accountInfo }) => {
        setTimeout(() => {
            if (accountInfo) {
                logout();
            }
            else {
                document.location.href = '/';
            }
        }, accountInfo ? 2000 : 500);
        return (accountInfo
            ? React.createElement("span", null, "Signing out. If you changed your password, please sign in with your new password.")
            : React.createElement("span", null, "Redirecting..."));
    }));
}
