import React from 'react';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentAnchorPropertiesSelector, currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector, useDispatch } from 'react-redux';
import ThicknessSlider from './ThicknessSlider';
import { setNetAreaPerMetreSquare } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
export default function AnchorParameters() {
    const dispatch = useDispatch();
    const layer = useSelector(activeConstructionLayerSelector);
    const { netAreaOfAnchorsPerMetreSquareMillimetresSqrd } = useSelector(currentAnchorPropertiesSelector);
    const currentSelectedMatrial = useSelector(currentSelectedMaterialSelector(false));
    const handleChange = (value) => {
        dispatch(setMaterialParameter(setNetAreaPerMetreSquare, layer, value, false));
    };
    const areaMarks = [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 180, 200, 220, 250, 280, 300, 320, 350, 380, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000];
    return (React.createElement("div", null,
        React.createElement(ThicknessSlider, { labelKey: 'area-label', handleChange: handleChange, selectedThickness: netAreaOfAnchorsPerMetreSquareMillimetresSqrd, disabled: !currentSelectedMatrial, marks: areaMarks, min: areaMarks[0], max: areaMarks[areaMarks.length - 1], defaultValue: 50, units: 'mm\u00B2', isValueFixed: false })));
}
