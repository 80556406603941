import * as actionTypes from './calculations.action-types';
export const initialState = {
    calculations: {},
    deletingCalculations: []
};
export const calculationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CALCULATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { calculations: Object.assign({}, action.payload) });
        case actionTypes.DELETE_CALCULATION_ATTEMPT:
            return Object.assign(Object.assign({}, state), { deletingCalculations: [
                    ...state.deletingCalculations,
                    action.payload,
                ] });
        case actionTypes.DELETE_CALCULATION_SUCCESS:
            const calcs = Object
                .values(state.calculations)
                .filter(c => c.calculationId !== action.payload)
                .reduce((obj, calc) => { return Object.assign(Object.assign({}, obj), { [calc.calculationId]: calc }); }, {});
            return {
                calculations: Object.assign({}, calcs),
                deletingCalculations: [
                    ...(state.deletingCalculations.filter(c => c !== action.payload))
                ],
            };
        case actionTypes.DELETE_CALCULATION_FAILURE:
            return Object.assign(Object.assign({}, state), { deletingCalculations: [
                    ...(state.deletingCalculations.filter(c => c !== action.payload))
                ] });
        default:
            return state;
    }
};
