import React from 'react';
import styles from './Header.module.scss';
import logo from '../../assets/images/kingspan-logo.svg';
import { Grid } from '@material-ui/core';
import Navigation from './Navigation/Navigation';
export function Header() {
    return (React.createElement(Grid, { container: true, justify: "space-between", alignItems: "center", className: styles.header },
        React.createElement(Grid, { item: true },
            React.createElement("a", { href: '/' },
                React.createElement("img", { src: logo, alt: "Logo", className: styles.logo }))),
        React.createElement(Grid, { item: true },
            React.createElement(Navigation, null))));
}
