import { SET_MATERIAL, SET_THICKNESS, NEW_CALCULATION_STARTED, SET_ANCHOR_DIAMETER, SET_ANCHOR_PER_SQUARE_METRE, FETCH_CALCULATION_RESULTS_ATTEMPT, FETCH_CALCULATION_RESULTS_SUCCESS, FETCH_CALCULATION_RESULTS_CLIENT_ERROR, FETCH_CALCULATION_RESULTS_FAILURE, REMOVE_LINKED_LAYER, CREATE_LINKED_LAYER, SET_WOOD_PERCENTAGE, SET_CALCULATED_LAYER_VALUE, SET_ANCHOR_PENETRATION, SET_NET_AREA_PER_METRE_SQUARE, SET_PROJECT_DETAILS, SET_ADDITIONAL_PARAMETERS, EDIT_CALCULATION_STARTED, CLEAR_CURRENT_CALCULATION, COPY_CALCULATION_STARTED, SET_AIR_CAVITY_GRADING } from './current-calculation-data.action-types';
export const setProjectDetails = (projectDetails) => ({
    type: SET_PROJECT_DETAILS,
    payload: projectDetails,
});
export const setAdditionalParameters = (additionalParameters) => ({
    type: SET_ADDITIONAL_PARAMETERS,
    payload: additionalParameters,
});
export const setAirCavityGradingInternal = (airCavityGrading, constructionLayerId) => ({
    type: SET_AIR_CAVITY_GRADING,
    payload: {
        airCavityGrading,
        constructionLayerId,
    }
});
export const setMaterialInternal = (material, constructionLayerId, isLinkedLayer) => ({
    type: SET_MATERIAL,
    payload: {
        material,
        constructionLayerId,
        isLinkedLayer,
    }
});
export const setThickness = (thicknessMillimetres, constructionLayerId, isLinkedLayer) => ({
    type: SET_THICKNESS,
    payload: {
        thicknessMillimetres,
        constructionLayerId,
        isLinkedLayer,
    }
});
export const newCalculationStarted = (layers, constructionTypeId) => ({
    type: NEW_CALCULATION_STARTED,
    payload: {
        calculationLayers: layers,
        constructionTypeId
    }
});
export const editCalculationStarted = (calculationId, calculationAccessCode, layers, constructionTypeId, projectDetails, calculationResult, additionalParameters) => ({
    type: EDIT_CALCULATION_STARTED,
    payload: {
        calculationId,
        calculationAccessCode,
        calculationLayers: layers,
        constructionTypeId,
        projectDetails,
        calculationResult,
        additionalParameters
    }
});
export const copyCalculationStarted = (layers, constructionTypeId, projectDetails, additionalParameters) => ({
    type: COPY_CALCULATION_STARTED,
    payload: {
        calculationLayers: layers,
        constructionTypeId,
        projectDetails,
        additionalParameters
    }
});
export const createLinkedLayer = (calculationLayer) => ({
    type: CREATE_LINKED_LAYER,
    payload: {
        calculationLayer,
    }
});
export const removeLinkedLayer = (constructionLayerId) => ({
    type: REMOVE_LINKED_LAYER,
    payload: {
        constructionLayerId,
    }
});
export const setAnchorDiameter = (diameterOfAnchorsMillimetres, constructionLayerId, isLinkedLayer) => ({
    type: SET_ANCHOR_DIAMETER,
    payload: {
        diameterOfAnchorsMillimetres,
        constructionLayerId,
        isLinkedLayer,
    }
});
export const setAnchorAmountPerSquareMetre = (numberOfAnchorsPerMetreSquare, constructionLayerId, isLinkedLayer) => ({
    type: SET_ANCHOR_PER_SQUARE_METRE,
    payload: {
        numberOfAnchorsPerMetreSquare,
        constructionLayerId,
        isLinkedLayer,
    }
});
export const setAnchorPenetration = (anchorPenetrationDepthMillimetres, constructionLayerId, isLinkedLayer) => ({
    type: SET_ANCHOR_PENETRATION,
    payload: {
        anchorPenetrationDepthMillimetres,
        constructionLayerId,
        isLinkedLayer,
    }
});
export const setNetAreaPerMetreSquare = (netAreaOfAnchorsPerMetreSquareMillimetresSqrd, constructionLayerId, isLinkedLayer) => ({
    type: SET_NET_AREA_PER_METRE_SQUARE,
    payload: {
        netAreaOfAnchorsPerMetreSquareMillimetresSqrd,
        constructionLayerId,
        isLinkedLayer,
    }
});
export const setWoodPercentage = (percentage, constructionLayerId, isLinkedLayer) => ({
    type: SET_WOOD_PERCENTAGE,
    payload: {
        percentage,
        constructionLayerId,
        isLinkedLayer,
    }
});
export const setCalculatedLayerValue = (bridgingLayer, insulationLayer, airCavityLayer) => ({
    type: SET_CALCULATED_LAYER_VALUE,
    payload: {
        bridgingLayer,
        insulationLayer,
        airCavityLayer,
    }
});
export const clearCurrentCalculation = () => ({
    type: CLEAR_CURRENT_CALCULATION,
});
export const fetchCalculationResultsAttempt = () => ({
    type: FETCH_CALCULATION_RESULTS_ATTEMPT,
});
export const fetchCalculationResultsSuccess = (data) => ({
    type: FETCH_CALCULATION_RESULTS_SUCCESS,
    payload: data,
});
export const fetchCalculationResultsFailure = (error) => ({
    type: FETCH_CALCULATION_RESULTS_FAILURE,
    error,
});
export const fetchCalculationResultsClientError = () => ({
    type: FETCH_CALCULATION_RESULTS_CLIENT_ERROR,
});
