import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { Header } from '../Header/Header';
import ErrorBar from './ErrorBar';
import { Footer } from '../Footer/Footer';
import styles from './Layout.module.scss';
import { useSelector } from 'react-redux';
import { countryIdSelector } from '../../store/component-state/component-state.selectors';
export function Layout({ children }) {
    const countryId = useSelector(countryIdSelector);
    useEffect(() => {
        if (countryId === 'nl') {
            const script = document.createElement('script');
            script.src = '/live-chat.js';
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [countryId]);
    return (React.createElement(Container, { disableGutters: true },
        React.createElement("div", { className: styles.page },
            React.createElement(Container, null,
                React.createElement(Header, null),
                React.createElement(ErrorBar, null),
                React.createElement("div", { "data-qa-id": "pageContent", className: styles.content }, children),
                React.createElement(Footer, null)))));
}
