import { createStyles, makeStyles, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { countryIdSelector } from '../../../store/component-state/component-state.selectors';
const useStyles = makeStyles((theme) => createStyles({
    address: {
        fontStyle: 'normal',
    },
}));
export function Address() {
    const { t } = useTranslation();
    const theme = useStyles();
    const countryId = useSelector(countryIdSelector);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h6" }, parse(t(`by-country.${countryId}.footer.address-label`)))),
        React.createElement("address", { className: theme.address },
            React.createElement("div", null,
                React.createElement(Typography, { variant: "body1" }, parse(t(`by-country.${countryId}.footer.address.line1`)))),
            React.createElement("div", null,
                React.createElement(Typography, { variant: "body1" }, parse(t(`by-country.${countryId}.footer.address.line2`)))),
            React.createElement("div", null,
                React.createElement(Typography, { variant: "body1" }, parse(t(`by-country.${countryId}.footer.address.line3`)))),
            React.createElement("div", null,
                React.createElement(Typography, { variant: "body1" }, parse(t(`by-country.${countryId}.footer.address.line4`)))))));
}
