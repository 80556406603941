import React from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedAirCavityGradingSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { setAirCavityGrading } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import AirCavityThickness from './AirCavityThickness';
export default function AirCavity() {
    var _a;
    const dispatch = useDispatch();
    const layer = useSelector(activeConstructionLayerSelector);
    const currentSelectedAcg = useSelector(currentSelectedAirCavityGradingSelector);
    const handleAcgChange = (event) => {
        dispatch(setAirCavityGrading(layer, layer.airCavityGradings.filter(ac => ac.airCavityGradingId === event.target.value)[0]));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'marginTop20' },
            React.createElement(Typography, { gutterBottom: true, "data-qa-id": "materialSelectLabel" },
                React.createElement(Trans, { i18nKey: "calculation-ui.material-label" }, "Material")),
            React.createElement(Select, { fullWidth: true, "data-qa-id": "materialSelect", variant: "outlined", value: (_a = currentSelectedAcg === null || currentSelectedAcg === void 0 ? void 0 : currentSelectedAcg.airCavityGradingId) !== null && _a !== void 0 ? _a : '', onChange: handleAcgChange }, layer.airCavityGradings.map(i => (React.createElement(MenuItem, { key: i.airCavityGradingId, value: i.airCavityGradingId, "data-qa-id": `${i.airCavityGradingId}-dropdownvalue` },
                React.createElement(Trans, { i18nKey: `calculation-ui.air-cavity-type.${i.airCavityGradingId}` }, i.airCavityGradingId)))))),
        React.createElement(AirCavityThickness, null)));
}
