import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { setActiveConstructionType, setActiveConstructionLayer } from '../../store/component-state/component-state.actions';
import CalculationProjectDetails from '../CalculationProjectDetails/CalculationProjectDetails';
import Calculation from '../Calculation/Calculation';
import { activeConstructionTypeIdSelector, selectedCultureSelector, isLoadingSelector } from '../../store/component-state/component-state.selectors';
import { fetchConstructionLayersForType, fetchConstructionLayersForCalculation } from '../../store/construction-data/construction-data.action-creators';
import { resetActiveConstructionLayerForCurrentCalculation } from '../../store/component-state/component-state.action-creators';
import { isEmpty } from 'lodash';
import { calculationSelector } from '../../store/calculations/calculations.selectors';
import { clearCurrentCalculation } from '../../store/current-calculation-data/current-calculation-data.actions';
import CalculationResults from '../CalculationResults/CalculationResults';
import { constructionLayerSelector, constructionTypesSelector } from '../../store/construction-data/construction-data.selectors';
import { useHistory } from 'react-router-dom';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import CalculationStepper from './CalculationStepper';
import { CalculationStep } from './calculation-page.types';
import { Trans } from 'react-i18next';
import LayerName from '../Calculation/LayerName';
var CalculationStage;
(function (CalculationStage) {
    CalculationStage[CalculationStage["INITIALIZING"] = 0] = "INITIALIZING";
    CalculationStage[CalculationStage["PROJECT_DETAILS"] = 1] = "PROJECT_DETAILS";
    CalculationStage[CalculationStage["CALCULATION"] = 2] = "CALCULATION";
    CalculationStage[CalculationStage["RESULTS"] = 3] = "RESULTS";
})(CalculationStage || (CalculationStage = {}));
function parseSearch(search) {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('results')) {
        return { isResultsStep: true };
    }
    return {
        isResultsStep: false,
        layerName: searchParams.get('layer'),
    };
}
export default function CalculationPage() {
    var _a;
    const dispatch = useDispatch();
    const { constructionTypeId, calculationId, countryId } = useParams();
    const { search } = useLocation();
    const history = useHistory();
    const isLoading = useSelector(isLoadingSelector);
    const activeConstructionTypeId = useSelector(activeConstructionTypeIdSelector);
    const layers = useSelector(constructionLayerSelector(constructionTypeId));
    const calculation = useSelector(calculationSelector(calculationId));
    const constructionTypes = useSelector(constructionTypesSelector);
    const [isCopy] = useState(search.indexOf('?copy') > -1);
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [steps, setSteps] = useState([]);
    const selectStep = (stepToSelect) => {
        var _a;
        const urlParts = [`/calculation/${constructionTypeId}`];
        if (calculationId) {
            urlParts.push(`/${calculationId}`);
        }
        if (typeof stepToSelect === 'number') {
            if (stepToSelect >= steps.length - 1) {
                urlParts.push('?results');
            }
            else {
                urlParts.push(`?layer=${(_a = layers[stepToSelect]) === null || _a === void 0 ? void 0 : _a.name}`);
            }
        }
        const url = urlParts.join('');
        history.push(buildRouteWithCountry(countryId, url));
    };
    const handleNextStep = () => selectStep(activeStepIndex + 1);
    const handlePreviousStep = () => {
        if (activeStepIndex === 0) {
            selectStep('project-details');
        }
        else {
            selectStep(activeStepIndex - 1);
        }
    };
    const [calculationStage, setCalculationStage] = useState(CalculationStage.INITIALIZING);
    // Build the list of steps to reflect the layers for the current Construction Type
    useEffect(() => {
        // wait until we have initialised, this will mean the layers we have are for the correct Construction Type
        if (constructionTypeId === activeConstructionTypeId && !isLoading) {
            const layerSteps = (layers !== null && layers !== void 0 ? layers : []).map(layer => new CalculationStep((insertSuggestedHyphenationIfRequired) => React.createElement(LayerName, { layer: layer, insertSuggestedHyphenationIfRequired: insertSuggestedHyphenationIfRequired }), `calc-layer-step-${layer.name}`));
            const finishStep = new CalculationStep(() => React.createElement(Trans, { i18nKey: "calculation-summary-ui.construction-summary-text" }, "Construction Summary"), 'calc-results-step');
            setSteps([...layerSteps, finishStep]);
        }
    }, [layers, constructionTypeId, activeConstructionTypeId, isLoading]);
    // Update the state whenever the user navigates to a different step
    useEffect(() => {
        // wait until we have initialised, this will mean the layers we have are for the correct Construction Type
        if (constructionTypeId === activeConstructionTypeId && !isLoading) {
            const { isResultsStep, layerName } = parseSearch(search);
            const layerIndex = layers && layerName ? layers.findIndex((layer) => layer.name === layerName) : -1;
            const layer = layers[layerIndex];
            if (layer) {
                setActiveStepIndex(layerIndex);
                setCalculationStage(CalculationStage.CALCULATION);
                dispatch(setActiveConstructionLayer(layer.constructionLayerId));
            }
            else if (isResultsStep) {
                setActiveStepIndex(layers.length);
                setCalculationStage(CalculationStage.RESULTS);
            }
            else {
                setCalculationStage(CalculationStage.PROJECT_DETAILS);
            }
        }
    }, [dispatch, search, layers, constructionTypeId, activeConstructionTypeId, isLoading]);
    // Initialise the Calculation Page and its layers
    useEffect(() => {
        dispatch(setActiveConstructionType(constructionTypeId));
        if (!isEmpty(countryId) && isEmpty(calculationId)) {
            dispatch(fetchConstructionLayersForType(constructionTypeId, countryId));
        }
        else if (!isEmpty(countryId) && !isEmpty(calculationId) && calculation) {
            dispatch(fetchConstructionLayersForCalculation(calculation, isCopy));
        }
        return function cleanup() {
            dispatch(clearCurrentCalculation());
        };
    }, [dispatch, constructionTypeId, countryId, calculationId, calculation, isCopy]);
    // Ensure latest translations from the backend are loaded
    const selectedCulture = useSelector(selectedCultureSelector);
    const isInitialised = React.useRef(false);
    useEffect(() => {
        if (!isInitialised.current) {
            isInitialised.current = true;
        }
        else {
            console.info('Selected culture/constructionType changed, so dispatching refresh of the current construction\'s layers and material names');
            dispatch(fetchConstructionLayersForType(activeConstructionTypeId, countryId, true));
        }
    }, [dispatch, selectedCulture, activeConstructionTypeId, countryId]);
    // If the Construction Types haven't been loaded yet, that means the page has been refreshed within a Calculation
    // This is not supported, and causes errors and sequencing issues, so redirect the user back to the Construction Type Selection screen
    if (((_a = constructionTypes === null || constructionTypes === void 0 ? void 0 : constructionTypes.length) !== null && _a !== void 0 ? _a : 0) === 0) {
        return React.createElement(Redirect, { to: `/${countryId}` });
    }
    const hasSteps = steps && steps.length > 0;
    const stepper = () => (hasSteps &&
        React.createElement(CalculationStepper, { activeStepIndex: activeStepIndex, steps: steps, handleBreadcrumbStepChange: newStepIndex => selectStep(newStepIndex) }));
    switch (calculationStage) {
        case CalculationStage.PROJECT_DETAILS:
            return (React.createElement(CalculationProjectDetails, { handleContinue: () => selectStep(0) }));
        case CalculationStage.CALCULATION:
            return (React.createElement(React.Fragment, null,
                stepper(),
                React.createElement(Calculation, { steps: steps, activeStepIndex: activeStepIndex, constructionTypeId: activeConstructionTypeId, handleNextStep: handleNextStep, handlePreviousStep: handlePreviousStep })));
        case CalculationStage.RESULTS:
            return (React.createElement(React.Fragment, null,
                stepper(),
                React.createElement(CalculationResults, { layers: layers, handleBack: () => {
                        dispatch(resetActiveConstructionLayerForCurrentCalculation());
                        selectStep('project-details');
                    } })));
        default:
            return (React.createElement("div", null,
                React.createElement(CircularProgress, { size: 50 })));
    }
}
