import cmp from 'semver-compare';
export const isUnsupportedBrowser = (browser) => {
    switch (browser && browser.name) {
        case 'chrome':
            return cmp(browser.version, '85') < 0;
        case 'firefox':
            return cmp(browser.version, '80') < 0;
        case 'edge':
            return cmp(browser.version, '84') < 0;
        case 'safari':
            return cmp(browser.version, '13') < 0;
        default:
            return false;
    }
};
