export var Category;
(function (Category) {
    Category["Floor"] = "Floor";
    Category["Roof"] = "Roof";
    Category["Wall"] = "Wall";
})(Category || (Category = {}));
export var LayerType;
(function (LayerType) {
    LayerType["Other"] = "Other";
    LayerType["Anchors"] = "Anchors";
    LayerType["Insulation"] = "Insulation";
    LayerType["AirCavity"] = "AirCavity";
    LayerType["Bridging"] = "Bridging";
    LayerType["AnchorsPreCalculated"] = "AnchorsPreCalculated";
    LayerType["WoodPercentage"] = "WoodPercentage";
})(LayerType || (LayerType = {}));
export var Complexity;
(function (Complexity) {
    Complexity["Simple"] = "Simple";
    Complexity["Complex"] = "Complex";
})(Complexity || (Complexity = {}));
export var ConstructionTypeAdditionalParameters;
(function (ConstructionTypeAdditionalParameters) {
    ConstructionTypeAdditionalParameters["PerimeterAndArea"] = "PerimeterAndArea";
})(ConstructionTypeAdditionalParameters || (ConstructionTypeAdditionalParameters = {}));
