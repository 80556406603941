import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { countryIdSelector } from '../../../store/component-state/component-state.selectors';
const useStyles = makeStyles((theme) => createStyles({
    container: {
        padding: theme.spacing(1),
    },
    iconContainer: {
        justifyContent: 'center',
        display: 'flex',
    },
    contactInfo: {
        fontSize: theme.typography.caption.fontSize,
        paddingLeft: '5px',
    },
    techSupportContainer: {
        backgroundColor: theme.palette.grey[500],
        color: '#FFF',
    },
    techLink: {
        color: '#FFF',
    }
}));
export function TechSupportBar() {
    const { t } = useTranslation();
    const theme = useStyles();
    const countryId = useSelector(countryIdSelector);
    return (React.createElement(React.Fragment, null, countryId &&
        React.createElement("div", { className: theme.techSupportContainer },
            React.createElement(Grid, { className: theme.container, container: true, justify: "space-evenly", alignItems: "center" },
                React.createElement(Grid, { item: true, xs: 12, style: { textAlign: 'center' } },
                    React.createElement(Box, { p: 1 },
                        React.createElement(Typography, { variant: "body1" },
                            parse(t(`by-country.${countryId}.footer.tech-support.blurb`)),
                            "\u00A0",
                            React.createElement("a", { href: t(`by-country.${countryId}.footer.tech-support.link-value`), className: theme.techLink }, parse(t(`by-country.${countryId}.footer.tech-support.link-friendly`)))))),
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, justify: "space-evenly", alignItems: "center" },
                        React.createElement(Grid, { item: true, className: theme.iconContainer },
                            React.createElement(MailIcon, null)),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { className: theme.contactInfo },
                                React.createElement("a", { href: 'mailto:' + t(`by-country.${countryId}.footer.tech-support.email-value`), className: theme.techLink }, parse(t(`by-country.${countryId}.footer.tech-support.email-friendly`))))))),
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, justify: "space-evenly", alignItems: "center" },
                        React.createElement(Grid, { item: true, className: theme.iconContainer },
                            React.createElement(PhoneIcon, null)),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { className: theme.contactInfo },
                                React.createElement("a", { href: 'tel:' + t(`by-country.${countryId}.footer.tech-support.phone-value`), className: theme.techLink }, parse(t(`by-country.${countryId}.footer.tech-support.phone-friendly`)))))))))));
}
