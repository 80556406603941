import React from 'react';
import { Grid, Typography, List, ListItem, ListItemText, Button, makeStyles, createStyles, Link } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => createStyles({
    link: {
        color: 'inherit',
        textDecoration: 'inherit',
    },
}));
export default function PersonalInformation(props) {
    const { user, handleSignOutClick } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Grid, { item: true, xs: 12, sm: 6 },
        React.createElement(Typography, { variant: "h4", component: "h4" },
            React.createElement(Trans, { i18nKey: "account-ui.personal-info" }, "Personal Information")),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.name'), secondary: `${user.givenName} ${user.familyName}` })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.phone-number'), secondary: user.phone })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.email-address'), secondary: user.emailAddress })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: "/update-details", className: classes.link },
                        React.createElement(Button, { variant: "contained", color: "primary" },
                            React.createElement(Trans, { i18nKey: "account-ui.buttons.update-details" }, "Update My Details and Consent"))) })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: "/reset-password", className: classes.link },
                        React.createElement(Button, { variant: "outlined", color: "secondary" },
                            React.createElement(Trans, { i18nKey: "account-ui.buttons.reset-password" }, "Reset Password"))) })),
            React.createElement(ListItem, null,
                React.createElement(Link, { component: "button", variant: "body2", onClick: handleSignOutClick },
                    React.createElement(Trans, { i18nKey: "account-ui.buttons.sign-out" }, "Sign out"))))));
}
