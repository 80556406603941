import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';
const initialState = {
    initializing: false,
    initialized: false,
    state: AuthenticationState.Unauthenticated
};
export const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthenticationActions.Initializing:
            return Object.assign(Object.assign({}, state), { initializing: true, initialized: false });
        case AuthenticationActions.Initialized:
            return Object.assign(Object.assign({}, state), { initializing: false, initialized: true });
        case AuthenticationActions.AcquiredIdTokenSuccess:
            return Object.assign(Object.assign({}, state), { idToken: action.payload });
        case AuthenticationActions.AcquiredAccessTokenSuccess:
            return Object.assign(Object.assign({}, state), { accessToken: action.payload });
        case AuthenticationActions.AcquiredAccessTokenError:
            return Object.assign(Object.assign({}, state), { accessToken: undefined });
        case AuthenticationActions.LoginSuccess:
            return Object.assign(Object.assign({}, state), { account: action.payload });
        case AuthenticationActions.LoginError:
        case AuthenticationActions.AcquiredIdTokenError:
        case AuthenticationActions.LogoutSuccess:
            return Object.assign(Object.assign({}, state), { idToken: undefined, accessToken: undefined, account: undefined });
        case AuthenticationActions.AuthenticatedStateChanged:
            return Object.assign(Object.assign({}, state), { state: action.payload });
        default:
            return state;
    }
};
