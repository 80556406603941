import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Box, DialogTitle, IconButton, createStyles, makeStyles, Typography, Grid, useMediaQuery, FormControl, TextField, CircularProgress, Checkbox, FormControlLabel, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Trans } from 'react-i18next';
import { getLinks } from '../../common/links';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    fullWidthButton: {
        width: '100%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    formControl: {
        width: '100%',
    },
    buttonWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.info.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    checkboxLabel: {
        marginLeft: 0,
        marginTop: theme.spacing(3),
        alignItems: 'start',
    },
    checkbox: {
        paddingTop: 0,
    },
}));
export default function EmailDialog(props) {
    const { open, handleClose, handleSend, sending } = props;
    const classes = useStyles();
    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const [guestEmail, setGuestEmail] = React.useState('');
    const [sendingState, setSendingState] = React.useState(false);
    const [gdprConsentGiven, setGdprConsentChecked] = React.useState(false);
    const links = getLinks();
    const directMarketingConsentLink = links.marketingConsent;
    const privacyPolicyLink = links.privacyPolicy;
    const onGuestEmailChange = (event) => {
        setGuestEmail(event.target.value);
    };
    const handleGdprConsentChange = (event) => {
        setGdprConsentChecked(event.target.checked);
    };
    React.useEffect(() => {
        setSendingState(sending);
    }, [sending]);
    return (React.createElement(Dialog, { open: open, keepMounted: true, onClose: () => handleClose(), fullWidth: true, maxWidth: "sm" },
        React.createElement(DialogTitle, { disableTypography: true },
            React.createElement(Box, { marginRight: 4 },
                React.createElement(Typography, { variant: "h4" },
                    React.createElement(Trans, { i18nKey: "email-dialog.guest-title" }, "email-dialog.guest-title"))),
            React.createElement(IconButton, { "aria-label": "close", className: classes.closeButton, onClick: () => handleClose() },
                React.createElement(CloseIcon, null))),
        React.createElement(DialogContent, null,
            React.createElement(Box, null,
                React.createElement(Trans, { i18nKey: "email-dialog.guest-subtitle" }, "email-dialog.guest-subtitle"),
                React.createElement(FormControlLabel, { className: classes.checkboxLabel, value: "start", control: React.createElement(Checkbox, { checked: gdprConsentGiven, onChange: handleGdprConsentChange, color: "primary", className: classes.checkbox }), label: React.createElement(Trans, { i18nKey: "account-ui.gdpr-consent-question", defaults: "I would like to hear about Kingspan promotions, products, services, event and other activities that may be of interest. For full details on how we use your personal data, please view our <anchorlinkMarketing>Direct Marketing Consent Notice</anchorlinkMarketing>.<br/><br/>We will use your personal data for marketing and retargeting purposes and you have the right to withdraw this consent at any time as explained in our <anchorlinkPrivacy>Privacy Policy.</anchorlinkPrivacy>", values: { privacyPolicyLink: privacyPolicyLink, directMarketingConsentLink: directMarketingConsentLink }, components: {
                            anchorlinkMarketing: (React.createElement("a", { href: directMarketingConsentLink, rel: "noopener noreferrer", target: "_blank" }, "Direct Marketing Consent Notice")),
                            anchorlinkPrivacy: (React.createElement("a", { href: privacyPolicyLink, rel: "noopener noreferrer", target: "_blank" }, "Privacy Policy")),
                        } }), labelPlacement: "end" })),
            React.createElement(Box, { mx: 2, my: 2 },
                React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                    React.createElement(TextField, { id: "project-name", label: React.createElement(Trans, { i18nKey: "email-dialog.email-address" }), variant: "outlined", className: classes.formControl, value: guestEmail, onChange: onGuestEmailChange })))),
        React.createElement(DialogActions, null,
            React.createElement(Grid, { container: true, justify: "flex-end" },
                React.createElement(Grid, { item: true, xs: 12, sm: 'auto', className: classes.buttonWrapper },
                    React.createElement(Button, { variant: "contained", color: "primary", disabled: sendingState, className: isMobileView ? classes.fullWidthButton : '', onClick: () => {
                            handleSend(guestEmail, gdprConsentGiven);
                        } },
                        React.createElement(Trans, { i18nKey: "email-dialog.send-email" }, "email-dialog.send-email")),
                    sendingState && React.createElement(CircularProgress, { size: 24, className: `${classes.buttonProgress}` })),
                React.createElement(Grid, { item: true, xs: 12, sm: 'auto' },
                    React.createElement(Button, { onClick: () => handleClose(), className: isMobileView ? classes.fullWidthButton : '', color: "primary", "data-qa-id": "cancel-btn" },
                        React.createElement(Trans, { i18nKey: "email-dialog.cancel" }, "email-dialog.cancel")))))));
}
