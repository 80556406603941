import React, { useState } from 'react';
import { Box, IconButton, Drawer, makeStyles, createStyles, List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Grid, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { deepPurple } from '@material-ui/core/colors';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationItemId } from '../../../types/domain/navigation-item.types';
import CultureSelect from '../CultureSelect/CultureSelect';
const useStyles = makeStyles((theme) => (createStyles({
    signedInAvatar: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    drawerList: {
        minWidth: 240,
    },
    link: {
        color: 'inherit',
        textDecoration: 'inherit'
    },
})));
export default function NavigationTablet(props) {
    const { isAuthenticated, user, navigationItems, handleSignInClick, handleSignOutClick } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    return (React.createElement(Box, null,
        React.createElement(Grid, { container: true, justify: "space-between", alignItems: "center", spacing: 3 },
            navigationItems.map(navigationItem => {
                // We want the My Account navigation item to go under the burger menu
                if (navigationItem.show && navigationItem.id !== NavigationItemId.MY_ACCOUNT) {
                    return (React.createElement(Grid, { item: true, key: navigationItem.id },
                        React.createElement(Link, { component: "button", variant: "body2" },
                            React.createElement(RouterLink, { to: navigationItem.route, className: classes.link }, t(navigationItem.translationKey)))));
                }
                return null;
            }),
            React.createElement(Grid, { item: true },
                React.createElement(IconButton, { onClick: () => setDrawerOpen(true) },
                    React.createElement(MenuIcon, null)),
                React.createElement(Drawer, { anchor: "right", open: drawerOpen, onClose: () => setDrawerOpen(false) },
                    React.createElement(Box, { className: classes.drawerList },
                        React.createElement(List, { dense: false },
                            React.createElement(ListItem, { button: true, onClick: () => setDrawerOpen(false) },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(CloseIcon, null)),
                                React.createElement(ListItemText, { primary: t('navigation-ui.close') })),
                            navigationItems.map(navigationItem => {
                                // We want the My Account navigation item to go under the burger menu
                                if (navigationItem.show && navigationItem.id === NavigationItemId.MY_ACCOUNT) {
                                    return (React.createElement(RouterLink, { key: navigationItem.id, to: navigationItem.route, className: classes.link, onClick: () => setDrawerOpen(false) },
                                        React.createElement(ListItem, { button: true },
                                            React.createElement(ListItemIcon, null,
                                                React.createElement(Avatar, null, navigationItem.icon())),
                                            React.createElement(ListItemText, { primary: t(navigationItem.translationKey) }))));
                                }
                                return null;
                            }),
                            React.createElement(CultureSelect, null),
                            isAuthenticated
                                ?
                                    React.createElement(ListItem, { button: true, onClick: handleSignOutClick },
                                        React.createElement(ListItemAvatar, null,
                                            React.createElement(Avatar, { src: "not-valid.jpg", className: classes.signedInAvatar }, getInitials(user))),
                                        React.createElement(ListItemText, { primary: t('navigation-ui.sign-out'), secondary: getUserName(user) }))
                                :
                                    React.createElement(ListItem, { button: true, onClick: handleSignInClick },
                                        React.createElement(ListItemAvatar, null,
                                            React.createElement(Avatar, { src: "not-valid.jpg" })),
                                        React.createElement(ListItemText, { primary: t('navigation-ui.sign-in') })))))))));
}
const getInitials = (user) => {
    const name = getUserName(user);
    return name === '' ? name : name[0];
};
const getUserName = (user) => {
    if (user === undefined) {
        return '';
    }
    return `${user.givenName} ${user.familyName}`;
};
