import React from 'react';
import { Grid, makeStyles, createStyles, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { currentCalculationResultSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { Trans } from 'react-i18next';
import { calculationResultLoadingStateSelector } from '../../store/component-state/component-state.selectors';
import FormatNumber from '../FormatNumber/FormatNumber';
const useStyles = makeStyles((theme) => createStyles({
    wrapper: {
        position: 'relative',
    },
    resultProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -34,
        marginLeft: -34,
    },
    loadingClass: {
        opacity: 0.4,
    },
}));
export default function RcValue() {
    const classes = useStyles();
    const result = useSelector(currentCalculationResultSelector);
    const isCalculationResultLoading = useSelector(calculationResultLoadingStateSelector);
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement(Grid, { container: true, justify: "center", alignItems: "center", direction: "column", className: isCalculationResultLoading ? classes.loadingClass : '' },
            React.createElement(Grid, { item: true },
                React.createElement("div", { className: 'rcValueLabel', "data-qa-id": "rcValueTitle" }, result ?
                    React.createElement(Trans, { i18nKey: `calculation-ui.${result.resultType.toLowerCase()}` }, result.resultType) :
                    '--')),
            React.createElement(Grid, { item: true },
                React.createElement("div", { className: 'rcValueNumber', "data-qa-id": "rcValue" }, result && result.resultValue ?
                    React.createElement(FormatNumber, { toFormat: result.resultValue }) :
                    '-,--')),
            React.createElement(Grid, { item: true },
                React.createElement("div", { className: '', "data-qa-id": "rcValueUnit" }, result ?
                    result.resultUnits :
                    '--')),
            React.createElement(Grid, { item: true },
                React.createElement("div", { className: 'rcTotalLabel marginTop40', "data-qa-id": "totalThicknessLabel" },
                    React.createElement(Trans, { i18nKey: 'calculation-ui.construction-thickness-label' }, "Total Construction Thickness"))),
            React.createElement(Grid, { item: true },
                React.createElement("div", { className: 'rcTotalNumber', "data-qa-id": "totalThicknessValue" },
                    result ?
                        result.totalThicknessMillimetres :
                        '--',
                    " mm"))),
        isCalculationResultLoading && React.createElement(CircularProgress, { size: 68, className: classes.resultProgress })));
}
