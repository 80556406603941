import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsItem } from '../../store/news-item/news-item.action-creators';
import { newsItemContentSelector, isLoadingSelector } from '../../store/news-item/news-item.selectors';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selectors';
import { clearNewsItem } from '../../store/news-item/news-item.actions';
import { Alert, AlertTitle } from '@material-ui/lab';
import styles from './NewsItemBanner.module.scss';
export default function NewsItemBanner(props) {
    const dispatch = useDispatch();
    const { countryId, constructionType, language } = props;
    useEffect(() => {
        if (constructionType && countryId && language) {
            dispatch(fetchNewsItem(constructionType, countryId, language));
        }
        return function cleanup() {
            dispatch(clearNewsItem());
        };
    }, [dispatch, constructionType, countryId, language]);
    const isLoading = useSelector(isLoadingSelector);
    const newsItem = useSelector(newsItemContentSelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const handleDismiss = (event) => {
        event.stopPropagation();
        dispatch(clearNewsItem());
    };
    return !isLoading && newsItem ? (React.createElement(Alert, { severity: "info", onClose: isAuthenticated ? handleDismiss : undefined, onClick: () => window.open(newsItem.url), className: styles.newsItemBanner },
        React.createElement(AlertTitle, { "data-qa-id": "newsItemUrl", className: styles.newsItemBannerText }, newsItem.subject))) : null;
}
