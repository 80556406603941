import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import useSupportedCultures from './useSupportedCultures';
import CultureSelectMobile from './CultureSelect.mobile';
import CultureSelectDesktop from './CultureSelect.desktop';
export default function CultureSelect() {
    const { supportedCultures, culture, setCulture } = useSupportedCultures();
    const isTablet = useMediaQuery((theme) => theme.breakpoints.between('xs', 'sm'));
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const [anchorElement, setAnchorElement] = useState(null);
    const handleMenuButtonClick = (event) => {
        setAnchorElement(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorElement(null);
    };
    const handleCultureSelection = (culture) => {
        handleClose();
        setCulture(culture);
    };
    if (isMobile || isTablet) {
        return React.createElement(CultureSelectMobile, { culture: culture, supportedCultures: supportedCultures, handleCultureSelection: handleCultureSelection, anchorElement: anchorElement, handleMenuButtonClick: handleMenuButtonClick, handleClose: handleClose });
    }
    return React.createElement(CultureSelectDesktop, { culture: culture, supportedCultures: supportedCultures, handleCultureSelection: handleCultureSelection, anchorElement: anchorElement, handleMenuButtonClick: handleMenuButtonClick, handleClose: handleClose });
}
;
