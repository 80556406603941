import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
import { getAppConfig } from '../../config/config';
import ThicknessComparison from './ThicknessComparison';
import RcValue from '../RcValue/RcValue';
import { countryIdSelector } from '../../store/component-state/component-state.selectors';
import { constructionTypeNameSelector } from '../../store/construction-data/construction-data.selectors';
import { currentCalculationDataSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { LayerType } from '../../types/domain/construction-data.types';
import LayerName from '../Calculation/LayerName';
import styles from './CalculationSummary.module.scss';
import { getCalculationLayerKey } from '../../store/current-calculation-data/current-calculation-data.reducer';
import MaterialParametersAux from '../CalculationLayer/MaterialParametersAux';
const useStyle = makeStyles((theme) => (createStyles({
    media: {
        height: 270,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    summaryContainer: {
        padding: theme.spacing(2),
    },
    summary: {
        marginBottom: 24,
    },
    layer: {
        marginBottom: 12,
    },
    link: {
        color: 'inherit',
    },
    panelsContainer: {
        marginTop: '0.5em',
        marginBottom: '1em',
    },
    panel: {
        padding: theme.spacing(2),
        backgroundColor: '#E0E0E0',
        borderRadius: '0.75em',
    },
    icon: {
        verticalAlign: 'bottom'
    },
    compliant: {
        color: 'green'
    },
    nonCompliant: {
        color: 'red'
    }
})));
function getAvailableMaterials(constructionLayer) {
    var _a;
    let materials = [...(_a = constructionLayer === null || constructionLayer === void 0 ? void 0 : constructionLayer.materials) !== null && _a !== void 0 ? _a : []];
    if (constructionLayer === null || constructionLayer === void 0 ? void 0 : constructionLayer.materials) {
        constructionLayer === null || constructionLayer === void 0 ? void 0 : constructionLayer.materials.forEach(material => {
            var _a;
            materials = [...materials, ...(_a = material.linkedMaterials) !== null && _a !== void 0 ? _a : []];
        });
    }
    return materials;
}
export const hasThicknessOptions = (material) => (material !== null && material !== undefined &&
    !isEmpty(material.optionsForThicknessMillimetres) &&
    material.optionsForThicknessMillimetres.some(x => x > 0));
export default function CalculationSummary(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const classes = useStyle();
    const apiUrl = (_a = getAppConfig()) === null || _a === void 0 ? void 0 : _a.apiUrl;
    const countryId = useSelector(countryIdSelector);
    const constructionTypeId = isEmpty(props.constructionTypeId) ? 'cavity-wall' : props.constructionTypeId;
    const constructionTypeName = useSelector(constructionTypeNameSelector(constructionTypeId));
    const currentCalculationData = useSelector(currentCalculationDataSelector);
    const thicknessComparison = (_b = props.results) === null || _b === void 0 ? void 0 : _b.thicknessComparison;
    return (React.createElement(Grid, { container: true, direction: "row" },
        React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 4 },
            React.createElement(Box, { marginLeft: 2, marginTop: 2, className: "calculationTitle" }, constructionTypeName),
            React.createElement(Box, { marginLeft: 2, marginBottom: 2, className: "calculationSubTitle" }, (_c = props.projectDetails) === null || _c === void 0 ? void 0 : _c.name),
            React.createElement(Box, { marginBottom: 3 },
                React.createElement("img", { "data-qa-id": "construction-type-image", style: { maxWidth: '100%' }, src: `${apiUrl}/content/build-up-images/${countryId.toUpperCase()}/${countryId.toUpperCase()}-${constructionTypeId}.jpg`, alt: constructionTypeName }))),
        React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 4, className: classes.summaryContainer },
            React.createElement("div", { className: classes.summary, "data-qa-id": "ConstructionSummary" },
                React.createElement("strong", null,
                    React.createElement(Trans, { i18nKey: "calculation-summary-ui.construction-summary-text" }, "Construction Summary"))),
            React.createElement("ul", { className: styles.layerList }, (_e = (_d = props.results) === null || _d === void 0 ? void 0 : _d.calculationLayers) === null || _e === void 0 ? void 0 : _e.map(resultLayer => {
                var _a;
                const constructionLayer = props.layers.find(layer => layer.name === resultLayer.layerName);
                const selectedMaterial = getAvailableMaterials(constructionLayer).find(material => material.materialId === resultLayer.materialId);
                const calculationLayerKey = getCalculationLayerKey((_a = constructionLayer === null || constructionLayer === void 0 ? void 0 : constructionLayer.constructionLayerId) !== null && _a !== void 0 ? _a : -1, selectedMaterial === null || selectedMaterial === void 0 ? void 0 : selectedMaterial.isLinkedMaterial);
                return Object.assign(Object.assign({}, resultLayer), { constructionLayer,
                    selectedMaterial, calculationLayer: currentCalculationData.calculationLayers[calculationLayerKey] });
            }).map(layer => {
                var _a, _b, _c, _d;
                return (React.createElement("li", { key: `${layer.layerName}-${(_a = layer.materialId) !== null && _a !== void 0 ? _a : layer.materialName}` },
                    React.createElement(LayerName, { layer: layer.constructionLayer }),
                    React.createElement("div", { className: styles.layerProperties },
                        React.createElement(Trans, { i18nKey: `results-layer.${layer.materialName}` }, layer.materialName),
                        ((_b = layer.constructionLayer) === null || _b === void 0 ? void 0 : _b.layerType) === LayerType.Insulation &&
                            hasThicknessOptions(layer.selectedMaterial) &&
                            React.createElement("div", { className: styles.layerPropertiesChevron },
                                React.createElement(Box, { marginTop: -4, marginLeft: 2, marginRight: 1 },
                                    React.createElement(MaterialParametersAux, { layer: layer.constructionLayer, currentSelectedThickness: layer.calculationLayer.thicknessMillimetres, currentSelectedMaterial: layer.selectedMaterial, isLinkedMaterial: (_d = (_c = layer.selectedMaterial) === null || _c === void 0 ? void 0 : _c.isLinkedMaterial) !== null && _d !== void 0 ? _d : false }))))));
            })),
            React.createElement("div", { "data-qa-id": "total-thickness" },
                React.createElement("strong", null,
                    React.createElement(Trans, { i18nKey: "calculation-summary-ui.total-thickness-text" }, "Total thickness")),
                "\u00A0", (_g = (_f = props.results) === null || _f === void 0 ? void 0 : _f.totalThicknessMillimetres) !== null && _g !== void 0 ? _g : '--',
                " mm")),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 4 },
            React.createElement(Grid, { className: classes.panelsContainer, container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 12 },
                    React.createElement(RcValue, null)),
                ((_h = props.results) === null || _h === void 0 ? void 0 : _h.isBengCompliant) != null &&
                    React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 12, "data-qa-id": "beng-indicator" },
                        React.createElement(Box, { className: classes.panel },
                            React.createElement(Typography, { align: "center", variant: "h6", component: "h2" },
                                "BENG ",
                                props.results.isBengCompliant ?
                                    React.createElement(CheckCircleIcon, { color: "primary", className: `${classes.icon} ${classes.compliant}`, "data-qa-id": "beng-indicator-green" }) :
                                    React.createElement(CancelIcon, { color: "primary", className: `${classes.icon} ${classes.nonCompliant}`, "data-qa-id": "beng-indicator-red" })))),
                thicknessComparison &&
                    React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 12 },
                        React.createElement(Box, { className: classes.panel },
                            React.createElement(ThicknessComparison, { thicknessComparison: thicknessComparison })))))));
}
