import React, { useState } from 'react';
import { Grid, Typography, Slider, Select, MenuItem, makeStyles, createStyles, Box } from '@material-ui/core';
import { Trans } from 'react-i18next';
const getMarks = (thicknesses) => {
    return thicknesses.map(t => { return { value: t }; });
};
const useStyle = makeStyles((theme) => (createStyles({
    depthDropdownContainer: {
        marginTop: 19,
    },
})));
export default function ThicknessSlider(props) {
    const { labelKey, handleChange, selectedThickness, disabled, min, max, marks, defaultValue, units, isValueFixed } = props;
    const [value, setValue] = useState(selectedThickness);
    const handleChangeInternal = (event, newValue) => {
        setValue(newValue);
    };
    const handleDropdownChange = (event) => {
        setValue(event.target.value);
        handleChange(event.target.value);
    };
    const classes = useStyle();
    React.useEffect(() => {
        setValue(selectedThickness);
    }, [selectedThickness]);
    return (React.createElement(Box, { marginTop: 4 },
        React.createElement(Grid, { container: true, spacing: 4 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { id: "discrete-slider", gutterBottom: true, "data-qa-id": "depthSliderLabel" },
                    React.createElement(Trans, { i18nKey: `calculation-ui.${labelKey}` }, "Thickness")),
                React.createElement(Slider, { onChange: handleChangeInternal, onChangeCommitted: () => handleChange(value), "aria-labelledby": "discrete-slider", valueLabelDisplay: "auto", step: null, marks: getMarks(marks), value: value !== null && value !== void 0 ? value : defaultValue, "data-qa-id": "depthSlider", min: min, max: max, disabled: disabled })),
            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "inputContainer", className: classes.depthDropdownContainer }, isValueFixed ?
                React.createElement(Typography, { variant: "h3" }, value !== null && value !== void 0 ? value : defaultValue)
                :
                    React.createElement(Select, { value: value !== null && value !== void 0 ? value : defaultValue, onChange: handleDropdownChange, displayEmpty: true, inputProps: { 'aria-label': 'Without label' }, disabled: disabled, "data-qa-id": `${labelKey}-depthDropdown` }, marks.map((mark, index) => (React.createElement(MenuItem, { value: mark, key: index, "data-qa-id": `${mark}-dropdownvalue` }, `${mark} ${units}`))))))));
}
