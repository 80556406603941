import { cloneDeep } from 'lodash';
import * as he from 'he';
import * as actionTypes from './construction-data.action-types';
export const initialState = {
    types: [],
    layers: {}
};
export const constructionDataReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case actionTypes.FETCH_CONSTRUCTION_TYPES_SUCCESS:
            return Object.assign(Object.assign({}, state), { types: action.payload });
        case actionTypes.FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS:
            {
                const newState = Object.assign(Object.assign({}, state), { types: cloneDeep((_a = state.types) !== null && _a !== void 0 ? _a : []) });
                // Build dictionary of the latest names where the key is the Construction Type ID
                const latestNames = {};
                action.payload.forEach(ct => latestNames[ct.id] = ct.nameLocalized);
                // Update the name of each Construction Type in our state, from the latest known names
                newState.types.forEach(ct => { var _a; return ct.nameLocalized = (_a = latestNames[ct.id]) !== null && _a !== void 0 ? _a : ct.nameLocalized; });
                return newState;
            }
        case actionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS:
            {
                const newState = Object.assign(Object.assign({}, state), { types: cloneDeep((_b = state.types) !== null && _b !== void 0 ? _b : []), layers: Object.assign(Object.assign({}, state.layers), { [action.payload.constructionType]: action.payload.layers }) });
                const constructionType = newState.types.find(ct => ct.id === action.payload.constructionType);
                if (constructionType) {
                    constructionType.nameLocalized = he.decode(action.payload.constructionTypeNameLocalized);
                }
                return newState;
            }
        default:
            return state;
    }
};
