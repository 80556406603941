import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ThicknessBuildUp from './ThicknessBuildUp';
const useStyle = makeStyles((theme) => (createStyles({
    tagLine: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '1em',
    },
})));
export default function ThicknessComparison(props) {
    const classes = useStyle();
    const { t } = useTranslation();
    const thicknessComparison = props.thicknessComparison;
    return thicknessComparison
        ? React.createElement("div", null,
            React.createElement(Grid, { container: true, direction: "row", spacing: 1, alignItems: "flex-end" },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(ThicknessBuildUp, { materialName: thicknessComparison.sourceMaterialName, totalConstructionThicknessMillimetres: thicknessComparison.sourceTotalConstructionThicknessMillimetres, layerThicknessPercentageNormalised: 1.0, isKingspanProduct: false })),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(ThicknessBuildUp, { materialName: thicknessComparison.comparisonMaterialName, totalConstructionThicknessMillimetres: thicknessComparison.comparisonTotalConstructionThicknessMillimetres, layerThicknessPercentageNormalised: 1 - thicknessComparison.layerThicknessSavingPercentage / 100, isKingspanProduct: true }))),
            React.createElement("div", { className: classes.tagLine },
                React.createElement("div", { "data-qa-id": "thickness-comparison-text" }, t('thickness-comparison-ui.you-will-save', Object.assign({}, thicknessComparison))),
                React.createElement("div", { "data-qa-id": "thickness-comparison-product" }, thicknessComparison.comparisonMaterialName)))
        : React.createElement("div", null);
}
