import { FETCH_NEWS_ITEM_SUCCESS, FETCH_NEWS_ITEM_FAILURE, FETCH_NEWS_ITEM_ATTEMPT, CLEAR_NEWS_ITEM, } from './news-item.action-types';
export const initialState = {
    isLoading: false,
    error: null,
};
export function newsItemReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_NEWS_ITEM_ATTEMPT:
            return Object.assign(Object.assign({}, initialState), { isLoading: true });
        case FETCH_NEWS_ITEM_SUCCESS:
            return Object.assign(Object.assign({}, initialState), { isLoading: false, content: action.payload });
        case FETCH_NEWS_ITEM_FAILURE:
            return Object.assign(Object.assign({}, initialState), { isLoading: false, error: action.error });
        case CLEAR_NEWS_ITEM:
            return Object.assign({}, initialState);
        default:
            return state;
    }
}
