import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, List, ListItemText, ListItem, Box, DialogTitle, IconButton, createStyles, makeStyles, Typography, Grid, ListItemIcon, useMediaQuery, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Trans, useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AzureAD from 'react-aad-msal';
import authProviderFactory from '../../authentication/authProvider';
import { useDispatch, useStore } from 'react-redux';
import { setIsConfirmedGuest } from '../../store/account/account.actions';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    fullWidthButton: {
        width: '100%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));
export default function SignInDialog(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const store = useStore();
    const { t } = useTranslation();
    const reasons = [
        t('sign-in-dialog.reason-1'),
        t('sign-in-dialog.reason-2'),
        t('sign-in-dialog.reason-3'),
    ];
    const closeWindow = (cont, isConfirmedGuest) => {
        dispatch(setIsConfirmedGuest(isConfirmedGuest));
        handleClose(cont);
    };
    return (React.createElement(Dialog, { open: open, keepMounted: true, onClose: () => closeWindow(false, false), fullWidth: true, maxWidth: 'sm' },
        React.createElement(DialogTitle, { disableTypography: true },
            React.createElement(Box, { marginRight: 4 },
                React.createElement(Typography, { variant: "h4" },
                    React.createElement(Trans, { i18nKey: "sign-in-dialog.title" }, "Make your experience even better"))),
            React.createElement(IconButton, { "aria-label": "close", className: classes.closeButton, onClick: () => closeWindow(false, false) },
                React.createElement(CloseIcon, null))),
        React.createElement(DialogContent, null,
            React.createElement(Box, null,
                React.createElement(Trans, { i18nKey: "sign-in-dialog.sub-title" }, "Creating an account is easy and free.")),
            React.createElement(List, null, reasons.map((reason, index) => (React.createElement(ListItem, { key: index },
                React.createElement(ListItemIcon, null,
                    React.createElement(ArrowRightIcon, null)),
                React.createElement(ListItemText, { primary: reason })))))),
        React.createElement(DialogActions, null,
            React.createElement(Grid, { container: true, justify: "flex-end" },
                React.createElement(Grid, { item: true, xs: 12, sm: 'auto' },
                    React.createElement(AzureAD, { provider: authProviderFactory(), reduxStore: store }, ({ login }) => {
                        return (React.createElement(Button, { variant: "contained", color: "primary", className: isMobileView ? classes.fullWidthButton : '', onClick: () => { closeWindow(true, false); login(); } },
                            React.createElement(Trans, { i18nKey: "sign-in-dialog.signup-signin" }, "Sign In")));
                    })),
                React.createElement(Grid, { item: true, xs: 12, sm: 'auto' },
                    React.createElement(Button, { onClick: () => closeWindow(true, true), className: isMobileView ? classes.fullWidthButton : '', color: "primary", "data-qa-id": "guest-btn" },
                        React.createElement(Trans, { i18nKey: "sign-in-dialog.guest-button" }, "Continue as guest")))))));
}
