import { Box, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { gt, gte } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PerimeterAndArea.module.scss';
export default function PerimeterAndArea(props) {
    const { t } = useTranslation();
    const maxPerimeter = 100000000;
    const maxArea = 100000000;
    const defaultMinimum = 1.00;
    function updatePerimeter(value) {
        let perimeterValue = Number(value);
        if (Number.isNaN(perimeterValue) || perimeterValue <= 0) {
            perimeterValue = defaultMinimum;
        }
        if (gt(perimeterValue, maxPerimeter)) {
            perimeterValue = maxPerimeter;
        }
        props.setPerimeter(perimeterValue);
    }
    function updateArea(value) {
        let areaValue = Number(value);
        if (Number.isNaN(areaValue) || areaValue <= 0) {
            areaValue = defaultMinimum;
        }
        if (gt(areaValue, maxArea)) {
            areaValue = maxArea;
        }
        props.setArea(areaValue);
    }
    function calcRatio() {
        if (props.area && props.perimeter) {
            return props.perimeter / props.area;
        }
        return 0;
    }
    function getHelperText(key, value, maxValue) {
        let helperText = gte(value, maxValue)
            ? t(`additional-parameters.perimeter-and-area.${key}-exceeds-max`, { maxPerimeter: maxPerimeter })
            : '';
        return helperText;
    }
    ;
    return (React.createElement(Grid, { container: true, alignItems: "flex-start" },
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(InputLabel, { htmlFor: "pa-perimeter", className: styles.paLabel }, t('additional-parameters.perimeter-and-area.perimeter')),
            React.createElement(TextField, { id: "pa-perimeter", className: styles.paTextFieldShort, type: "number", inputProps: { inputMode: 'numeric', min: { defaultMinimum }, max: { maxPerimeter } }, value: props.perimeter, "data-qa-id": "qa-pa-perimeter", variant: "outlined", error: gt(props.perimeter, maxPerimeter), helperText: getHelperText('perimeter', props.perimeter, maxPerimeter), onChange: (event) => updatePerimeter(event.target.value) })),
        React.createElement(Grid, { item: true, xs: 1, className: styles.paUnits },
            React.createElement(Typography, null, t('additional-parameters.perimeter-and-area.perimeter-units'))),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(InputLabel, { htmlFor: "pa-area", className: `${styles.paLabel} ${styles.paLabelShort}` }, t('additional-parameters.perimeter-and-area.area')),
            React.createElement(TextField, { id: "pa-area", className: styles.paTextFieldShort, type: "number", inputProps: { inputMode: 'numeric', min: { defaultMinimum }, max: { maxArea } }, value: props.area, "data-qa-id": "qa-pa-area", variant: "outlined", error: gt(props.area, maxArea), helperText: getHelperText('area', props.area, maxArea), onChange: (event) => updateArea(event.target.value) })),
        React.createElement(Grid, { item: true, xs: 1, className: styles.paUnits },
            React.createElement(Typography, null, t('additional-parameters.perimeter-and-area.area-units'))),
        React.createElement(Grid, { item: true, xs: 4 },
            React.createElement(InputLabel, { htmlFor: "pa-ratio", className: `${styles.paLabel} ${styles.paLabelShort}` }, t('additional-parameters.perimeter-and-area.pa-ratio')),
            React.createElement(Box, { className: styles.paRatio },
                React.createElement(Typography, { id: "pa-ratio", "data-qa-id": "qa-pa-ratio" },
                    "= ",
                    calcRatio().toFixed(3))))));
}
