import { Box, createStyles, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { countryIdSelector } from '../../store/component-state/component-state.selectors';
import { Address } from './Address/Address';
import { Contact } from './Contact/Contact';
import { TechSupportBar } from './TechSupportBar/TechSupportBar';
import FooterLinks from './FooterLinks';
const useStyles = makeStyles((theme) => createStyles({
    container: {
        padding: theme.spacing(1),
    },
    title: {
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.h1.fontSize,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.h2.fontSize,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.h3.fontSize,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h6.fontSize,
        },
    },
    paddedGrid: {
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
        },
    },
}));
export function Footer() {
    const { t } = useTranslation();
    const theme = useStyles();
    const countryId = useSelector(countryIdSelector);
    return (React.createElement(React.Fragment, null,
        React.createElement(TechSupportBar, null),
        React.createElement(Divider, null),
        countryId && (React.createElement(Grid, { className: theme.container, container: true, justify: "space-evenly", alignItems: "center" },
            React.createElement(Grid, { item: true, xs: 12, md: 6, className: theme.paddedGrid },
                React.createElement(Typography, { variant: "h1", className: theme.title }, parse(t(`by-country.${countryId}.footer.company`)))),
            React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 3, className: theme.paddedGrid },
                React.createElement(Address, null)),
            React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 3, className: theme.paddedGrid },
                React.createElement(Contact, null)))),
        React.createElement(Divider, null),
        React.createElement(Box, { p: 3, paddingTop: 1 },
            React.createElement(Typography, { variant: "caption" }, parse(t(`by-country.${countryId}.footer.disclaimer`)))),
        React.createElement(Divider, null),
        React.createElement(FooterLinks, null)));
}
