var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getCalculationLayerKey } from '../store/current-calculation-data/current-calculation-data.reducer';
import { parseISO } from 'date-fns';
export const mapApiResponseArrayToCalculcationObjectCollection = (data) => {
    if (data.length) {
        return data
            .map(mapApiResponseToCalculcation)
            .reduce((obj, item) => {
            return Object.assign(Object.assign({}, obj), { [item.calculationId]: item });
        }, {});
    }
    return {};
};
export const mapApiResponseToCalculcation = (calc) => {
    const { calculationLayers, projectDetails } = calc, result = __rest(calc, ["calculationLayers", "projectDetails"]);
    return Object.assign(Object.assign({}, result), { projectDetails: Object.assign(Object.assign({}, projectDetails), { startDate: (projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.startDate) ? parseISO(projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.startDate.toString()) : undefined }), createdOn: parseISO(result.createdOn), updatedOn: parseISO(result.updatedOn), calculationLayers: calculationLayers.reduce((obj, item) => {
            var _a, _b;
            return Object.assign(Object.assign({}, obj), { [getCalculationLayerKey(item.constructionLayerId, (_b = (_a = item.material) === null || _a === void 0 ? void 0 : _a.isLinkedMaterial) !== null && _b !== void 0 ? _b : false)]: item });
        }, {}) });
};
