import React from 'react';
import { activeConstructionLayerSelector, activeConstructionTypeSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedThicknessSelector, } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector, useDispatch } from 'react-redux';
import ThicknessSlider from './ThicknessSlider';
import { setWoodPercentage } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { countryIdSelector } from '../../store/component-state/component-state.selectors';
export default function WoodPercentage() {
    var _a, _b;
    const dispatch = useDispatch();
    const constructionType = useSelector(activeConstructionTypeSelector);
    const layer = useSelector(activeConstructionLayerSelector);
    const currentWoodPercentage = useSelector(currentSelectedThicknessSelector(false)); //using thickness property as % on pseudo layer
    const countryId = useSelector(countryIdSelector);
    const handleChange = (value) => {
        dispatch(setMaterialParameter(setWoodPercentage, layer, value, false));
    };
    if (countryId === 'gb' || countryId === 'ie') {
        return (React.createElement(ThicknessSlider, { labelKey: 'wood-percentage-label', handleChange: handleChange, selectedThickness: currentWoodPercentage, disabled: true, marks: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], min: 5, max: 25, defaultValue: Math.round(((_a = constructionType === null || constructionType === void 0 ? void 0 : constructionType.defaultWoodPercentage) !== null && _a !== void 0 ? _a : 0.2) * 100), units: '%', isValueFixed: false }));
    }
    else {
        return (React.createElement(ThicknessSlider, { labelKey: 'wood-percentage-label', handleChange: handleChange, selectedThickness: currentWoodPercentage, disabled: false, marks: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], min: 5, max: 25, defaultValue: Math.round(((_b = constructionType === null || constructionType === void 0 ? void 0 : constructionType.defaultWoodPercentage) !== null && _b !== void 0 ? _b : 0.2) * 100), units: '%', isValueFixed: false }));
    }
}
