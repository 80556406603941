import * as actionTypes from './current-calculation-data.action-types';
export const initialState = {
    calculationLayers: {},
};
export const currentCalculationDataReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case actionTypes.SET_PROJECT_DETAILS:
            return Object.assign(Object.assign({}, state), { projectDetails: action.payload });
        case actionTypes.SET_ADDITIONAL_PARAMETERS:
            return Object.assign(Object.assign({}, state), { additionalParameters: action.payload });
        case actionTypes.SET_AIR_CAVITY_GRADING:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, false)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId)]), { airCavityGrading: action.payload.airCavityGrading }) }) });
        case actionTypes.SET_MATERIAL:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId)]), { material: action.payload.material }) }) });
        case actionTypes.SET_THICKNESS:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]), { thicknessMillimetres: action.payload.thicknessMillimetres }) }) });
        case actionTypes.SET_ANCHOR_DIAMETER:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId)]), { diameterOfAnchorsMillimetres: action.payload.diameterOfAnchorsMillimetres }) }) });
        case actionTypes.SET_ANCHOR_PER_SQUARE_METRE:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]), { numberOfAnchorsPerMetreSquare: action.payload.numberOfAnchorsPerMetreSquare }) }) });
        case actionTypes.SET_ANCHOR_PENETRATION:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]), { anchorPenetrationDepthMillimetres: action.payload.anchorPenetrationDepthMillimetres }) }) });
        case actionTypes.SET_NET_AREA_PER_METRE_SQUARE:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]), { netAreaOfAnchorsPerMetreSquareMillimetresSqrd: action.payload.netAreaOfAnchorsPerMetreSquareMillimetresSqrd }) }) });
        case actionTypes.SET_WOOD_PERCENTAGE:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]), { thicknessMillimetres: action.payload.percentage }) }) });
        case actionTypes.SET_CALCULATED_LAYER_VALUE:
            const airLayerThickness = Math.max(0, (state.calculationLayers[getCalculationLayerKey(action.payload.bridgingLayer.constructionLayerId)].thicknessMillimetres)
                - (((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.insulationLayer) === null || _b === void 0 ? void 0 : _b.constructionLayerId) ? state.calculationLayers[getCalculationLayerKey(action.payload.insulationLayer.constructionLayerId)].thicknessMillimetres : 0));
            const noAirLayerGrading = action.payload.airCavityLayer.airCavityGradings.find(a => a.airCavityGradingId === 'no-air-layer');
            const currentGrading = state.calculationLayers[getCalculationLayerKey(action.payload.airCavityLayer.constructionLayerId)].airCavityGrading;
            const airCavityGrading = airLayerThickness <= 0
                ? noAirLayerGrading !== null && noAirLayerGrading !== void 0 ? noAirLayerGrading : currentGrading
                : (currentGrading === null || currentGrading === void 0 ? void 0 : currentGrading.airCavityGradingId) === (noAirLayerGrading === null || noAirLayerGrading === void 0 ? void 0 : noAirLayerGrading.airCavityGradingId)
                    ? action.payload.airCavityLayer.airCavityGradings.find(a => { var _a; return a.airCavityGradingId === ((_a = state.calculationLayers[getCalculationLayerKey(action.payload.insulationLayer.constructionLayerId)].material) === null || _a === void 0 ? void 0 : _a.defaultAirCavityGradingId); })
                    : currentGrading;
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.airCavityLayer.constructionLayerId)]: Object.assign(Object.assign({}, state.calculationLayers[getCalculationLayerKey(action.payload.airCavityLayer.constructionLayerId)]), { thicknessMillimetres: airLayerThickness, airCavityGrading: airCavityGrading }) }) });
        case actionTypes.CREATE_LINKED_LAYER:
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign(Object.assign({}, state.calculationLayers), { [getCalculationLayerKey(action.payload.calculationLayer.constructionLayerId, true)]: Object.assign({}, action.payload.calculationLayer) }) });
        case actionTypes.REMOVE_LINKED_LAYER:
            if (!state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, true)]) {
                return state;
            }
            const cl = Object.keys(state.calculationLayers)
                .filter(key => key !== getCalculationLayerKey(action.payload.constructionLayerId, true))
                .reduce((result, key) => (Object.assign(Object.assign({}, result), { [key]: state.calculationLayers[key] })), {});
            return Object.assign(Object.assign({}, state), { calculationLayers: Object.assign({}, cl) });
        case actionTypes.FETCH_CALCULATION_RESULTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { calculationResult: action.payload.calculationResult, calculationId: action.payload.calculationId, calculationAccessCode: action.payload.calculationAccessCode });
        case actionTypes.NEW_CALCULATION_STARTED:
            return Object.assign(Object.assign({}, state), { calculationLayers: action.payload.calculationLayers });
        case actionTypes.EDIT_CALCULATION_STARTED:
            return Object.assign(Object.assign({}, state), { calculationLayers: action.payload.calculationLayers, calculationResult: action.payload.calculationResult, projectDetails: action.payload.projectDetails, additionalParameters: action.payload.additionalParameters, calculationAccessCode: action.payload.calculationAccessCode, calculationId: action.payload.calculationId });
        case actionTypes.COPY_CALCULATION_STARTED:
            return Object.assign(Object.assign({}, state), { calculationLayers: action.payload.calculationLayers, projectDetails: action.payload.projectDetails, additionalParameters: action.payload.additionalParameters });
        case actionTypes.CLEAR_CURRENT_CALCULATION:
            return initialState;
        default:
            return state;
    }
};
/* Might be a better place for this function */
export const getCalculationLayerKey = (constructionLayerId, isLinkedLayer = false) => constructionLayerId.toString() + (isLinkedLayer ? '.linked' : '');
