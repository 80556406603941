export const activeConstructionLayerSelector = (store) => { var _a, _b; return (_b = store.constructionData.layers[(_a = store.componentState.activeConstructionTypeId) !== null && _a !== void 0 ? _a : '']) === null || _b === void 0 ? void 0 : _b.filter(l => l.constructionLayerId === store.componentState.activeConstructionLayerId)[0]; };
export const activeConstructionTypeIdSelector = (store) => store.componentState.activeConstructionTypeId;
export const activeConstructionTypeSelector = (store) => store.constructionData.types.find(ct => ct.id === store.componentState.activeConstructionTypeId);
export const countriesSelector = (store) => { var _a; return (_a = store.componentState.countries) !== null && _a !== void 0 ? _a : []; };
export const isLoadingCountriesSelector = (store) => store.componentState.countries === null;
export const countrySelector = (store) => store.componentState.selectedCountry;
export const countryIdSelector = (store) => { var _a, _b; return ((_b = (_a = store.componentState.selectedCountry) === null || _a === void 0 ? void 0 : _a.countryId) !== null && _b !== void 0 ? _b : '').toLowerCase(); };
export const calculationResultLoadingStateSelector = (store) => store.componentState.isCalculationResultLoading;
export const isLoadingSelector = (store) => store.componentState.isLoading;
export const isLoadingConstructionTypesSelector = (store) => store.componentState.isLoadingConstructionTypes;
export const errorSelector = (store) => store.componentState.error;
export const calculationResultErrorSelector = (store) => store.componentState.calculationResultError;
export const selectedCultureSelector = (store) => store.componentState.selectedCulture;
export const downloadingCalculationIdSelector = (store) => store.componentState.downloadingCalculationId;
export const getIsGtmContainerSet = (store) => store.componentState.isGtmContainerSet;
export const projectConfigSelector = (store) => store.componentState.projectConfig;
export const countiesSelector = (store) => { var _a; return (_a = store.componentState.counties) !== null && _a !== void 0 ? _a : []; };
export const fieldDataSelector = (store) => { var _a; return (_a = store.componentState.fieldData) !== null && _a !== void 0 ? _a : []; };
