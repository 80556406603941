import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import { getPreviouslySelectedCountryId } from '../../helpers/memory-helper';
import { authenticationActionCreatorOverrides } from '../../store/authentication/authentication.action-creators';
import { Layout } from '../Layout/Layout';
import { LayoutMinimal } from '../Layout/LayoutMinimal';
import { store } from '../../store/store';
import { theme } from '../../theme/theme';
import ConstructionTypeSelection from '../ConstructionTypeSelection/ConstructionTypeSelection';
import CalculationPage from '../CalculationPage/CalculationPage';
import ChooseCountry from '../ChooseCountry/ChooseCountry';
import CountryRoute from './CountryRoute';
import AccountDetails from '../AccountDetails/AccountDetails';
import PasswordReset from '../PasswordReset/PasswordReset';
import MyCalculations from '../MyCalculations/MyCalculations';
import logo from '../../assets/images/kingspan-logo.svg';
import i18n from '../../i18n';
import { I18nextProvider } from 'react-i18next';
import authProviderFactory, { launchRedirectToPasswordReset, updateDetailsAuthConfig, buildAuthenticationParameters } from '../../authentication/authProvider';
import * as Msal from 'msal';
import interceptors from '../../interceptors/interceptors';
import AzureAD from 'react-aad-msal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import CountriesProvider from './CountriesProvider';
const Loader = () => (React.createElement("div", { className: "App" },
    React.createElement("img", { src: logo, className: "App-logo", alt: "logo" }),
    React.createElement("div", null, "loading...")));
export function App() {
    authenticationActionCreatorOverrides(store);
    authProviderFactory().registerReduxStore(store);
    interceptors.init();
    return (React.createElement(Provider, { store: store },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(I18nextProvider, { i18n: i18n },
                React.createElement(CssBaseline, null),
                React.createElement(Suspense, { fallback: React.createElement(Loader, null) },
                    React.createElement(CountriesProvider, null,
                        React.createElement(BrowserRouter, null,
                            React.createElement(Switch, null,
                                React.createElement(Route, { exact: true, path: "/" },
                                    React.createElement(LayoutMinimal, null,
                                        React.createElement(ChooseCountry, null))),
                                React.createElement(Layout, null,
                                    React.createElement(Switch, null,
                                        React.createElement(CountryRoute, { path: "/:countryId/account" },
                                            React.createElement(AzureAD, { provider: authProviderFactory(), forceLogin: true },
                                                React.createElement(AccountDetails, null))),
                                        React.createElement(Route, { path: "/account-updated" },
                                            React.createElement(Redirect, { to: buildRouteWithCountry(getPreviouslySelectedCountryId(), '/account') })),
                                        React.createElement(Route, { path: "/reset-password", render: () => {
                                                launchRedirectToPasswordReset();
                                                return (React.createElement("span", null, i18n.t('navigation-ui.redirecting-ellipsis')));
                                            } }),
                                        React.createElement(Route, { path: "/update-details", render: () => {
                                                const myMSALObj = new Msal.UserAgentApplication(updateDetailsAuthConfig());
                                                setTimeout(() => myMSALObj.loginRedirect(buildAuthenticationParameters('updateDetails')), 250);
                                                return (React.createElement("span", null, i18n.t('navigation-ui.redirecting-ellipsis')));
                                            } }),
                                        React.createElement(Route, { path: "/password-reset" },
                                            React.createElement(PasswordReset, null)),
                                        React.createElement(CountryRoute, { exact: true, path: "/:countryId" },
                                            React.createElement(ConstructionTypeSelection, null)),
                                        React.createElement(CountryRoute, { path: "/:countryId/calculation/:constructionTypeId/:calculationId?" },
                                            React.createElement(CalculationPage, null)),
                                        React.createElement(CountryRoute, { path: "/:countryId/my-calculations" },
                                            React.createElement(AzureAD, { provider: authProviderFactory(), forceLogin: true },
                                                React.createElement(MyCalculations, null))),
                                        React.createElement(Redirect, { to: "/" })))))),
                    React.createElement(ToastContainer, null))))));
}
