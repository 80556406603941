export const FETCH_USER_ATTEMPT = '[Account] Fetch User Attempt';
export const FETCH_USER_SUCCESS = '[Account] Fetch User Success';
export const FETCH_USER_FAILURE = '[Account] Fetch User Failure';
export const UPLOAD_COMPANY_LOGO_ATTEMPT = '[Account] Upload Company Logo Attempt';
export const UPLOAD_COMPANY_LOGO_SUCCESS = '[Account] Upload Company Logo Success';
export const UPLOAD_COMPANY_LOGO_BAD_REQUEST = '[Account] Upload Company Logo Bad Request';
export const UPLOAD_COMPANY_LOGO_FAILURE = '[Account] Upload Company Logo Attempt Failure';
export const GDPR_CONSENT_RESPONSE_SELECTED = '[Account] GDPR Consent response selected';
export const RECORD_GDPR_CONSENT_SUCCESS = '[Account] Record GDPR Consent response success';
export const RECORD_GDPR_CONSENT_FAILURE = '[Account] Record GDPR Consent response failure';
export const RECORD_GDPR_CONSENT_ATTEMPT = '[Account] Record GDPR Consent response attempt';
export const SET_IS_CONFIRMED_GUEST = '[Account] Set IsConfirmedGuest';
