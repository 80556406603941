import React from 'react';
import { makeStyles, createStyles, Grid, Avatar, Link, Box, Divider } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CultureSelect from '../CultureSelect/CultureSelect';
const useStyles = makeStyles((theme) => (createStyles({
    signInLinkContainer: {
        marginRight: theme.spacing(2),
    },
    signedInAvatar: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    signedInWelcome: {
        marginRight: theme.spacing(2),
    },
    link: {
        color: 'inherit',
        textDecoration: 'inherit'
    },
})));
export default function NavigationDesktop(props) {
    const { isAuthenticated, user, navigationItems, handleSignInClick, handleSignOutClick } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, justify: "flex-end" },
            React.createElement(Grid, { item: true },
                React.createElement(Box, { marginBottom: 2 },
                    React.createElement(CultureSelect, null),
                    React.createElement(Divider, null)))),
        React.createElement(Grid, { container: true, justify: "space-between", alignItems: "center", spacing: 4 },
            navigationItems.map(navigationItem => {
                if (navigationItem.show) {
                    return (React.createElement(Grid, { item: true, key: navigationItem.id, "data-qa-id": `${navigationItem.translationKey}-tab` },
                        React.createElement(Link, { component: "button", variant: "body2" },
                            React.createElement(RouterLink, { to: navigationItem.route, className: classes.link }, t(navigationItem.translationKey)))));
                }
                return null;
            }),
            React.createElement(Grid, { item: true }, isAuthenticated
                ? React.createElement(Grid, { item: true, container: true, alignItems: "center", onClick: handleSignOutClick },
                    React.createElement(Grid, { className: classes.signedInWelcome },
                        React.createElement(Box, { "data-qa-id": "greeting" },
                            t('navigation-ui.welcome'),
                            ", ",
                            getUserName(user)),
                        React.createElement(Box, { style: { float: 'right' } },
                            React.createElement(Link, { component: "button", variant: "body2" }, t('navigation-ui.sign-out')))),
                    React.createElement(Grid, null,
                        React.createElement(Avatar, { src: "not-valid.jpg", className: classes.signedInAvatar }, getInitials(user))))
                : React.createElement(Grid, { item: true, container: true, alignItems: "center", onClick: handleSignInClick },
                    React.createElement(Grid, { className: classes.signInLinkContainer },
                        React.createElement(Link, { "data-qa-id": "signInButton", component: "button", variant: "body2" }, t('navigation-ui.sign-in'))),
                    React.createElement(Grid, null,
                        React.createElement(Avatar, { src: "not-valid.jpg" })))))));
}
const getInitials = (user) => {
    const name = getUserName(user);
    return name === '' ? name : name[0];
};
const getUserName = (user) => {
    if (user === undefined) {
        return '';
    }
    return `${user.givenName} ${user.familyName}`;
};
