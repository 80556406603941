import React from 'react';
import { Grid, Typography, Box, Button, makeStyles, createStyles, List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { uploadCompanyLogo } from '../../store/account/account.action-creators';
const useStyles = makeStyles((theme) => (createStyles({
    companyLogo: {
        width: 250,
    }
})));
export default function BusinessInformation(props) {
    const dispatch = useDispatch();
    const { user, uploadCompanyLogoState } = props;
    const { isLoading, error, response } = uploadCompanyLogoState;
    const uploadSuccessful = response === null || response === void 0 ? void 0 : response.ok;
    const classes = useStyles();
    const { t } = useTranslation();
    const handleChooseFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            dispatch(uploadCompanyLogo(event.target.files[0], event.target.files[0].name));
            // Clear out the HTML input control's selected file, allowing the user to select a new file, or even reselect the same file.
            // This does not clear out the blob reference we have, and as long as the file doesn't change on disk before commiting, the commit changes still works.
            event.target.value = null;
        }
    };
    return (React.createElement(Grid, { item: true, xs: 12, sm: 6 },
        React.createElement(Typography, { variant: "h4", component: "h4" },
            React.createElement(Trans, { i18nKey: 'account-ui.business-info' }, "Business Information")),
        React.createElement(List, { dense: false },
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: React.createElement(React.Fragment, null,
                        t('account-ui.titles.company-logo'),
                        React.createElement(Box, { my: 4 }, user.companyLogoBase64 ? (React.createElement("img", { src: user.companyLogoBase64, alt: "Business logo", className: classes.companyLogo })) : (React.createElement(Typography, { variant: "caption", color: "textSecondary", gutterBottom: true },
                            React.createElement(Trans, { i18nKey: 'account-ui.no-logo-provided' }, "No logo provided.")))),
                        isLoading && React.createElement(Box, { m: 2 },
                            React.createElement(CircularProgress, null)),
                        error && React.createElement(Box, { my: 2 },
                            React.createElement(Alert, { severity: "error", "data-qa-id": "errorMessage" }, "Something went wrong when trying to upload your image, please try again.")),
                        uploadSuccessful && React.createElement(Box, { my: 2 },
                            React.createElement(Alert, { severity: "success", "data-qa-id": "sucessMessage" }, "Your image was uploaded successfully.")),
                        React.createElement(Button, { variant: "outlined", color: "secondary", component: "label", disabled: isLoading, "data-qa-id": "upload-company-logo-btn" },
                            React.createElement(Trans, { i18nKey: "account-ui.buttons.upload-logo" }),
                            React.createElement("input", { type: "file", style: { display: 'none' }, onChange: handleChooseFileChange, accept: "image/*" }))) })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.company-name'), secondary: user.companyName })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.street-address'), secondary: user.streetAddress })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.city'), secondary: user.city })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.postal-code'), secondary: user.postalCode })),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: t('account-ui.titles.country'), secondary: user.country })))));
}
