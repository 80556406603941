import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Box, makeStyles, createStyles, useMediaQuery, CircularProgress } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import EmailDialog from '../EmailDialogs/EmailDialog';
import { downloadCalculation, directDownloadCalculation } from '../../store/calculations/calculations.action-creators';
import { loggedInUserSelector } from '../../store/account/account.selectors';
import { selectedCultureSelector, activeConstructionTypeIdSelector, downloadingCalculationIdSelector } from '../../store/component-state/component-state.selectors';
import { dispatchCalculationAttempt } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { projectDetailsSelector, currentCalculationDataSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import CalculationSummary from './CalculationSummary';
import { displayPdfDownloadToast } from '../../helpers/toast-helpers';
import TagManager from 'react-gtm-module';
const useStyles = makeStyles((theme) => createStyles({
    fullWidthButton: {
        width: '100%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    link: {
        color: 'inherit',
        textDecoration: 'inherit',
    },
    buttonWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.info.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
export default function CalculationResults(props) {
    const dispatch = useDispatch();
    const user = useSelector(loggedInUserSelector);
    const downloadingCalculationId = useSelector(downloadingCalculationIdSelector);
    const { t } = useTranslation();
    const handleDownloadClick = async (calculationId, calculationAccessCode) => {
        if (user != null) {
            const result = await dispatch(downloadCalculation(calculationId, calculationAccessCode));
            displayPdfDownloadToast(result, t);
        }
        else {
            setGuestDialogOpen(true);
        }
    };
    const handleDirectDownloadClick = async (calculationName, constructionTypeId, calculationId, calculationAccessCode) => {
        if (user != null) {
            await dispatch(directDownloadCalculation(calculationId, calculationAccessCode));
        }
    };
    const { handleBack } = props;
    const { calculationResult, calculationId, calculationAccessCode } = useSelector(currentCalculationDataSelector);
    const constructionTypeId = useSelector(activeConstructionTypeIdSelector);
    const projectDetails = useSelector(projectDetailsSelector);
    const classes = useStyles();
    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const [guestDialogOpen, setGuestDialogOpen] = React.useState(false);
    const [downloadingState, setDownloadingState] = React.useState(false);
    React.useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'calculation_results_page_init',
            }
        });
    });
    React.useEffect(() => {
        if (!downloadingState && downloadingCalculationId) {
            setDownloadingState(true);
        }
        else if (downloadingState && !downloadingCalculationId) {
            setDownloadingState(false);
        }
    }, [downloadingState, downloadingCalculationId, setDownloadingState]);
    const handleClose = () => {
        setGuestDialogOpen(false);
    };
    const handleGuestEmailSend = async (guestEmail, gdprConsentGiven) => {
        if (calculationId && calculationAccessCode) {
            const result = await dispatch(downloadCalculation(calculationId, calculationAccessCode, guestEmail, gdprConsentGiven));
            displayPdfDownloadToast(result, t);
            setGuestDialogOpen(false);
        }
    };
    const selectedCulture = useSelector(selectedCultureSelector);
    const isInitialised = React.useRef(false);
    React.useEffect(() => {
        if (!isInitialised.current) {
            isInitialised.current = true;
        }
        else {
            console.info(`Selected culture changed to ${selectedCulture}, so dispatching refresh of the calculation result`);
            dispatch(dispatchCalculationAttempt());
        }
    }, [dispatch, selectedCulture]);
    if (user != null && !isInitialised.current) {
        toast.info(t('calculation-results-ui.calculation-saved-message', { projectName: projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.name }));
    }
    return (React.createElement(Box, null,
        React.createElement(EmailDialog, { open: guestDialogOpen, handleClose: handleClose, sending: downloadingState, handleSend: handleGuestEmailSend }),
        React.createElement(CalculationSummary, { results: calculationResult, constructionTypeId: constructionTypeId, projectDetails: projectDetails, layers: props.layers }),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, sm: "auto", className: classes.buttonWrapper },
                calculationId && calculationAccessCode && (React.createElement(Button, { variant: "contained", color: "primary", onClick: () => handleDownloadClick(calculationId, calculationAccessCode), className: isMobileView ? classes.fullWidthButton : '', disabled: downloadingState },
                    React.createElement(Trans, { i18nKey: "calculation-results-ui.download-pdf-button" }, "Download"))),
                downloadingState && React.createElement(CircularProgress, { size: 24, className: `${classes.buttonProgress}` })),
            React.createElement(Grid, { item: true, xs: 12, sm: "auto", className: classes.buttonWrapper },
                calculationId && calculationAccessCode && user != null && (React.createElement(Button, { variant: "contained", color: "secondary", onClick: () => handleDirectDownloadClick(projectDetails === null || projectDetails === void 0 ? void 0 : projectDetails.name, constructionTypeId, calculationId, calculationAccessCode), className: isMobileView ? classes.fullWidthButton : '', disabled: downloadingState },
                    React.createElement(Trans, { i18nKey: "calculation-results-ui.direct-download-pdf-button" }, "View"))),
                downloadingState && React.createElement(CircularProgress, { size: 24, className: `${classes.buttonProgress}` })),
            React.createElement(Grid, { item: true, xs: 12, sm: "auto", className: classes.buttonWrapper },
                React.createElement(Button, { variant: "outlined", color: "secondary", className: isMobileView ? classes.fullWidthButton : '', onClick: handleBack, "data-qa-id": "edit-calculation-button" },
                    React.createElement(Trans, { i18nKey: "calculation-results-ui.edit-calculation-button" }, "Edit calculation"))))));
}
