import React from 'react';
import MaterialDropDown from './MaterialDropDown';
import MaterialParameters from './MaterialParameters';
import AnchorParameters from './AnchorParameters';
import AnchorPreCalculatedParameters from './AnchorPreCalculatedParameters';
import WoodPercentage from './WoodPercentage';
import AirCavity from './AirCavity';
import { LayerType } from '../../types/domain/construction-data.types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LayerName from '../Calculation/LayerName';
function renderControls(layer, selectedMaterial, isLinkedMaterial) {
    var _a;
    switch (layer.layerType) {
        case LayerType.Anchors:
            return (React.createElement(React.Fragment, null,
                React.createElement(MaterialDropDown, { isLinkedMaterial: isLinkedMaterial }),
                React.createElement(AnchorParameters, null)));
        case LayerType.AnchorsPreCalculated:
            return (React.createElement(React.Fragment, null,
                React.createElement(MaterialDropDown, { isLinkedMaterial: isLinkedMaterial }),
                React.createElement(AnchorPreCalculatedParameters, null)));
        case LayerType.WoodPercentage:
            return (React.createElement(WoodPercentage, null));
        case LayerType.AirCavity:
            return (React.createElement(AirCavity, null));
        default:
            return (React.createElement(React.Fragment, null,
                React.createElement(MaterialDropDown, { isLinkedMaterial: isLinkedMaterial }),
                (((_a = selectedMaterial === null || selectedMaterial === void 0 ? void 0 : selectedMaterial.optionsForThicknessMillimetres) === null || _a === void 0 ? void 0 : _a.length) || layer.isWithinBridgingFrame) && React.createElement(MaterialParameters, { isLinkedMaterial: isLinkedMaterial })));
    }
}
function renderLayerHeader(layer, layerTooltipText) {
    switch (layer.layerType) {
        case LayerType.Anchors:
        case LayerType.AnchorsPreCalculated:
        case LayerType.WoodPercentage:
            return (React.createElement(Tooltip, { title: layerTooltipText, "aria-label": layerTooltipText, enterTouchDelay: 10 },
                React.createElement("div", { className: 'stepTitle stepTitleTooltip', "data-qa-id": "layerTitle" },
                    React.createElement(LayerName, { layer: layer }),
                    React.createElement(InfoIcon, { className: 'tooltipIcon' }))));
        default:
            return (React.createElement("div", { className: 'stepTitle', "data-qa-id": "layerTitle" },
                React.createElement(LayerName, { layer: layer })));
    }
}
export default function CalculationLayer() {
    const layer = useSelector(activeConstructionLayerSelector);
    const currentSelectedMaterial = useSelector(currentSelectedMaterialSelector(false));
    const { t } = useTranslation();
    const layerTooltipText = t(`layer-tooltip.${layer.layerType.toLowerCase()}`);
    return layer ? (React.createElement("div", { style: { maxWidth: '400px' } },
        renderLayerHeader(layer, layerTooltipText),
        renderControls(layer, currentSelectedMaterial, false),
        currentSelectedMaterial && currentSelectedMaterial.linkedMaterials.length > 0 && renderControls(layer, currentSelectedMaterial, true))) : null;
}
