export const SET_PROJECT_DETAILS = 'SET_PROJECT_DETAILS';
export const SET_ADDITIONAL_PARAMETERS = 'SET_ADDITIONAL_PARAMETERS';
export const SET_MATERIAL = 'SET_MATERIAL';
export const SET_AIR_CAVITY_GRADING = 'SET_AIR_CAVITY_GRADING';
export const SET_THICKNESS = 'SET_THICKNESS';
export const SET_ANCHOR_PER_SQUARE_METRE = 'SET_ANCHOR_PER_SQUARE_METRE';
export const SET_ANCHOR_DIAMETER = 'SET_ANCHOR_DIAMETER';
export const SET_ANCHOR_PENETRATION = 'SET_ANCHOR_PENETRATION';
export const SET_NET_AREA_PER_METRE_SQUARE = '';
export const SET_WOOD_PERCENTAGE = 'SET_WOOD_PERCENTAGE';
export const SET_CALCULATED_LAYER_VALUE = 'SET_CALCULATED_LAYER_VALUE';
export const NEW_CALCULATION_STARTED = 'NEW_CALCULATION_STARTED';
export const EDIT_CALCULATION_STARTED = 'EDIT_CALCULATION_STARTED';
export const COPY_CALCULATION_STARTED = 'COPY_CALCULATION_STARTED';
export const CREATE_LINKED_LAYER = 'CREATE_LINKED_LAYER';
export const REMOVE_LINKED_LAYER = 'REMOVE_LINKED_LAYER';
export const CLEAR_CURRENT_CALCULATION = 'CLEAR_CURRENT_CALCULATION';
export const FETCH_CALCULATION_RESULTS_ATTEMPT = 'FETCH_CALCULATION_RESULTS_ATTEMPT';
export const FETCH_CALCULATION_RESULTS_SUCCESS = 'FETCH_CALCULATION_RESULTS_SUCCESS';
export const FETCH_CALCULATION_RESULTS_CLIENT_ERROR = 'FETCH_CALCULATION_RESULTS_CLIENT_ERROR';
export const FETCH_CALCULATION_RESULTS_FAILURE = 'FETCH_CALCULATION_RESULTS_FAILURE';
