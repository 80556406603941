import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { errorSelector, calculationResultErrorSelector } from '../../store/component-state/component-state.selectors';
import { dispatchCalculationAttempt } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { useTranslation } from 'react-i18next';
export default function ErrorBar() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const error = useSelector(errorSelector);
    const calculationResultError = useSelector(calculationResultErrorSelector);
    useEffect(() => {
        if (!isEmpty(error)) {
            console.warn('Unhandled error:', error);
        }
    }, [error]);
    useEffect(() => {
        if (!isEmpty(calculationResultError)) {
            console.warn('Unhandled calculation error:', calculationResultError);
        }
    }, [calculationResultError]);
    if (!isEmpty(error)) {
        return React.createElement(Box, { my: 2 },
            React.createElement(Alert, { severity: "error" }, t('error-banner.generic-error-label')));
    }
    if (!isEmpty(calculationResultError)) {
        return React.createElement(Box, { my: 2 },
            React.createElement(Alert, { severity: "error", action: React.createElement(Button, { color: "inherit", size: "small", onClick: () => dispatch(dispatchCalculationAttempt()) }, t('error-banner.refresh-button')) }, t('error-banner.calculation-result-error-label')));
    }
    return null;
}
