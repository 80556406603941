import { createStyles, makeStyles, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { countryIdSelector } from '../../../store/component-state/component-state.selectors';
const useStyles = makeStyles((theme) => createStyles({
    link: {
        color: theme.palette.text.primary,
    }
}));
export function Contact() {
    const { t } = useTranslation();
    const theme = useStyles();
    const countryId = useSelector(countryIdSelector);
    const label1 = t(`by-country.${countryId}.footer.contacts.contact-number-1.label`);
    const contactNumber1 = t(`by-country.${countryId}.footer.contacts.contact-number-1.value`);
    const label2 = t(`by-country.${countryId}.footer.contacts.contact-number-2.label`);
    const contactNumber2 = t(`by-country.${countryId}.footer.contacts.contact-number-2.value`);
    return (React.createElement(React.Fragment, null,
        label1.length > 0 && contactNumber1.length > 0 &&
            React.createElement("div", null,
                React.createElement(Typography, { variant: "body1" },
                    parse(label1),
                    ":",
                    React.createElement("br", null),
                    React.createElement("a", { href: `tel:${contactNumber1}`, className: theme.link }, parse(t(`by-country.${countryId}.footer.contacts.contact-number-1.friendly`))))),
        label2.length > 0 && contactNumber2.length > 0 &&
            React.createElement("div", null,
                React.createElement(Typography, { variant: "body1" },
                    parse(label2),
                    ":",
                    React.createElement("br", null),
                    React.createElement("a", { href: `tel:${contactNumber2}`, className: theme.link }, parse(t(`by-country.${countryId}.footer.contacts.contact-number-2.friendly`)))))));
}
