import { default as axiosDefault } from 'axios';
import { store } from '../store/store';
import authProviderFactory from '../authentication/authProvider';
import { getAppConfig } from '../config/config';
function init() {
    var _a;
    axiosDefault.defaults.baseURL = (_a = getAppConfig()) === null || _a === void 0 ? void 0 : _a.apiUrl;
    axiosDefault.interceptors.request.use(config => {
        var _a;
        config.headers = (_a = config.headers) !== null && _a !== void 0 ? _a : {};
        config.headers['Accept-Language'] = store.getState().componentState.selectedCulture;
        return config;
    }, error => Promise.reject(error));
    axiosDefault.interceptors.request.use(async (config) => {
        var _a;
        if (config.withCredentials) {
            const idToken = await authProviderFactory().getIdToken();
            config.headers = (_a = config.headers) !== null && _a !== void 0 ? _a : {};
            config.headers['Authorization'] = `Bearer ${idToken === null || idToken === void 0 ? void 0 : idToken.idToken.rawIdToken}`;
        }
        return config;
    }, error => Promise.reject(error));
}
export default {
    init,
};
